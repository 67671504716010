/* eslint-disable */
import Loader from '@/plugins/loader';
import axios from '@/plugins/axios';
const webApi = axios();

export default class Drawflow {
  constructor(container, render = null, parent = null) {
    this.events = {};
    this.container = container;
    this.precanvas = null;
    this.nodeId = 1;
    this.ele_selected = null;
    this.ele_selectedOver = null;
    this.node_selected = null;
    this.node_selectedOver = null;
    this.lastNode_selectedOver = null;
    this.drag = false;
    this.reroute = false;
    this.reroute_fix_curvature = false;
    this.curvature = 0.5;
    this.reroute_curvature_start_end = 0.5;
    this.reroute_curvature = 0.5;
    this.reroute_width = 6;
    this.drag_point = false;
    this.editor_selected = false;
    this.connection = false;
    this.connection_ele = null;
    this.connection_eleOver = null;
    this.connection_selected = null;
    this.connection_selectedOver = null;
    this.lastConnection_selectedOver = null;
    this.canvas_x = 0;
    this.canvas_y = 0;
    this.pos_x = 0;
    this.pos_x_start = 0;
    this.pos_y = 0;
    this.pos_y_start = 0;
    this.mouse_x = 0;
    this.mouse_y = 0;
    this.line_path = 5;
    this.first_click = null;
    this.force_first_input = false;
    this.draggable_inputs = true;
    this.useuuid = false;
    this.parent = parent;

    this.noderegister = {};
    this.render = render;
    this.drawflow = { drawflow: { Home: { data: {} } } };
    // Configurable options
    this.module = 'Home';
    this.editor_mode = 'edit';
    this.zoom = 1;
    this.zoom_max = 8;
    this.zoom_min = 0.2;
    this.zoom_value = 0.1;
    this.zoom_last_value = 1;

    // Mobile
    this.evCache = new Array();
    this.prevDiff = -1;
    this.nodesError = [];

    //Detect simple click
    this.dragged = false;
    this.d;
    this.selectPosX;
    this.selectPosY;
    this.selectInitX=false;
    this.selectInitY=false;
    this.keyPressed = false;
    this.selectPress = false;
    this.selectables = [];
    this.squad = {};
    this.startSelect = false;
    this.itensSelected = [];
    this.nodesSelected = [];
    this.itensCopy = [];
    this.itensNodeCopy = [];
    this.pos_xSelect = 0;
    this.pos_ySelect = 0;
  }

  start() {
    // console.info("Start Drawflow!!");
    this.container.classList.add('parent-drawflow');
    this.container.tabIndex = 0;
    this.precanvas = document.createElement('div');
    this.precanvas.classList.add('drawflow');
    this.container.appendChild(this.precanvas);

    //Squad
    //this.loadSelectables();
    //this.container.addEventListener('pointerdown', this.createDivSquad.bind(this));

    /* Mouse and Touch Actions */
    this.container.addEventListener('mouseup', this.dragEnd.bind(this));
    this.container.addEventListener('mousemove', this.position.bind(this));
    this.container.addEventListener('mousedown', this.click.bind(this));

    this.container.addEventListener('touchend', this.dragEnd.bind(this));
    this.container.addEventListener('touchmove', this.position.bind(this));
    this.container.addEventListener('touchstart', this.click.bind(this));

    this.container.addEventListener('mouseover', this.mouseover.bind(this));

    /* Context Menu */
    this.container.addEventListener('contextmenu', this.contextmenu.bind(this));
    /* Key */
    this.container.addEventListener('keydown', this.key.bind(this));

    this.container.addEventListener('keyup', this.key_up.bind(this));

    /* Zoom Mouse */
    this.container.addEventListener('wheel', this.zoom_enter.bind(this));
    /* Update data Nodes */
    this.container.addEventListener('input', this.updateNodeValue.bind(this));

    this.container.addEventListener('dblclick', this.dblclick.bind(this));
    /* Mobile zoom */
    this.container.onpointerdown = this.pointerdown_handler.bind(this);
    this.container.onpointermove = this.pointermove_handler.bind(this);
    this.container.onpointerup = this.pointerup_handler.bind(this);
    this.container.onpointercancel = this.pointerup_handler.bind(this);
    this.container.onpointerout = this.pointerup_handler.bind(this);
    this.container.onpointerleave = this.pointerup_handler.bind(this);

    //Select area
    //this.container.addEventListener('mousemove', this.mouse_square.bind(this));

    //this.load();
    /*document.addEventListener('keydown', (e) => {
      if (e.key === 'Shift') {
        this.keyPressed = true;
      }
      if(e.metaKey && e.keyCode == 67) {
        console.log('Copiando...');
        this.itensCopy = this.itensSelected;
        this.itensNodeCopy = this.nodesSelected;
      }
      if(e.metaKey && e.keyCode == 86) {
        console.log('Colando...');
        let newNodes = [];
        let newNodesElement = [];
        let cashNodes = [];
        console.log(this.itensNodeCopy);
        for (var key in this.itensNodeCopy) {
          let itemNode = this.itensNodeCopy[key];
          let oldNodeId = itemNode.id;
          let newId = (parseInt(itemNode.id) + parseInt(itemNode.id) * 2);
          itemNode.id = newId.toString();

          //Save info node for later
          cashNodes.push({
            new: newId,
            old: oldNodeId
          });
          newNodes.push(itemNode);
        }
        console.log('Cash Nodes', cashNodes);
        for (var key in newNodes) {
          let node = newNodes[key];

          //Update new connections of nodes
          if(node.outputs.output_1 != undefined) {
            //Update
            node.outputs.output_1.connections.forEach((connection) => {
              cashNodes.forEach((cash) => {
                if(connection.node == cash.old) {
                  connection.node = cash.new;
                }
              })
            });
            //Reset
            let exist = false;
            node.outputs.output_1.connections.forEach((connection) => {
              cashNodes.forEach((cash) => {
                if(connection.node == cash.new) {
                  exist = true;
                }
              })
            });
            if(!exist) {
              node.outputs.output_1.connections = [];
            }
          }
          if(node.outputs.output_2 != undefined) {
            //update
            node.outputs.output_2.connections.forEach((connection) => {
              cashNodes.forEach((cash) => {
                if(connection.node == cash.old) {
                  connection.node = cash.new;
                }
              })
            });
            //Reset
            let exist = false;
            node.outputs.output_2.connections.forEach((connection) => {
              cashNodes.forEach((cash) => {
                if(connection.node == cash.new) {
                  exist = true;
                }
              })
            });
            if(!exist) {
              node.outputs.output_2.connections = [];
            }
          }
          if(node.inputs.input_1 != undefined) {
            node.inputs.input_1.connections.forEach((connection) => {
              cashNodes.forEach((cash) => {
                if(connection.node == cash.old) {
                  connection.node = cash.new;
                }
              })
            });
            //Reset
            let exist = false;
            node.inputs.input_1.connections.forEach((connection) => {
              cashNodes.forEach((cash) => {
                if(connection.node == cash.new) {
                  exist = true;
                }
              })
            });
            if(!exist) {
              node.inputs.input_1.connections = [];
            }
          }
          if(node.inputs.input_2 != undefined) {
            node.inputs.input_2.connections.forEach((connection) => {
              cashNodes.forEach((cash) => {
                if(connection.node == cash.old) {
                  connection.node = cash.new;
                }
              })
            });
            let exist = false;
            node.inputs.input_2.connections.forEach((connection) => {
              cashNodes.forEach((cash) => {
                if(connection.node == cash.new) {
                  exist = true;
                }
              })
            });
            if(!exist) {
              node.outputs.input_2.connections = [];
            }
          }
          node.pos_x += 100;
          node.pos_y += 100;
          console.log('New node', node);
          console.log(`node-${node.id}`);
          this.drawflow.drawflow[this.module].data[node.id] = node;
          console.log(this.drawflow.drawflow[this.module].data[node.id]);
          let newElement = this.addNodeImport(node, this.precanvas, true);
          newNodesElement.push(newElement);
          console.log('Node ele',newElement);

          //this.updateConnectionNodes(`node-${node.id}`);
        }
        this.nodesSelected = newNodes;
        this.itensSelected = newNodesElement;
        for (const selectable of this.selectables) {
          if(selectable.elem)
            selectable.elem.classList.remove("intersected");
        }
        for (const selectable of this.itensSelected) {
          if(selectable)
            selectable.classList.add("intersected");
        }
        console.log(this.drawflow.drawflow[this.module].data);
      }
    });*/
  }

  checkSelected(selectAreaElem) {
    const select = selectAreaElem.getBoundingClientRect();
    const {x, y, height, width} = select;

    let index = 0;
    let node = {};
    for (const selectable of this.selectables) {
      let id = selectable.elem.id.replace('node-', '');
      node = this.drawflow.drawflow[this.module].data[id];
      index = this.nodesSelected.indexOf(node);

      if (this.checkRectIntersection({x: x + window.scrollX, y: y + window.scrollY, height, width}, selectable)){
        if(selectable.elem.id != 'node-1') {
          selectable.elem.classList.add("intersected");
          if(index == -1) {
            this.nodesSelected.push(node);
            this.itensSelected.push(selectable.elem);
          }
        }
      } else {
        selectable.elem.classList.remove("intersected");
      }
    }
  }

  checkRectIntersection(r1, r2) {

    let r2X = (r2.x + this.canvas_x);
    let r2Y = (r2.y + this.canvas_y);
    if(this.zoom < 1) {
      r2X = ((r2.x + this.canvas_x) / this.zoom);
      r2Y = ((r2.y + this.canvas_y) / this.zoom);
    }
    let r1X = r1.x;
    let r1Y = r1.y ;

    return !(r1X + r1.width  < r2X ||
             r2X + r2.width  < r1X ||
             r1Y + r1.height < r2Y ||
             r2Y + r2.height < r1Y);
  }

  loadSelectables() {
    this.selectables = [];
    const selectableElems = [...document.querySelectorAll(".selectable")];
    for (const selectable of selectableElems) {
      const {x, y, width, height} = selectable.getBoundingClientRect();
      this.selectables.push({x: x + window.scrollX, y: y + window.scrollY, width, height, elem: selectable});
      selectable.dataset.info = JSON.stringify({x, y, width, height});
    }
  }

  createDivSquad(event){
    //event.preventDefault();
    this.startSelect = false;
    //this.selectables.forEach(item => item.elem.classList.remove("intersected"));
    if(this.keyPressed) {
      const x = event.pageX;
      const y = event.pageY;
      this.selectPosX = x;
      this.selectPosY = y;
      this.startSelect = true;
      this.itensSelected = [];
      this.nodesSelected = [];

      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.width = "0";
      div.style.height = "0";
      div.style.left = x + "px";
      div.style.top = y + "px";
      div.classList.add("drag-select");
      document.body.append(div);
      this.squad = div;

      addEventListener("pointermove", this.resize.bind(this));
      addEventListener("pointerup", () => {
        //removeEventListener("pointermove", this.resize.bind(this));
        this.startSelect = false;
        this.squad.remove();
      });
    }
  }

  resize(event) {
    const diffX = event.pageX - this.selectPosX;
    const diffY = event.pageY - this.selectPosY;
    this.squad.style.left = diffX < 0 ? this.selectPosX + diffX + "px" : this.selectPosX + "px";
    this.squad.style.top = diffY < 0 ? this.selectPosY + diffY + "px" : this.selectPosY + "px";
    this.squad.style.height = Math.abs(diffY) + "px";
    this.squad.style.width = Math.abs(diffX) + "px";
    if(this.startSelect)
      this.checkSelected(this.squad);
  }

  /* Mobile zoom */
  pointerdown_handler(ev) {
    this.evCache.push(ev);
  }

  pointermove_handler(ev) {
    for (let i = 0; i < this.evCache.length; i++) {
      if (ev.pointerId == this.evCache[i].pointerId) {
        this.evCache[i] = ev;
        break;
      }
    }

    if (this.evCache.length == 2) {
      // Calculate the distance between the two pointers
      const curDiff = Math.abs(this.evCache[0].clientX - this.evCache[1].clientX);

      if (this.prevDiff > 100) {
        if (curDiff > this.prevDiff) {
          // The distance between the two pointers has increased

          this.zoom_in();
        }
        if (curDiff < this.prevDiff) {
          // The distance between the two pointers has decreased
          this.zoom_out();
        }
      }
      this.prevDiff = curDiff;
    }
  }

  pointerup_handler(ev) {
    this.remove_event(ev);
    if (this.evCache.length < 2) {
      this.prevDiff = -1;
    }
  }

  remove_event(ev) {
    // Remove this event from the target's cache
    for (let i = 0; i < this.evCache.length; i++) {
      if (this.evCache[i].pointerId == ev.pointerId) {
        this.evCache.splice(i, 1);
        break;
      }
    }
  }

  /* End Mobile Zoom */
  load() {
    for (var key in this.drawflow.drawflow[this.module].data) {
      this.drawflow.drawflow[this.module].data[key].id = key;
      this.addNodeImport(this.drawflow.drawflow[this.module].data[key], this.precanvas);
    }

    if (this.reroute) {
      for (var key in this.drawflow.drawflow[this.module].data) {
        this.addRerouteImport(this.drawflow.drawflow[this.module].data[key]);
      }
    }

    for (var key in this.drawflow.drawflow[this.module].data) {
      this.updateConnectionNodes(`node-${key}`);
    }

    const editor = this.drawflow.drawflow;
    let number = 1;
    Object.keys(editor).map((moduleName, index) => {
      Object.keys(editor[moduleName].data).map((id, index2) => {
        if (parseInt(id) >= number) {
          number = parseInt(id) + 1;
        }
      });
    });
    this.nodeId = number;
    this.loadSelectables();
  }

  removeReouteConnectionSelected() {
    this.dispatch('connectionUnselected', true);
    if (this.reroute_fix_curvature) {
      this.connection_selected.parentElement.querySelectorAll('.main-path').forEach((item, i) => {
        item.classList.remove('selected');
      });
    }
  }

  click(e) {
    if(!this.keyPressed) {
      this.selectPress = false;
      this.dragged = false;
      this.dispatch('click', e);

      if (this.editor_mode === 'fixed') {
        // return false;
        e.preventDefault();
        if (e.target.classList[0] === 'parent-drawflow' || e.target.classList[0] === 'drawflow') {
          this.ele_selected = e.target.closest('.parent-drawflow');
        } else {
          return false;
        }
      } else if (this.editor_mode === 'view') {
        if (e.target.closest('.drawflow') != null || e.target.matches('.parent-drawflow')) {
          this.ele_selected = e.target.closest('.parent-drawflow');
          e.preventDefault();
        }
      } else {
        this.first_click = e.target;
        this.ele_selected = e.target;
        if (e.button === 0) {
          this.contextmenuDel();
        }

        if (e.target.closest('.drawflow_content_node') != null) {
          this.ele_selected = e.target.closest('.drawflow_content_node').parentElement;
        }
      }
      if(this.ele_selected.classList[2] && this.ele_selected.classList[2] == 'node-item-delete') {
        if (this.lastNode_selectedOver && this.ele_selected.classList[3] == 'node-delete-icon') {
          this.removeNodeId(this.lastNode_selectedOver.id);
        }

        if (this.lastConnection_selectedOver && this.ele_selected.classList[3] == 'connection-delete-icon') {
          this.removeConnection();
        }

        if (this.lastNode_selectedOver != null) {
          this.lastNode_selectedOver.classList.remove('selected');
          this.lastNode_selectedOver = null;
          this.dispatch('nodeUnselected', true);
        }
        if (this.lastConnection_selectedOver != null) {
          this.lastConnection_selectedOver.classList.remove('selected');
          //this.removeReouteConnectionSelected();
          this.lastConnection_selectedOver = null;
        }
      }

      if(this.ele_selected.classList[0] != 'drawflow-node' && this.ele_selected.classList[0] != 'drawflow-delete') {
        this.selectables.forEach(item => item.elem.classList.remove("intersected"));
        this.itensSelected = [];
        this.nodesSelected = [];
      }

      switch (this.ele_selected.classList[0]) {
        case 'drawflow-node':
          if (this.node_selected != null) {
            this.node_selected.classList.remove('selected');
            if (this.node_selected != this.ele_selected) {
              this.dispatch('nodeUnselected', true);
            }
          }
          if (this.connection_selected != null) {
            this.connection_selected.classList.remove('selected');
            this.removeReouteConnectionSelected();
            this.connection_selected = null;
          }
          if (this.node_selected != this.ele_selected) {
            this.dispatch('nodeSelected', this.ele_selected.id.slice(5));
          }
          this.node_selected = this.ele_selected;
          this.node_selected.classList.add('selected');
          if (!this.draggable_inputs) {
            if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA' && e.target.tagName !== 'SELECT' && e.target.hasAttribute('contenteditable') !== true) {
              this.drag = true;
            }
          } else if (e.target.tagName !== 'SELECT') {
            this.drag = true;
          }
          break;
        case 'output':
          this.connection = true;
          if (this.node_selected != null) {
            this.node_selected.classList.remove('selected');
            this.node_selected = null;
            this.dispatch('nodeUnselected', true);
          }
          if (this.connection_selected != null) {
            this.connection_selected.classList.remove('selected');
            this.removeReouteConnectionSelected();
            this.connection_selected = null;
          }
          this.drawConnection(e.target);
          break;
        case 'parent-drawflow':
          if (this.node_selected != null) {
            this.node_selected.classList.remove('selected');
            this.node_selected = null;
            this.dispatch('nodeUnselected', true);
          }
          if (this.connection_selected != null) {
            this.connection_selected.classList.remove('selected');
            this.removeReouteConnectionSelected();
            this.connection_selected = null;
          }
          this.editor_selected = true;
          break;
        case 'drawflow':
          if (this.node_selected != null) {
            this.node_selected.classList.remove('selected');
            this.node_selected = null;
            this.dispatch('nodeUnselected', true);
          }
          if (this.connection_selected != null) {
            this.connection_selected.classList.remove('selected');
            this.removeReouteConnectionSelected();
            this.connection_selected = null;
          }
          this.editor_selected = true;
          break;
        case 'main-path':
          if (this.node_selected != null) {
            this.node_selected.classList.remove('selected');
            this.node_selected = null;
            this.dispatch('nodeUnselected', true);
          }
          if (this.connection_selected != null) {
            this.connection_selected.classList.remove('selected');
            this.removeReouteConnectionSelected();
            this.connection_selected = null;
          }
          this.connection_selected = this.ele_selected;
          this.connection_selected.classList.add('selected');
          const listclassConnection = this.connection_selected.parentElement.classList;
          if (listclassConnection.length > 1) {
            this.dispatch('connectionSelected', {
              output_id: listclassConnection[2].slice(14), input_id: listclassConnection[1].slice(13), output_class: listclassConnection[3], input_class: listclassConnection[4],
            });
            if (this.reroute_fix_curvature) {
              this.connection_selected.parentElement.querySelectorAll('.main-path').forEach((item, i) => {
                item.classList.add('selected');
              });
            }
          }
          break;
        case 'point':
          this.drag_point = true;
          this.ele_selected.classList.add('selected');
          break;
        case 'drawflow-delete':
          if(this.itensSelected.length > 0) {
            this.itensSelected.forEach((item) => {
              this.removeNodeId(item.id);
            });
          } else {
            if (this.lastNode_selectedOver && this.ele_selected.classList[1] == 'node-delete') {
              this.removeNodeId(this.lastNode_selectedOver.id);
            }
          }

          this.selectables.forEach(item => item.elem.classList.remove("intersected"));
          this.itensSelected = [];
          this.nodesSelected = [];

          if (this.lastConnection_selectedOver && this.ele_selected.classList[1] == 'connection-delete') {
            this.removeConnection();
          }

          if (this.lastNode_selectedOver != null) {
            this.lastNode_selectedOver.classList.remove('selected');
            this.lastNode_selectedOver = null;
            this.dispatch('nodeUnselected', true);
          }
          if (this.lastConnection_selectedOver != null) {
            this.lastConnection_selectedOver.classList.remove('selected');
            //this.removeReouteConnectionSelected();
            this.lastConnection_selectedOver = null;
          }
          this.loadSelectables();
          break;
        default:
      }
      if (e.type === 'touchstart') {
        this.pos_x = e.touches[0].clientX;
        this.pos_x_start = e.touches[0].clientX;
        this.pos_y = e.touches[0].clientY;
        this.pos_y_start = e.touches[0].clientY;
        this.mouse_x = e.touches[0].clientX;
        this.mouse_y = e.touches[0].clientY;
      } else {
        this.pos_x = e.clientX;
        this.pos_x_start = e.clientX;
        this.pos_y = e.clientY;
        this.pos_y_start = e.clientY;
      }
      if (['input', 'output', 'main-path'].includes(this.ele_selected.classList[0])) {
        e.preventDefault();
      }
      this.dispatch('clickEnd', e);
    } else {
      this.selectPress = true;
    }
  }

  position(e) {
    //console.log('Key pressed');
    //console.log(this.keyPressed);
    if(!this.keyPressed) {
      this.dragged = true;
      if (e.type === 'touchmove') {
        var e_pos_x = e.touches[0].clientX;
        var e_pos_y = e.touches[0].clientY;
      } else {
        var e_pos_x = e.clientX;
        var e_pos_y = e.clientY;
      }

      if (this.connection) {
        this.updateConnection(e_pos_x, e_pos_y);
      }
      if (this.editor_selected) {
        x = this.canvas_x + (-(this.pos_x - e_pos_x));
        y = this.canvas_y + (-(this.pos_y - e_pos_y));
        this.dispatch('translate', { x, y });
        this.precanvas.style.transform = `translate(${x}px, ${y}px) scale(${this.zoom})`;
      }
      if (this.drag) {
        e.preventDefault();
        var x = (this.pos_x - e_pos_x) * this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom);
        var y = (this.pos_y - e_pos_y) * this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom);
        this.pos_x = e_pos_x;
        this.pos_y = e_pos_y;

        //Update groups
        if(this.itensSelected.length > 0) {
          this.itensSelected.forEach((item) => {
            item.style.top = `${item.offsetTop - y}px`;
            item.style.left = `${item.offsetLeft - x}px`;

            this.drawflow.drawflow[this.module].data[item.id.slice(5)].pos_x = (item.offsetLeft - x);
            this.drawflow.drawflow[this.module].data[item.id.slice(5)].pos_y = (item.offsetTop - y);

            this.updateConnectionNodes(item.id);
          });
        } else {
          this.ele_selected.style.top = `${this.ele_selected.offsetTop - y}px`;
          this.ele_selected.style.left = `${this.ele_selected.offsetLeft - x}px`;

          this.drawflow.drawflow[this.module].data[this.ele_selected.id.slice(5)].pos_x = (this.ele_selected.offsetLeft - x);
          this.drawflow.drawflow[this.module].data[this.ele_selected.id.slice(5)].pos_y = (this.ele_selected.offsetTop - y);

          this.updateConnectionNodes(this.ele_selected.id);
        }
      }

      if (this.drag_point) {
        var x = (this.pos_x - e_pos_x) * this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom);
        var y = (this.pos_y - e_pos_y) * this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom);
        this.pos_x = e_pos_x;
        this.pos_y = e_pos_y;

        const pos_x = this.pos_x * (this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)) - (this.precanvas.getBoundingClientRect().x * (this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)));
        const pos_y = this.pos_y * (this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)) - (this.precanvas.getBoundingClientRect().y * (this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)));

        this.ele_selected.setAttributeNS(null, 'cx', pos_x);
        this.ele_selected.setAttributeNS(null, 'cy', pos_y);

        const nodeUpdate = this.ele_selected.parentElement.classList[2].slice(9);
        const nodeUpdateIn = this.ele_selected.parentElement.classList[1].slice(13);
        const output_class = this.ele_selected.parentElement.classList[3];
        const input_class = this.ele_selected.parentElement.classList[4];

        let numberPointPosition = Array.from(this.ele_selected.parentElement.children).indexOf(this.ele_selected) - 1;

        if (this.reroute_fix_curvature) {
          const numberMainPath = this.ele_selected.parentElement.querySelectorAll('.main-path').length - 1;
          numberPointPosition -= numberMainPath;
          if (numberPointPosition < 0) {
            numberPointPosition = 0;
          }
        }

        const nodeId = nodeUpdate.slice(5);
        const searchConnection = this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections.findIndex((item, i) => item.node === nodeUpdateIn && item.output === input_class);

        this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points[numberPointPosition] = { pos_x, pos_y };

        const parentSelected = this.ele_selected.parentElement.classList[2].slice(9);

        this.updateConnectionNodes(parentSelected);
      }

      if (e.type === 'touchmove') {
        this.mouse_x = e_pos_x;
        this.mouse_y = e_pos_y;
      }
      this.dispatch('mouseMove', { x: e_pos_x, y: e_pos_y });
    } else {
      /*if(this.selectPress) {
        let posx=0;
        let posy=0;
        var ev=(!e)?window.event:e;//Moz:IE

        if (ev.pageX){//Moz
          posx=ev.pageX+window.pageXOffset;
          posy=ev.pageY+window.pageYOffset;
        }
        else if(ev.clientX){//IE
          posx=ev.clientX+document.body.scrollLeft;
          posy=ev.clientY+document.body.scrollTop;
        }
        else{
          return false
        }
        //old browsers
        var target = (e && e.target) || (event && event.srcElement);
        this.container.onmousedown= () => {
          this.selectInitX=posx; this.selectInitY=posy;
          this.d = document.createElement('div');
          this.d.className='square-select'
          this.d.style.left=this.selectInitX+'px';this.d.style.top=this.selectInitY+'px';
          document.getElementsByTagName('body')[0].appendChild(this.d)
        }
        this.container.onmouseup = () => {
          this.selectInitX=false; this.selectInitY=false;
        }
        if(this.selectInitX){
          this.d.style.width=Math.abs(posx-this.selectInitX)+'px';
          this.d.style.height=Math.abs(posy-this.selectInitY)+'px';
          this.d.style.left= posx-this.selectInitX< 0 ? posx+'px': this.selectInitX+'px';
          this.d.style.top= posy-this.selectInitY <0? posy+'px': this.selectInitY+'px';
        }
      }*/
    }
  }

  dragEnd(e) {
    this.selectPress = false;
    if (e.type === 'touchend') {
      var e_pos_x = this.mouse_x;
      var e_pos_y = this.mouse_y;
      var ele_last = document.elementFromPoint(e_pos_x, e_pos_y);
    } else {
      var e_pos_x = e.clientX;
      var e_pos_y = e.clientY;
      var ele_last = e.target;
    }

    if (this.drag) {
      if (this.pos_x_start != e_pos_x || this.pos_y_start != e_pos_y) {
        this.dispatch('nodeMoved', this.ele_selected.id.slice(5));
      } else {
        this.dispatch('nodeSimpleClick', this.ele_selected.id.slice(5));
      }
    }

    if (this.drag_point) {
      this.ele_selected.classList.remove('selected');
      if (this.pos_x_start != e_pos_x || this.pos_y_start != e_pos_y) {
        this.dispatch('rerouteMoved', this.ele_selected.parentElement.classList[2].slice(14));
      }
    }

    if (this.editor_selected) {
      this.canvas_x += (-(this.pos_x - e_pos_x));
      this.canvas_y += (-(this.pos_y - e_pos_y));
      this.editor_selected = false;
    }
    if (this.connection === true) {
      if (ele_last.classList[0] === 'input' || (this.force_first_input && (ele_last.closest('.drawflow_content_node') != null || ele_last.classList[0] === 'drawflow-node'))) {
        if (this.force_first_input && (ele_last.closest('.drawflow_content_node') != null || ele_last.classList[0] === 'drawflow-node')) {
          if (ele_last.closest('.drawflow_content_node') != null) {
            var input_id = ele_last.closest('.drawflow_content_node').parentElement.id;
          } else {
            var input_id = ele_last.id;
          }
          if (Object.keys(this.getNodeFromId(input_id.slice(5)).inputs).length === 0) {
            var input_class = false;
          } else {
            var input_class = 'input_1';
          }
        } else {
          // Fix connection;
          var input_id = ele_last.parentElement.parentElement.id;
          var input_class = ele_last.classList[1];
        }
        const output_id = this.ele_selected.parentElement.parentElement.id;
        const output_class = this.ele_selected.classList[1];

        if (output_id !== input_id && input_class !== false) {
          if (this.container.querySelectorAll(`.connection.node_in_${input_id}.node_out_${output_id}.${output_class}.${input_class}`).length === 0) {
          // Conection no exist save connection

            this.connection_ele.classList.add(`node_in_${input_id}`);
            this.connection_ele.classList.add(`node_out_${output_id}`);
            this.connection_ele.classList.add(output_class);
            this.connection_ele.classList.add(input_class);
            const id_input = input_id.slice(5);
            const id_output = output_id.slice(5);

            this.drawflow.drawflow[this.module].data[id_output].outputs[output_class].connections.push({ node: id_input, output: input_class });
            this.drawflow.drawflow[this.module].data[id_input].inputs[input_class].connections.push({ node: id_output, input: output_class });
            this.updateConnectionNodes(`node-${id_output}`);
            this.updateConnectionNodes(`node-${id_input}`);
            this.dispatch('connectionCreated', {
              output_id: id_output, input_id: id_input, output_class, input_class,
            });
          } else {
            this.dispatch('connectionCancel', true);
            this.connection_ele.remove();
          }

          this.connection_ele = null;
        } else {
        // Connection exists Remove Connection;
          this.dispatch('connectionCancel', true);
          this.connection_ele.remove();
          this.connection_ele = null;
        }
      } else {
        // Remove Connection;
        this.dispatch('connectionCancel', true);
        this.connection_ele.remove();
        this.connection_ele = null;
      }
    }

    this.drag = false;
    this.drag_point = false;
    this.connection = false;
    this.ele_selected = null;
    this.editor_selected = false;

    const squares = document.querySelectorAll('.square-select');

    squares.forEach(square => {
      square.remove();
    });

    this.dispatch('mouseUp', e);
  }

  contextmenu(e) {
    this.dispatch('contextmenu', e);
    e.preventDefault();
    if (this.editor_mode === 'fixed' || this.editor_mode === 'view') {
      return false;
    }
    if (this.precanvas.getElementsByClassName('drawflow-delete').length) {
      this.precanvas.getElementsByClassName('drawflow-delete')[0].remove();
    }
    /*if (this.node_selected || this.connection_selected) {
      const deletebox = document.createElement('div');
      deletebox.classList.add('drawflow-delete');
      deletebox.innerHTML = 'x';
      if (this.node_selected) {
        this.node_selected.appendChild(deletebox);
      }
      if (this.connection_selected && (this.connection_selected.parentElement.classList.length > 1)) {
        deletebox.style.top = `${e.clientY * (this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)) - (this.precanvas.getBoundingClientRect().y * (this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)))}px`;
        deletebox.style.left = `${e.clientX * (this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)) - (this.precanvas.getBoundingClientRect().x * (this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)))}px`;

        this.precanvas.appendChild(deletebox);
      }
    }*/
  }

  mouseover(e) {
    this.dispatch('mouseover', e);
    e.preventDefault();
    this.node_selectedOver = null;
    this.connection_selectedOver = null;
    if (this.editor_mode === 'fixed') {
      // return false;
      e.preventDefault();
      if (e.target.classList[0] === 'parent-drawflow' || e.target.classList[0] === 'drawflow') {
        this.ele_selectedOver = e.target.closest('.parent-drawflow');
      } else {
        return false;
      }
    } else if (this.editor_mode === 'view') {
      if (e.target.closest('.drawflow') != null || e.target.matches('.parent-drawflow')) {
        this.ele_selectedOver = e.target.closest('.parent-drawflow');
        e.preventDefault();
      }
    } else {
      this.ele_selectedOver = e.target;

      if (e.target.closest('.drawflow_content_node') != null) {
        this.ele_selectedOver = e.target.closest('.drawflow_content_node').parentElement;
      }
    }

    let welcomeClass = this.ele_selectedOver.classList[1];
    switch (this.ele_selectedOver.classList[0]) {
      case 'drawflow-node':
        if (this.precanvas.getElementsByClassName('drawflow-delete').length) {
          this.precanvas.getElementsByClassName('drawflow-delete')[0].remove();
        }
        if (this.node_selectedOver != null) {
          if (this.node_selectedOver != this.ele_selectedOver) {
            this.dispatch('nodeUnOver', true);
          }
        }
        if (this.node_selectedOver != this.ele_selectedOver) {

          this.dispatch('nodeOver', this.ele_selectedOver.id.slice(5));
        }
        this.connection_selectedOver = null;
        this.node_selectedOver = this.ele_selectedOver;
        break;
      case 'main-path':
        if (this.node_selectedOver != null) {
          this.node_selectedOver = null;
          this.dispatch('nodeUnOver', true);
        }
        if (this.connection_selectedOver != null) {
          this.connection_selectedOver = null;
        }
        this.connection_selectedOver = this.ele_selectedOver;
        const listclassConnection = this.connection_selectedOver.parentElement.classList;
        if (listclassConnection.length > 1) {
          this.dispatch('connectionOver', {
            output_id: listclassConnection[2].slice(14), input_id: listclassConnection[1].slice(13), output_class: listclassConnection[3], input_class: listclassConnection[4],
          });
          if (this.reroute_fix_curvature) {
            this.connection_selectedOver.parentElement.querySelectorAll('.main-path').forEach((item, i) => {
              item.classList.add('selected');
            });
          }
        }
        break;
      default:

        break;
    }
    if (welcomeClass != 'welcome') {
      if (this.node_selectedOver || this.connection_selectedOver) {
        if (this.precanvas.getElementsByClassName('drawflow-delete').length) {
          this.precanvas.getElementsByClassName('drawflow-delete')[0].remove();
        }
        const deletebox = document.createElement('div');
        const deleteIcon = document.createElement('i');
        deletebox.classList.add('drawflow-delete');
        deleteIcon.className = 'fas fa-trash node-item-delete';
        //deletebox.innerHTML = 'x';

        if (this.node_selectedOver) {
          deletebox.classList.add('node-delete');
          deleteIcon.classList.add('node-delete-icon')
          deletebox.appendChild(deleteIcon);
          this.node_selectedOver.appendChild(deletebox);
          this.lastNode_selectedOver = this.node_selectedOver;
        }
        if (this.connection_selectedOver && (this.connection_selectedOver.parentElement.classList.length > 1)) {
          deletebox.classList.add('connection-delete');
          deleteIcon.classList.add('connection-delete-icon')
          deletebox.appendChild(deleteIcon);

          deletebox.style.top = `${e.clientY * (this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)) - (this.precanvas.getBoundingClientRect().y * (this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)))}px`;
          deletebox.style.left = `${e.clientX * (this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)) - (this.precanvas.getBoundingClientRect().x * (this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)))}px`;
          this.lastConnection_selectedOver = this.connection_selectedOver;
          this.precanvas.appendChild(deletebox);
        }
      }
    }
  }

  contextmenuDel() {
    if (this.precanvas.getElementsByClassName('drawflow-delete').length) {
      this.precanvas.getElementsByClassName('drawflow-delete')[0].remove();
    }
  }

  key_up(e) {
    this.dispatch('keyup', e);
    this.keyPressed = false;
    const squares = document.querySelectorAll('.square-select');

    squares.forEach(square => {
      square.remove();
    });
  }
  key(e) {
    this.dispatch('keydown', e);
    if (this.editor_mode === 'fixed' || this.editor_mode === 'view') {
      return false;
    }
    if (e.key === 'Delete' || (e.key === 'Backspace' && e.metaKey)) {
      if (this.node_selected != null) {
        if (this.first_click.tagName !== 'INPUT' && this.first_click.tagName !== 'TEXTAREA' && this.first_click.hasAttribute('contenteditable') !== true) {
          this.removeNodeId(this.node_selected.id);
        }
      }
      if (this.connection_selected != null) {
        this.removeConnection();
      }
    }
  }

  zoom_enter(event, delta) {
    //if (event.ctrlKey) {
      event.preventDefault();
      if (event.deltaY > 0) {
        // Zoom Out
        this.zoom_out();
      } else {
        // Zoom In
        this.zoom_in();
      }
    //}
  }

  zoom_refresh() {
    this.dispatch('zoom', this.zoom);
    this.canvas_x = (this.canvas_x / this.zoom_last_value) * this.zoom;
    this.canvas_y = (this.canvas_y / this.zoom_last_value) * this.zoom;
    this.zoom_last_value = this.zoom;
    this.precanvas.style.transform = `translate(${this.canvas_x}px, ${this.canvas_y}px) scale(${this.zoom})`;
  }

  backToNode(node) {
    const element = document.getElementById(`node-${node.id}`);

    element?.scrollIntoView({block: 'center', inline: 'center'});
  }

  zoom_in() {
    if (this.zoom < this.zoom_max) {
      this.zoom += this.zoom_value;
      this.zoom_refresh();
    }
  }

  zoom_out() {
    if (this.zoom > this.zoom_min) {
      this.zoom -= this.zoom_value;
      this.zoom_refresh();
    }
  }

  zoom_reset() {
    if (this.zoom != 1) {
      this.zoom = 1;
      this.zoom_refresh();
    }
  }

  createCurvature(start_pos_x, start_pos_y, end_pos_x, end_pos_y, curvature_value, type) {
    const line_x = start_pos_x;
    const line_y = start_pos_y;
    const x = end_pos_x;
    const y = end_pos_y;
    const curvature = curvature_value;
    // type openclose open close other
    switch (type) {
      case 'open':
        if (start_pos_x >= end_pos_x) {
          var hx1 = line_x + Math.abs(x - line_x) * curvature;
          var hx2 = x - Math.abs(x - line_x) * (curvature * -1);
        } else {
          var hx1 = line_x + Math.abs(x - line_x) * curvature;
          var hx2 = x - Math.abs(x - line_x) * curvature;
        }
        return ` M ${line_x} ${line_y} C ${hx1} ${line_y} ${hx2} ${y} ${x}  ${y}`;

        break;
      case 'close':
        if (start_pos_x >= end_pos_x) {
          var hx1 = line_x + Math.abs(x - line_x) * (curvature * -1);
          var hx2 = x - Math.abs(x - line_x) * curvature;
        } else {
          var hx1 = line_x + Math.abs(x - line_x) * curvature;
          var hx2 = x - Math.abs(x - line_x) * curvature;
        }
        return ` M ${line_x} ${line_y} C ${hx1} ${line_y} ${hx2} ${y} ${x}  ${y}`;
        break;
      case 'other':
        if (start_pos_x >= end_pos_x) {
          var hx1 = line_x + Math.abs(x - line_x) * (curvature * -1);
          var hx2 = x - Math.abs(x - line_x) * (curvature * -1);
        } else {
          var hx1 = line_x + Math.abs(x - line_x) * curvature;
          var hx2 = x - Math.abs(x - line_x) * curvature;
        }
        return ` M ${line_x} ${line_y} C ${hx1} ${line_y} ${hx2} ${y} ${x}  ${y}`;
        break;
      default:

        var hx1 = line_x + Math.abs(x - line_x) * curvature;
        var hx2 = x - Math.abs(x - line_x) * curvature;

        return ` M ${line_x} ${line_y} C ${hx1} ${line_y} ${hx2} ${y} ${x}  ${y}`;
    }
  }

  drawConnection(ele) {
    const connection = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.connection_ele = connection;
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.classList.add('main-path');
    path.setAttributeNS(null, 'd', '');
    // path.innerHTML = 'a';
    connection.classList.add('connection');
    connection.appendChild(path);
    this.precanvas.appendChild(connection);
    const id_output = ele.parentElement.parentElement.id.slice(5);
    const output_class = ele.classList[1];
    this.dispatch('connectionStart', { output_id: id_output, output_class });
  }

  updateConnection(eX, eY) {
    const precanvas = this.precanvas;
    const zoom = this.zoom;
    let precanvasWitdhZoom = precanvas.clientWidth / (precanvas.clientWidth * zoom);
    precanvasWitdhZoom = precanvasWitdhZoom || 0;
    let precanvasHeightZoom = precanvas.clientHeight / (precanvas.clientHeight * zoom);
    precanvasHeightZoom = precanvasHeightZoom || 0;
    const path = this.connection_ele.children[0];

    const line_x = this.ele_selected.offsetWidth / 2 + (this.ele_selected.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom;
    const line_y = this.ele_selected.offsetHeight / 2 + (this.ele_selected.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom;

    const x = eX * (this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)) - (this.precanvas.getBoundingClientRect().x * (this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)));
    const y = eY * (this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)) - (this.precanvas.getBoundingClientRect().y * (this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)));

    const curvature = this.curvature;
    const lineCurve = this.createCurvature(line_x, line_y, x, y, curvature, 'openclose');
    path.setAttributeNS(null, 'd', lineCurve);
  }

  addConnection(id_output, id_input, output_class, input_class) {
    const nodeOneModule = this.getModuleFromNodeId(id_output);
    const nodeTwoModule = this.getModuleFromNodeId(id_input);
    if (nodeOneModule === nodeTwoModule) {
      const dataNode = this.getNodeFromId(id_output);
      let exist = false;
      for (const checkOutput in dataNode.outputs[output_class].connections) {
        const connectionSearch = dataNode.outputs[output_class].connections[checkOutput];
        if (connectionSearch.node == id_input && connectionSearch.output == input_class) {
          exist = true;
        }
      }
      // Check connection exist
      if (exist === false) {
        // Create Connection
        this.drawflow.drawflow[nodeOneModule].data[id_output].outputs[output_class].connections.push({ node: id_input.toString(), output: input_class });
        this.drawflow.drawflow[nodeOneModule].data[id_input].inputs[input_class].connections.push({ node: id_output.toString(), input: output_class });

        if (this.module === nodeOneModule) {
        // Draw connection
          const connection = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
          const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
          path.classList.add('main-path');
          path.setAttributeNS(null, 'd', '');
          // path.innerHTML = 'a';
          connection.classList.add('connection');
          connection.classList.add(`node_in_node-${id_input}`);
          connection.classList.add(`node_out_node-${id_output}`);
          connection.classList.add(output_class);
          connection.classList.add(input_class);
          connection.appendChild(path);
          this.precanvas.appendChild(connection);
          this.updateConnectionNodes(`node-${id_output}`);
          this.updateConnectionNodes(`node-${id_input}`);
        }

        this.dispatch('connectionCreated', {
          output_id: id_output, input_id: id_input, output_class, input_class,
        });
      }
    }
  }

  updateConnectionNodes(id) {
    // Aquí nos quedamos;
    const idSearch = `node_in_${id}`;
    const idSearchOut = `node_out_${id}`;
    const line_path = this.line_path / 2;
    const container = this.container;
    const precanvas = this.precanvas;
    const curvature = this.curvature;
    const createCurvature = this.createCurvature;
    const reroute_curvature = this.reroute_curvature;
    const reroute_curvature_start_end = this.reroute_curvature_start_end;
    const reroute_fix_curvature = this.reroute_fix_curvature;
    const rerouteWidth = this.reroute_width;
    const zoom = this.zoom;
    let precanvasWitdhZoom = precanvas.clientWidth / (precanvas.clientWidth * zoom);
    precanvasWitdhZoom = precanvasWitdhZoom || 0;
    let precanvasHeightZoom = precanvas.clientHeight / (precanvas.clientHeight * zoom);
    precanvasHeightZoom = precanvasHeightZoom || 0;

    const elemsOut = container.querySelectorAll(`.${idSearchOut}`);

    Object.keys(elemsOut).map((item, index) => {
      if (elemsOut[item].querySelector('.point') === null) {
        const elemtsearchId_out = container.querySelector(`#${id}`);

        const id_search = elemsOut[item].classList[1].replace('node_in_', '');
        const elemtsearchId = container.querySelector(`#${id_search}`);

        const elemtsearch = elemtsearchId.querySelectorAll(`.${elemsOut[item].classList[4]}`)[0];
        const eX = elemtsearch.offsetWidth / 2 + (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom;
        const eY = elemtsearch.offsetHeight / 2 + (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom;

        const elemtsearchOut = elemtsearchId_out.querySelectorAll(`.${elemsOut[item].classList[3]}`)[0];

        const line_x = elemtsearchOut.offsetWidth / 2 + (elemtsearchOut.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom;
        const line_y = elemtsearchOut.offsetHeight / 2 + (elemtsearchOut.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom;

        const x = eX;
        const y = eY;

        const lineCurve = createCurvature(line_x, line_y, x, y, curvature, 'openclose');
        elemsOut[item].children[0].setAttributeNS(null, 'd', lineCurve);
      } else {
        const points = elemsOut[item].querySelectorAll('.point');
        let linecurve = '';
        const reoute_fix = [];
        points.forEach((item, i) => {
          if (i === 0 && ((points.length - 1) === 0)) {
            var elemtsearchId_out = container.querySelector(`#${id}`);
            var elemtsearch = item;

            var eX = (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom + rerouteWidth;
            var eY = (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom + rerouteWidth;

            var elemtsearchOut = elemtsearchId_out.querySelectorAll(`.${item.parentElement.classList[3]}`)[0];
            var line_x = elemtsearchOut.offsetWidth / 2 + (elemtsearchOut.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom;
            var line_y = elemtsearchOut.offsetHeight / 2 + (elemtsearchOut.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom;
            var x = eX;
            var y = eY;

            var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'open');
            linecurve += lineCurveSearch;
            reoute_fix.push(lineCurveSearch);

            var elemtsearchId_out = item;
            var id_search = item.parentElement.classList[1].replace('node_in_', '');
            var elemtsearchId = container.querySelector(`#${id_search}`);
            var elemtsearch = elemtsearchId.querySelectorAll(`.${item.parentElement.classList[4]}`)[0];

            var elemtsearchIn = elemtsearchId.querySelectorAll(`.${item.parentElement.classList[4]}`)[0];
            var eX = elemtsearchIn.offsetWidth / 2 + (elemtsearchIn.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom;
            var eY = elemtsearchIn.offsetHeight / 2 + (elemtsearchIn.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom;

            var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom + rerouteWidth;
            var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom + rerouteWidth;
            var x = eX;
            var y = eY;

            var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'close');
            linecurve += lineCurveSearch;
            reoute_fix.push(lineCurveSearch);
          } else if (i === 0) {
            var elemtsearchId_out = container.querySelector(`#${id}`);
            var elemtsearch = item;

            var eX = (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom + rerouteWidth;
            var eY = (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom + rerouteWidth;

            var elemtsearchOut = elemtsearchId_out.querySelectorAll(`.${item.parentElement.classList[3]}`)[0];
            var line_x = elemtsearchOut.offsetWidth / 2 + (elemtsearchOut.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom;
            var line_y = elemtsearchOut.offsetHeight / 2 + (elemtsearchOut.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom;

            var x = eX;
            var y = eY;

            var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'open');
            linecurve += lineCurveSearch;
            reoute_fix.push(lineCurveSearch);

            // SECOND
            var elemtsearchId_out = item;
            var elemtsearch = points[i + 1];

            var eX = (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom + rerouteWidth;
            var eY = (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom + rerouteWidth;
            var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom + rerouteWidth;
            var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom + rerouteWidth;
            var x = eX;
            var y = eY;

            var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature, 'other');
            linecurve += lineCurveSearch;
            reoute_fix.push(lineCurveSearch);
          } else if (i === (points.length - 1)) {
            var elemtsearchId_out = item;

            var id_search = item.parentElement.classList[1].replace('node_in_', '');
            var elemtsearchId = container.querySelector(`#${id_search}`);
            var elemtsearch = elemtsearchId.querySelectorAll(`.${item.parentElement.classList[4]}`)[0];

            var elemtsearchIn = elemtsearchId.querySelectorAll(`.${item.parentElement.classList[4]}`)[0];
            var eX = elemtsearchIn.offsetWidth / 2 + (elemtsearchIn.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom;
            var eY = elemtsearchIn.offsetHeight / 2 + (elemtsearchIn.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom;
            var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * (precanvas.clientWidth / (precanvas.clientWidth * zoom)) + rerouteWidth;
            var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * (precanvas.clientHeight / (precanvas.clientHeight * zoom)) + rerouteWidth;
            var x = eX;
            var y = eY;

            var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'close');
            linecurve += lineCurveSearch;
            reoute_fix.push(lineCurveSearch);
          } else {
            var elemtsearchId_out = item;
            var elemtsearch = points[i + 1];

            var eX = (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * (precanvas.clientWidth / (precanvas.clientWidth * zoom)) + rerouteWidth;
            var eY = (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * (precanvas.clientHeight / (precanvas.clientHeight * zoom)) + rerouteWidth;
            var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * (precanvas.clientWidth / (precanvas.clientWidth * zoom)) + rerouteWidth;
            var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * (precanvas.clientHeight / (precanvas.clientHeight * zoom)) + rerouteWidth;
            var x = eX;
            var y = eY;

            var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature, 'other');
            linecurve += lineCurveSearch;
            reoute_fix.push(lineCurveSearch);
          }
        });
        if (reroute_fix_curvature) {
          reoute_fix.forEach((itempath, i) => {
            elemsOut[item].children[i].setAttributeNS(null, 'd', itempath);
          });
        } else {
          elemsOut[item].children[0].setAttributeNS(null, 'd', linecurve);
        }
      }
    });

    const elems = container.querySelectorAll(`.${idSearch}`);
    Object.keys(elems).map((item, index) => {
      if (elems[item].querySelector('.point') === null) {
        var elemtsearchId_in = container.querySelector(`#${id}`);

        const id_search = elems[item].classList[2].replace('node_out_', '');

        const elemtsearchId = container.querySelector(`#${id_search}`);
        const elemtsearch = elemtsearchId.querySelectorAll(`.${elems[item].classList[3]}`)[0];

        const line_x = elemtsearch.offsetWidth / 2 + (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom;
        const line_y = elemtsearch.offsetHeight / 2 + (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom;

        var elemtsearchId_in = elemtsearchId_in.querySelectorAll(`.${elems[item].classList[4]}`)[0];
        const x = elemtsearchId_in.offsetWidth / 2 + (elemtsearchId_in.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom;
        const y = elemtsearchId_in.offsetHeight / 2 + (elemtsearchId_in.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom;

        const lineCurve = createCurvature(line_x, line_y, x, y, curvature, 'openclose');
        elems[item].children[0].setAttributeNS(null, 'd', lineCurve);
      } else {
        const points = elems[item].querySelectorAll('.point');
        let linecurve = '';
        const reoute_fix = [];
        points.forEach((item, i) => {
          if (i === 0 && ((points.length - 1) === 0)) {
            var elemtsearchId_out = container.querySelector(`#${id}`);
            var elemtsearch = item;

            var line_x = (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom + rerouteWidth;
            var line_y = (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom + rerouteWidth;

            var elemtsearchIn = elemtsearchId_out.querySelectorAll(`.${item.parentElement.classList[4]}`)[0];
            var eX = elemtsearchIn.offsetWidth / 2 + (elemtsearchIn.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom;
            var eY = elemtsearchIn.offsetHeight / 2 + (elemtsearchIn.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom;

            var x = eX;
            var y = eY;

            var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'close');
            linecurve += lineCurveSearch;
            reoute_fix.push(lineCurveSearch);

            var elemtsearchId_out = item;
            var id_search = item.parentElement.classList[2].replace('node_out_', '');
            var elemtsearchId = container.querySelector(`#${id_search}`);
            var elemtsearch = elemtsearchId.querySelectorAll(`.${item.parentElement.classList[3]}`)[0];

            var elemtsearchOut = elemtsearchId.querySelectorAll(`.${item.parentElement.classList[3]}`)[0];
            var line_x = elemtsearchOut.offsetWidth / 2 + (elemtsearchOut.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom;
            var line_y = elemtsearchOut.offsetHeight / 2 + (elemtsearchOut.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom;

            var eX = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom + rerouteWidth;
            var eY = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom + rerouteWidth;
            var x = eX;
            var y = eY;

            var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'open');
            linecurve += lineCurveSearch;
            reoute_fix.push(lineCurveSearch);
          } else if (i === 0) {
            // FIRST
            var elemtsearchId_out = item;
            var id_search = item.parentElement.classList[2].replace('node_out_', '');
            var elemtsearchId = container.querySelector(`#${id_search}`);
            var elemtsearch = elemtsearchId.querySelectorAll(`.${item.parentElement.classList[3]}`)[0];
            var elemtsearchOut = elemtsearchId.querySelectorAll(`.${item.parentElement.classList[3]}`)[0];
            var line_x = elemtsearchOut.offsetWidth / 2 + (elemtsearchOut.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom;
            var line_y = elemtsearchOut.offsetHeight / 2 + (elemtsearchOut.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom;

            var eX = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom + rerouteWidth;
            var eY = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom + rerouteWidth;
            var x = eX;
            var y = eY;

            var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'open');
            linecurve += lineCurveSearch;
            reoute_fix.push(lineCurveSearch);

            // SECOND
            var elemtsearchId_out = item;
            var elemtsearch = points[i + 1];

            var eX = (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom + rerouteWidth;
            var eY = (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom + rerouteWidth;
            var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom + rerouteWidth;
            var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom + rerouteWidth;
            var x = eX;
            var y = eY;

            var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature, 'other');
            linecurve += lineCurveSearch;
            reoute_fix.push(lineCurveSearch);
          } else if (i === (points.length - 1)) {
            var elemtsearchId_out = item;

            var id_search = item.parentElement.classList[1].replace('node_in_', '');
            var elemtsearchId = container.querySelector(`#${id_search}`);
            var elemtsearch = elemtsearchId.querySelectorAll(`.${item.parentElement.classList[4]}`)[0];

            var elemtsearchIn = elemtsearchId.querySelectorAll(`.${item.parentElement.classList[4]}`)[0];
            var eX = elemtsearchIn.offsetWidth / 2 + (elemtsearchIn.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom;
            var eY = elemtsearchIn.offsetHeight / 2 + (elemtsearchIn.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom;

            var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom + rerouteWidth;
            var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom + rerouteWidth;
            var x = eX;
            var y = eY;

            var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature_start_end, 'close');
            linecurve += lineCurveSearch;
            reoute_fix.push(lineCurveSearch);
          } else {
            var elemtsearchId_out = item;
            var elemtsearch = points[i + 1];

            var eX = (elemtsearch.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom + rerouteWidth;
            var eY = (elemtsearch.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom + rerouteWidth;
            var line_x = (elemtsearchId_out.getBoundingClientRect().x - precanvas.getBoundingClientRect().x) * precanvasWitdhZoom + rerouteWidth;
            var line_y = (elemtsearchId_out.getBoundingClientRect().y - precanvas.getBoundingClientRect().y) * precanvasHeightZoom + rerouteWidth;
            var x = eX;
            var y = eY;

            var lineCurveSearch = createCurvature(line_x, line_y, x, y, reroute_curvature, 'other');
            linecurve += lineCurveSearch;
            reoute_fix.push(lineCurveSearch);
          }
        });
        if (reroute_fix_curvature) {
          reoute_fix.forEach((itempath, i) => {
            elems[item].children[i].setAttributeNS(null, 'd', itempath);
          });
        } else {
          elems[item].children[0].setAttributeNS(null, 'd', linecurve);
        }
      }
    });
  }

  dblclick(e) {
    if (this.connection_selected != null && this.reroute) {
      this.createReroutePoint(this.connection_selected);
    }

    if (e.target.classList[0] === 'point') {
      this.removeReroutePoint(e.target);
    }
  }

  isEmpty(item) {
    if(item == null || item == '' || item == undefined) {
      return true;
    } else {
      if (item.length == 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  async publishFlow(flow, callback) {
    try {
      console.log('Body flow', flow);
      Loader.showModal('Publicando...');
      let nodes = JSON.parse(flow.nodes);
      let draftNodes = JSON.parse(flow.nodes_draft);
      let response = null;

      if (flow.importing === true || flow.importing === 'true') {
        response = await webApi.delete(`flows/delete-actions/${flow.id}`);
      } else {
        //Delete old nodes
        Loader.showModal('Removendo nodes antigos...');
        for (var i in draftNodes.drawflow.Home.data) {
          let old = nodes.drawflow.Home.data[i];
          let node = draftNodes.drawflow.Home.data[i];
          if(old == undefined || old == null) {
            await webApi.post(`flow/delete-action`, {
              name: node.name,
              node: node,
              flow_id: flow.id,
            });
          }
        }
      }

      //Criando backs
      Loader.showModal('Atualizando back nodes...');
      for (var i in draftNodes.drawflow.Home.data) {
        let node = draftNodes.drawflow.Home.data[i];
        let backNode = draftNodes.drawflow.Home.data[node.data.back_node_id];
        console.log(backNode);
        if(backNode) {
          if (backNode.data.action_id == null || backNode.data.action_id == '' || backNode.data.action_id == undefined || backNode.data.action_id.length == 0) {
            if(node.back_id == "") {
              delete node.back_name;
              delete node.back_id;
            }
            switch(backNode.name) {
              case 'loop_flow':
                response = await this.createLoop(node.data.back_node_id, backNode, draftNodes.drawflow.Home.data[backNode.data.back_node_id], draftNodes, nodes, flow);
                break;
              default:
                response = await webApi.post(`flow/action`, {
                  node: backNode,
                  flow_id: flow.id,
                });
            }
            var node_id = null;
            if(response.id) {
              node_id = response.id;
            } else {
              node_id = response.data.id;
            }
            draftNodes.drawflow.Home.data[node.data.back_node_id].data.action_id = node_id;
            draftNodes.drawflow.Home.data[i].data.back_id = node_id;
          } else {
            draftNodes.drawflow.Home.data[i].data.back_id = backNode.data.action_id;
          }
        }
      }


      //Make new nodes
      Loader.showModal('Criando novos nodes...');
      for (var i in draftNodes.drawflow.Home.data) {
        let node     = draftNodes.drawflow.Home.data[i];
        let backNode = draftNodes.drawflow.Home.data[node.data.back_node_id];
        let first    = node.data.first ? node.data.first : false;
        let to_link  = first ? true : false;
        let data     = node.data;
        var node_id  = null;
        let backId   = null;
        let backName = null;
        let action   = {};

        if ((data.back_node_id != null || data.back_node_id != undefined) && data.first == false) {
          backId = backNode.data.action_id;
          backName = backNode.data.action_name ? backNode.data.action_name : null;

          data.back_id = backId;
          data.back_name = backName;
          node.data.back_id = backId;
          node.data.back_name = backName;
        }
        if (this.isEmpty(node.data.action_id)) {
          response = await webApi.post(`flow/action`, {
            node: node,
            flow_id: flow.id,
          });

          if(response.id != undefined) {
            node_id = response.id;
          } else {
            node_id = response.data.id;
          }

          draftNodes.drawflow.Home.data[i].data.action_id = node_id;
          draftNodes.drawflow.Home.data[i].data.back_id = backId;
          draftNodes.drawflow.Home.data[i].data.back_name = backName;
        } else {
          draftNodes.drawflow.Home.data[i].data.back_id = backId;
          draftNodes.drawflow.Home.data[i].data.back_name = backName;
          if(node.name != 'loop_flow') {
            if (node.name == 'send_file') {
              console.log('Aqui node', node);
            }
            response = await webApi.post(`flow/update-action`, {
              node: node,
              flow_id: flow.id,
            });

            if(response.id != undefined) {
              node_id = response.id;
            } else {
              node_id = response.data.id;
            }
            //draftNodes.drawflow.Home.data[i].data.action_id = node_id;
            draftNodes.drawflow.Home.data[i].data.back_id = backId;
            draftNodes.drawflow.Home.data[i].data.back_name = backName;
          }
        }
      }

      //Remake interactive and Template
      Loader.showModal('Criando nodes interativos...');
      for (var i in draftNodes.drawflow.Home.data) {
        let node     = draftNodes.drawflow.Home.data[i];
        let backNode = draftNodes.drawflow.Home.data[node.data.back_node_id];
        let first    = node.data.first ? node.data.first : false;
        let to_link  = first ? true : false;
        let data     = node.data;
        var node_id  = null;
        let backId   = null;
        let backName = null;
        let action   = {};
        let response = null;

        if ((data.back_node_id != null || data.back_node_id != undefined) && data.first == false) {
          backId = backNode.data.action_id;
          backName = backNode.data.action_name ? backNode.data.action_name : null;

          data.back_id = backId;
          data.back_name = backName;
        }

        switch(node.name) {
          case 'catalog_whatsapp':
            response = await webApi.post(`flow/delete-action`, {
              node: node,
              flow_id: flow.id,
            });

            response = await webApi.post(`flow/action`, {
              node: node,
              flow_id: flow.id,
            });

            if(response.id != undefined) {
              node_id = response.id;
            } else {
              node_id = response.data.id;
            }
            draftNodes.drawflow.Home.data[i].data.action_id = node_id;
            draftNodes.drawflow.Home.data[i].data.back_id = backId;
            draftNodes.drawflow.Home.data[i].data.back_name = backName;
            node.data.action_id = node_id;
            data.action_id = node_id;
            break;
          case 'interactive_whatsapp':
            if(data.template.type == 'buttons') {
              data.template.sections = null;
            }
            if(data.template.type == 'menu') {
              data.template.buttons = null;
            }

            response = await webApi.post(`flow/delete-action`, {
              node: node,
              flow_id: flow.id,
            });

            response = await webApi.post(`flow/action`, {
              node: node,
              flow_id: flow.id,
            });

            if(response.id != undefined) {
              node_id = response.id;
            } else {
              node_id = response.data.id;
            }
            draftNodes.drawflow.Home.data[i].data.action_id = node_id;
            draftNodes.drawflow.Home.data[i].data.back_id = backId;
            draftNodes.drawflow.Home.data[i].data.back_name = backName;
            node.data.action_id = node_id;
            data.action_id = node_id;
            break;
          case 'template_whatsapp':
            action = {
              'first':first,
              'to_link': to_link,
              'flow_id': flow.id,
              'main_user': true,
              'key': data.key,
              'language_policy': 'deterministic',
              'language_code': this.isEmpty(data.template.language_code) ? data.template.language_code : 'PT_BR',
              'template_name': data.template.name,
              'class_name_back': this.isEmpty(data.back_name) ? data.back_name : null,
              'header_link': this.isEmpty(data.template.header_link) ? data.template.header_link : null,
              'back': this.isEmpty(data.back_id) ? data.back_id : null
            };
            let parameters = [];
            if (this.isEmpty(data.template.params)) {
              for (var param in data.template.params) {
                parameters.push({
                  'value': param.text,
                  'sort': param.index,
                  'type': 'text',
                })
              }
            }
            action.parameters = parameters;
            await webApi.post(`flow/delete-action`, {
              node: node,
              flow_id: flow.id,
            });

            response = await webApi.post(`flow/action`, {
              node: node,
              flow_id: flow.id,
            });
            if(response.id != undefined) {
              node_id = response.id;
            } else {
              node_id = response.data.id;
            }
            draftNodes.drawflow.Home.data[i].data.action_id = node_id;
            draftNodes.drawflow.Home.data[i].data.back_id = backId;
            draftNodes.drawflow.Home.data[i].data.back_name = backName;
            break;
          case 'consumir_api':
            action = {
              'first': first,
              'flow_id': flow.id,
              'to_link': to_link,
              'main_user': true,
              'key': data.key,
              'class_name_back': this.isEmpty(data.back_name) ? data.back_name : null,
              'back': this.isEmpty(data.back_id) ? data.back_id : null,
              'auth': data.auth,
              'end_point': data.end_point,
              'method': data.method,
              'type_auth': data.type_auth,
              'parameters_body': this.isEmpty(data.parameters_body) ? data.parameters_body : [],
              'json_body': this.isEmpty(data.json_body) ? data.json_body : '',
              'parameters_path': this.isEmpty(data.parameters_path) ? data.parameters_path : [],
            };

            let failed_action_node_id = data.failed_action_node_id;
            if(failed_action_node_id) {
              if(draftNodes.drawflow.Home.data[failed_action_node_id]) {
                node.data.failed_action_id = draftNodes.drawflow.Home.data[failed_action_node_id].data.action_id;
                node.data.failed_class_name =  draftNodes.drawflow.Home.data[failed_action_node_id].data.action_name;
              }
            }

            response = await webApi.post(`flow/delete-action`, {
              node: node,
              flow_id: flow.id,
            });

            response = await webApi.post(`flow/action`, {
              node: node,
              flow_id: flow.id,
            });

            if(response.id != undefined) {
              node_id = response.id;
            } else {
              node_id = response.data.id;
            }
            draftNodes.drawflow.Home.data[i].data.action_id = node_id;
            draftNodes.drawflow.Home.data[i].data.back_id = backId;
            draftNodes.drawflow.Home.data[i].data.back_name = backName;
            break;
          }
      }

      //Make loop
      Loader.showModal('Criando loops...');
      for (var i in draftNodes.drawflow.Home.data) {
        let node  = draftNodes.drawflow.Home.data[i];
        let backNode = draftNodes.drawflow.Home.data[node.data.back_node_id];
        switch(node.name) {
          case 'loop_flow':
            await this.createLoop(i, node, backNode, draftNodes, nodes, flow);
            break;
          default:
        }
      }

      //Update controllers
      Loader.showModal('Atualizando controllers...');
      for (var i in draftNodes.drawflow.Home.data) {
        let controllerId = null;
        let controllerName = null;
        let currentController = null;
        let node = draftNodes.drawflow.Home.data[i]
        let data = node.data;
        if (!this.isEmpty(data.controller_id) && data.first == false && !this.isEmpty(draftNodes.drawflow.Home.data[data.controller_id])) {
          controllerId = draftNodes.drawflow.Home.data[data.controller_id].data.action_id;
          controllerName = !this.isEmpty(draftNodes.drawflow.Home.data[data.controller_id].data.action_name) ? draftNodes.drawflow.Home.data[data.controller_id].data.action_name : null;
          currentController = !this.isEmpty(data.current_controller) ? data.current_controller : null;
        }

        if (!this.isEmpty(data.action_id) && !this.isEmpty(controllerName) && !this.isEmpty(controllerId)){
          draftNodes.drawflow.Home.data[i].data.controller = currentController ? controllerId : null;
          draftNodes.drawflow.Home.data[i].data.class_name_controller = currentController ? controllerName : null;
          node.data.controller = currentController ? controllerId : null;
          node.data.class_name_controller = currentController ? controllerName : null;
          if(node.name != 'loop_flow'){
            response = await webApi.post(`flow/update-action`, {
              node: node,
              flow_id: flow.id,
            });
          }
        }
      }

      for (var i in draftNodes.drawflow.Home.data) {
        let node = draftNodes.drawflow.Home.data[i];
        if(node.name == 'branch' && !this.isEmpty(node.data.otherwise_id)) {
          if(draftNodes.drawflow.Home.data[node.data.otherwise_id] && node.data.action_id) {
            node.data.otherwise = draftNodes.drawflow.Home.data[node.data.otherwise_id].data.action_id;

            response = await webApi.post(`flow/update-action`, {
              node: node,
              flow_id: flow.id,
            });
            draftNodes.drawflow.Home.data[i].data.otherwise = draftNodes.drawflow.Home.data[node.data.otherwise_id].data.action_id;
          }
        }
      }

      Loader.showModal('Limpando nodes...');
      //Clear connections
      response = await webApi.post(`flow/clear-connections`, {
        flow_id: flow.id,
      });

      //createNextAdditional
      for (var i in draftNodes.drawflow.Home.data) {
        let node  = draftNodes.drawflow.Home.data[i];
        let data  = node.data;
        let currentNode = null;
        let nextNodeId  = null;
        let nextNode    = null;
        let action      = null;
        let response    = null;
        switch(node.name) {
          case 'next_additional':
            if(!this.isEmpty(node.outputs.output_1.connections[0])) {
              currentNode = draftNodes.drawflow.Home.data[data.back_node_id];
              nextNodeId = node.outputs.output_1.connections[0].node;
              nextNode = draftNodes.drawflow.Home.data[nextNodeId];
              action = {
                'current_class_name': currentNode.data.action_name,
                'flow_action_id': currentNode.data.action_id,
                'flow_action_id_next': nextNode.data.action_id,
                'next_class_name': nextNode.data.action_name,
              };
              let response = await webApi.post(`flow/get-additional`, {
                flow_action_id: action.flow_action_id,
                flow_action_id_next: action.flow_action_id_next,
              });

              if(this.isEmpty(response.data) || this.isEmpty(response.id)) {
                if(response.data.length == 0) {
                  try {
                    response = await webApi.post(`flow/next-additional`, {
                      flow_id: flow.id,
                      node: action,
                    });
                  } catch (error) {
                    console.log('Erro node: '+error.message);
                  }
                }
              }
              draftNodes.drawflow.Home.data[i].data.action_id = response.id;
            }
            break;
        }
      }

      for (var i in draftNodes.drawflow.Home.data) {
        let node  = draftNodes.drawflow.Home.data[i];
        let data  = node.data;
        let currentNode = null;
        let nextNodeId  = null;

        if(node.name != 'welcome' && node.name != 'flow_end') {
          if(!this.isEmpty(draftNodes.drawflow.Home.data[i].outputs.output_1)) {
            for (var connection in draftNodes.drawflow.Home.data[i].outputs.output_1.connections) {
              let con = draftNodes.drawflow.Home.data[i].outputs.output_1.connections[connection];
              currentNode = draftNodes.drawflow.Home.data[i];
              nextNodeId = con.node;
              if(!this.isEmpty(draftNodes.drawflow.Home.data[nextNodeId])) {
                  let nextNode = draftNodes.drawflow.Home.data[nextNodeId];
                  if (currentNode.data.action_name != 'NextAdditional'
                    && currentNode.data.action_name != 'LoopFlow'
                    && nextNode.data.action_name != 'NextAdditional') {

                    let action = {
                      'current_class_name': currentNode.data.action_name,
                      'flow_action_id': currentNode.data.action_id,
                      'flow_action_id_next': nextNode.data.action_id,
                      'next_class_name': nextNode.data.action_name,
                    };
                    let response = await webApi.post(`flow/get-additional`, {
                      flow_action_id: action.flow_action_id,
                      flow_action_id_next: action.flow_action_id_next,
                    });

                    if(this.isEmpty(response.data) || this.isEmpty(response.id)) {
                      if(response.data.length == 0) {
                        try {
                          response = await webApi.post(`flow/next-additional`, {
                            flow_id: flow.id,
                            node: action,
                          });
                        } catch (error) {
                          console.log('Erro node: '+error.message);
                          console.log(node);
                        }
                      }
                    }
                  }
              } else {
               console.log('Não existe');
               console.log(nextNodeId);
              }
            }
          }

          if(!this.isEmpty(draftNodes.drawflow.Home.data[i].outputs.output_2)) {
            for (var connection in draftNodes.drawflow.Home.data[i].outputs.output_2.connections) {
              let con = draftNodes.drawflow.Home.data[i].outputs.output_2.connections[connection];
              currentNode = draftNodes.drawflow.Home.data[i];
              nextNodeId = con.node;
              if(!this.isEmpty(draftNodes.drawflow.Home.data[nextNodeId])) {
                  let nextNode = draftNodes.drawflow.Home.data[nextNodeId];
                  if (currentNode.data.action_name != 'NextAdditional' && nextNode.data.action_name != 'NextAdditional') {

                    let action = {
                      'current_class_name': currentNode.data.action_name,
                      'flow_action_id': currentNode.data.action_id,
                      'flow_action_id_next': nextNode.data.action_id,
                      'next_class_name': nextNode.data.action_name,
                    };
                    let response = await webApi.post(`flow/get-additional`, {
                      flow_action_id: action.flow_action_id,
                      flow_action_id_next: action.flow_action_id_next,
                    });
                    if(this.isEmpty(response.data) || this.isEmpty(response.id)) {
                      if(response.data.length == 0) {
                        try {
                          response = await webApi.post(`flow/next-additional`, {
                            flow_id: flow.id,
                            node: action,
                          });
                        } catch (error) {
                          console.log('Erro node: '+error.message);
                          console.log(node);
                        }
                      }
                    }
                  }
              } else {
               console.log('Não existe');
               console.log(nextNodeId);
              }
            }
          }
        }
      }
      Loader.hide();
      callback(draftNodes);
    } catch(error) {
      console.log('Erro ao publicar flow');
      console.log(error);
      Loader.hide();
      callback(null, error);
    }
  }
  /*
    @i posicao do node no loop
    @node o node que precisa ser registrado
    @backNode representa o node pai do elemento
  */
  async createLoop(i, node, backNode, draftNodes, nodes, flow) {
    let response = null;
    let backId = null;
    let backName = null;
    const data = node.data;
    let first    = this.isEmpty(data.first) ? data.first : false;
    let to_link  = first ? true : false;
    if ((data.back_node_id != null || data.back_node_id != undefined) && data.first == false) {
      backId = backNode.data.action_id;
      backName = backNode.data.action_name ? backNode.data.action_name : null;

      data.back_id = backId;
      data.back_name = backName;
    }
    console.log('data.send_action_id', data);
    console.log(draftNodes.drawflow.Home.data[data.send_action_id]);
    if (this.isEmpty(data.action_id)) {
      node.data = {
        ...node.data,
        'to_link': to_link,
        'first': first,
        'flow_id': flow.id,
        'main_user': true,
        'send_action': draftNodes.drawflow.Home.data[data.send_action_id].data.action_id,
        'class_name_action': draftNodes.drawflow.Home.data[data.send_action_id].data.action_name,
        'attribute': this.isEmpty(data.attribute) ? data.attribute : '',
        'key': data.key,
        'class_name_back': this.isEmpty(data.back_name) ? data.back_name : null,
        'back': this.isEmpty(data.back_id) ? data.back_id : null,
        'wait_finish': true
      };

      response = await webApi.post(`flow/action`, {
        node: node,
        flow_id: flow.id,
      });

      if (this.isEmpty(response.id)) {
        draftNodes.drawflow.Home.data[i].data.action_id = response.id;
        draftNodes.drawflow.Home.data[i].data.back_id = backId;
        draftNodes.drawflow.Home.data[i].data.back_name = backName;
        draftNodes.drawflow.Home.data[i].data.send_action = draftNodes.drawflow.Home.data[data.send_action_id].data.action_id;
        draftNodes.drawflow.Home.data[i].data.class_name_action = draftNodes.drawflow.Home.data[data.send_action_id].data.action_name;
      }
    } else {
      draftNodes.drawflow.Home.data[i].data.back_id = backId;
      draftNodes.drawflow.Home.data[i].data.back_name = backName;
      draftNodes.drawflow.Home.data[i].data.send_action = draftNodes.drawflow.Home.data[data.send_action_id].data.action_id;
      draftNodes.drawflow.Home.data[i].data.class_name_action = draftNodes.drawflow.Home.data[data.send_action_id].data.action_name;
      response = await webApi.post(`flow/update-action`, {
        node: draftNodes.drawflow.Home.data[i],
        flow_id: flow.id,
      });
      draftNodes.drawflow.Home.data[i].data.action_id = draftNodes.drawflow.Home.data[i].data.action_id;
    }
    return response;
  }

  createReroutePoint(ele) {
    this.connection_selected.classList.remove('selected');
    const nodeUpdate = this.connection_selected.parentElement.classList[2].slice(9);
    const nodeUpdateIn = this.connection_selected.parentElement.classList[1].slice(13);
    const output_class = this.connection_selected.parentElement.classList[3];
    const input_class = this.connection_selected.parentElement.classList[4];
    this.connection_selected = null;
    const point = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    point.classList.add('point');
    const pos_x = this.pos_x * (this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)) - (this.precanvas.getBoundingClientRect().x * (this.precanvas.clientWidth / (this.precanvas.clientWidth * this.zoom)));
    const pos_y = this.pos_y * (this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)) - (this.precanvas.getBoundingClientRect().y * (this.precanvas.clientHeight / (this.precanvas.clientHeight * this.zoom)));

    point.setAttributeNS(null, 'cx', pos_x);
    point.setAttributeNS(null, 'cy', pos_y);
    point.setAttributeNS(null, 'r', this.reroute_width);

    let position_add_array_point = 0;
    if (this.reroute_fix_curvature) {
      const numberPoints = ele.parentElement.querySelectorAll('.main-path').length;
      const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      path.classList.add('main-path');
      path.setAttributeNS(null, 'd', '');

      ele.parentElement.insertBefore(path, ele.parentElement.children[numberPoints]);
      if (numberPoints === 1) {
        ele.parentElement.appendChild(point);
      } else {
        const search_point = Array.from(ele.parentElement.children).indexOf(ele);
        position_add_array_point = search_point;
        ele.parentElement.insertBefore(point, ele.parentElement.children[search_point + numberPoints + 1]);
      }
    } else {
      ele.parentElement.appendChild(point);
    }

    const nodeId = nodeUpdate.slice(5);
    const searchConnection = this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections.findIndex((item, i) => item.node === nodeUpdateIn && item.output === input_class);

    if (this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points === undefined) {
      this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points = [];
    }

    if (this.reroute_fix_curvature) {
      if (position_add_array_point > 0 || this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points !== []) {
        this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points.splice(position_add_array_point, 0, { pos_x, pos_y });
      } else {
        this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points.push({ pos_x, pos_y });
      }

      ele.parentElement.querySelectorAll('.main-path').forEach((item, i) => {
        item.classList.remove('selected');
      });
    } else {
      this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points.push({ pos_x, pos_y });
    }

    this.dispatch('addReroute', nodeId);
    this.updateConnectionNodes(nodeUpdate);
  }

  removeReroutePoint(ele) {
    const nodeUpdate = ele.parentElement.classList[2].slice(9);
    const nodeUpdateIn = ele.parentElement.classList[1].slice(13);
    const output_class = ele.parentElement.classList[3];
    const input_class = ele.parentElement.classList[4];

    let numberPointPosition = Array.from(ele.parentElement.children).indexOf(ele);
    const nodeId = nodeUpdate.slice(5);
    const searchConnection = this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections.findIndex((item, i) => item.node === nodeUpdateIn && item.output === input_class);

    if (this.reroute_fix_curvature) {
      const numberMainPath = ele.parentElement.querySelectorAll('.main-path').length;
      ele.parentElement.children[numberMainPath - 1].remove();
      numberPointPosition -= numberMainPath;
      if (numberPointPosition < 0) {
        numberPointPosition = 0;
      }
    } else {
      numberPointPosition--;
    }
    this.drawflow.drawflow[this.module].data[nodeId].outputs[output_class].connections[searchConnection].points.splice(numberPointPosition, 1);

    ele.remove();
    this.dispatch('removeReroute', nodeId);
    this.updateConnectionNodes(nodeUpdate);
  }

  registerNode(name, html, props = null, options = null) {
    this.noderegister[name] = { html, props, options };
  }

  getNodeFromId(id) {
    const moduleName = this.getModuleFromNodeId(id);
    return JSON.parse(JSON.stringify(this.drawflow.drawflow[moduleName].data[id]));
  }

  getNodesFromName(name) {
    const nodes = [];
    const editor = this.drawflow.drawflow;
    Object.keys(editor).map((moduleName, index) => {
      for (const node in editor[moduleName].data) {
        if (editor[moduleName].data[node].name == name) {
          nodes.push(editor[moduleName].data[node].id);
        }
      }
    });
    return nodes;
  }

  addNode(name, num_in, num_out, ele_pos_x, ele_pos_y, classoverride, data, html, typenode = false) {
    if (this.useuuid) {
      var newNodeId = this.getUuid();
    } else {
      var newNodeId = this.nodeId;
    }
    const parent = document.createElement('div');
    parent.classList.add('parent-node');

    const node = document.createElement('div');
    node.innerHTML = '';
    node.setAttribute('id', `node-${newNodeId}`);
    node.classList.add('drawflow-node');
    node.classList.add('selectable');
    if (classoverride != '') {
      node.classList.add(...classoverride.split(' '));
    }

    const inputs = document.createElement('div');
    inputs.classList.add('inputs');

    const outputs = document.createElement('div');
    outputs.classList.add('outputs');

    const json_inputs = {};
    for (var x = 0; x < num_in; x++) {
      const input = document.createElement('div');
      input.classList.add('input');
      input.classList.add(`input_${x + 1}`);
      json_inputs[`input_${x + 1}`] = { connections: [] };
      inputs.appendChild(input);
    }

    const json_outputs = {};
    for (var x = 0; x < num_out; x++) {
      const output = document.createElement('div');
      output.classList.add('output');
      output.classList.add(`output_${x + 1}`);
      json_outputs[`output_${x + 1}`] = { connections: [] };

      switch (name) {
        case 'loop_flow':
          if((x + 1) == 1) {
            const span_next = document.createElement('span');
            span_next.classList.add('output-span-info-text');
            span_next.classList.add('output-span-info-loop')
            span_next.classList.add('output-span-info-first')
            span_next.innerHTML = '<< Próxima ação';
            output.appendChild(span_next);
          }
          if((x + 1) == 2) {
            const span_next = document.createElement('span');
            span_next.classList.add('output-span-info-text');
            span_next.classList.add('output-span-info-loop')
            span_next.innerHTML = '<< Saída';
            output.appendChild(span_next);
          }
          break;
        case 'branch':
          if((x + 1) == 1) {
            const span_next = document.createElement('span');
            span_next.classList.add('output-span-info-text');
            span_next.classList.add('output-span-info-branch')
            span_next.classList.add('output-span-info-first')
            span_next.innerHTML = '<< Se';
            output.appendChild(span_next);
          }
          if((x + 1) == 2) {
            const span_next = document.createElement('span');
            span_next.classList.add('output-span-info-text');
            span_next.classList.add('output-span-info-branch')
            span_next.innerHTML = '<< Caso contrário';
            output.appendChild(span_next);
          }
          break;
        case 'consumir_api':
          if((x + 1) == 1) {
            const span_next = document.createElement('span');
            span_next.classList.add('output-span-info-text');
            span_next.classList.add('output-span-info-branch')
            span_next.classList.add('output-span-info-first')
            span_next.innerHTML = '<< Sucesso';
            output.appendChild(span_next);
          }
          if((x + 1) == 2) {
            const span_next = document.createElement('span');
            span_next.classList.add('output-span-info-text');
            span_next.classList.add('output-span-info-branch')
            span_next.innerHTML = '<< Erro';
            output.appendChild(span_next);
          }
          break;
      }

      outputs.appendChild(output);
    }

    const content = document.createElement('div');
    content.classList.add('drawflow_content_node');
    if (typenode === false) {
      content.innerHTML = html;
    } else if (typenode === true) {
      content.appendChild(this.noderegister[html].html.cloneNode(true));
    } else if (parseInt(this.render.version) === 3) {
      // Vue 3
      const wrapper = this.render.h(this.noderegister[html].html, this.noderegister[html].props, this.noderegister[html].options);
      wrapper.appContext = this.parent;
      this.render.render(wrapper, content);
    } else {
      // Vue 2
      const wrapper = new this.render({
        parent: this.parent,
        render: (h) => h(this.noderegister[html].html, { props: this.noderegister[html].props }),
        ...this.noderegister[html].options,
      }).$mount();
      //
      content.appendChild(wrapper.$el);
    }

    Object.entries(data).forEach((key, value) => {
      if (typeof key[1] === 'object') {
        insertObjectkeys(null, key[0], key[0]);
      } else {
        const elems = content.querySelectorAll(`[df-${key[0]}]`);
        for (let i = 0; i < elems.length; i++) {
          elems[i].value = key[1];
          if (elems[i].isContentEditable) {
            elems[i].innerText = key[1];
          }
        }
      }
    });

    function insertObjectkeys(object, name, completname) {
      if (object === null) {
        var object = data[name];
      } else {
        var object = object[name];
      }
      if (object !== null) {
        Object.entries(object).forEach((key, value) => {
          if (typeof key[1] === 'object') {
            insertObjectkeys(object, key[0], `${completname}-${key[0]}`);
          } else {
            const elems = content.querySelectorAll(`[df-${completname}-${key[0]}]`);
            for (let i = 0; i < elems.length; i++) {
              elems[i].value = key[1];
              if (elems[i].isContentEditable) {
                elems[i].innerText = key[1];
              }
            }
          }
        });
      }
    }
    node.appendChild(inputs);
    node.appendChild(content);
    node.appendChild(outputs);
    node.style.top = `${ele_pos_y}px`;
    node.style.left = `${ele_pos_x}px`;
    parent.appendChild(node);
    this.precanvas.appendChild(parent);
    const json = {
      id: newNodeId,
      name,
      data,
      class: classoverride,
      html,
      typenode,
      inputs: json_inputs,
      outputs: json_outputs,
      pos_x: ele_pos_x,
      pos_y: ele_pos_y,
    };
    this.drawflow.drawflow[this.module].data[newNodeId] = json;
    this.dispatch('nodeCreated', newNodeId);
    if (!this.useuuid) {
      this.nodeId++;
    }
    this.loadSelectables();
    return newNodeId;
  }

  makeErrorNode(message, nodeId) {
    let node = document.querySelector(`.error-node-tooltip-${nodeId}`);
    if(node) {
      node.remove();
    }

    let erroNodeToolTip = document.createElement('div');
    let erroArrow = document.createElement('i');
    erroNodeToolTip.innerHTML = message;
    erroNodeToolTip.classList.add('error-node-tooltip');
    erroNodeToolTip.classList.add(`error-node-tooltip-${nodeId}`);
    erroNodeToolTip.appendChild(erroArrow);
    return erroNodeToolTip;
  }
  validate() {
    let hasError = false;
    this.nodesError = [];
    document.querySelectorAll('.error-node-tooltip').forEach((node) => {
      node.remove();
    });

    for (var nodeId in this.drawflow.drawflow.Home.data) {
      let node = this.drawflow.drawflow.Home.data[nodeId];
      let nodesConnection = document.getElementsByClassName('node_in_node-'+node.id);
      let nodeElement = document.getElementById('node-'+node.id);
      nodeElement.style.setProperty( 'border', '3px solid #44df72', 'important' );
      let connectOkColor = '#44df72';
      let hasErrorCheck = false;
      switch (node.name) {
        case 'simple_message':
          //check if has message
          if( node.data.text.length == 0 ) {
            nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
            nodeElement.appendChild(this.makeErrorNode('Conteúdo da mensagem está vazio', node.id));
            hasError = true;
            hasErrorCheck = true;
            this.nodesError.push(node);
            //console.error('validate:node:simple_message', node);
          }
          break;
        case 'loop_flow':
          //check if loop has the exit action
          if( node.data.send_action_id.length == 0 ) {
            nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
            nodeElement.appendChild(this.makeErrorNode('Ação de saída é necessária', node.id));
            hasError = true;
            hasErrorCheck = true;
            this.nodesError.push(node);
          }
          if( node.data.attribute == null || node.data.attribute.length == 0 || node.data.attribute == '' || node.data.attribute == undefined ) {
            nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
            nodeElement.appendChild(this.makeErrorNode('O atributo precisa ser preenchidos', node.id));
            hasError = true;
            hasErrorCheck = true;
            this.nodesError.push(node);
          }
          break;
        case 'contact_update':
          //check if has message
          if( node.data.field.length == 0 || node.data.value.length == 0 ) {
            nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
            nodeElement.appendChild(this.makeErrorNode('O campo ou atributo precisam ser preenchidos', node.id));
            hasError = true;
            hasErrorCheck = true;
            this.nodesError.push(node);
          }
          break;
        case 'send_file':
          //check if has file
          if( node.data.url == null || node.data.url == undefined || node.data.url.length == 0) {
            nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
            nodeElement.appendChild(this.makeErrorNode('Precisa de um arquivo', node.id));
            hasError = true;
            hasErrorCheck = true;
            this.nodesError.push(node);
          }
          break;
        case 'response_expecter':
          //check if has message
          if( node.data.variable_name == null || node.data.variable_name.length == 0 || node.data.variable_name == '' ) {
            nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
            nodeElement.appendChild(this.makeErrorNode('O nome da variável precisa ser preenchidos', node.id));
            hasError = true;
            hasErrorCheck = true;
            this.nodesError.push(node);
          }
          break;
        case 'branch':
          //check if has message
          if( node.data.attribute == null || node.data.attribute.length == 0 || node.data.attribute == '' ) {
            nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
            nodeElement.appendChild(this.makeErrorNode('O nome da variável precisa ser preenchidos', node.id));
            hasError = true;
            hasErrorCheck = true;
            this.nodesError.push(node);
          }
          break;
        case 'add_variable':
            //check if has message
            if( node.data.name.length == 0 || node.data.value.length == 0 ) {
              nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
              nodeElement.appendChild(this.makeErrorNode('O nome e valor precisam ser preenchidos', node.id));
              hasError = true;
              hasErrorCheck = true;
              this.nodesError.push(node);
            }
            break;
        case 'send_file':
          //check if has message
          if( node.data.url == null || node.data.url.length == 0 || node.data.url == undefined) {
            nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
            nodeElement.appendChild(this.makeErrorNode('Um arquivo é obrigatório', node.id));
            hasError = true;
            hasErrorCheck = true;
            this.nodesError.push(node);
          }
          break;
        case 'catalog_whatsapp':
          console.log(node.data.catalog);
          //check if has message
          if( node.data.catalog == null || (node.data.catalog != null && node.data.catalog.catalog_id.length == 0) ) {
            nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
            nodeElement.appendChild(this.makeErrorNode('Você precisa escolher um catálogo', node.id));
            hasError = true;
            hasErrorCheck = true;
            this.nodesError.push(node);
          }
          break;
        case 'template_whatsapp':
          //check if has message
          if( node.data.template == null || (node.data.template != null && node.data.template.name.length == 0) ) {
            nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
            nodeElement.appendChild(this.makeErrorNode('Você precisa escolher um template', node.id));
            hasError = true;
            hasErrorCheck = true;
            this.nodesError.push(node);
          }
          break;
        case 'interactive_whatsapp':
          //check if has message
          if( node.data.template == null || (node.data.template != null && node.data.template.body.length == 0) ) {
            nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
            nodeElement.appendChild(this.makeErrorNode('Você precisa preencher os dados', node.id));
            hasError = true;
            hasErrorCheck = true;
            this.nodesError.push(node);
          }
          if(node.data.template) {
            if(node.data.template.type == 'buttons' && node.data.template.buttons.length == 0) {
              nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
              nodeElement.appendChild(this.makeErrorNode('Você precisa adicionar botões', node.id));
              hasError = true;
              hasErrorCheck = true;
              this.nodesError.push(node);
            }
          }
          break;
        case 'transfer_omni':
          //check if has message
          if( node.data.department.length == 0 || node.data.transfer_message == null ) {
            nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
            nodeElement.appendChild(this.makeErrorNode('Você precisa escolher um departamento e uma mensagem padrão', node.id));
            hasError = true;
            hasErrorCheck = true;
            this.nodesError.push(node);
          }
          break;
        case 'consumir_api':
          //check if has message
          if( node.data.end_point.length == 0 ) {
            nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
            nodeElement.appendChild(this.makeErrorNode('Você precisa informar o EndPoint', node.id));
            hasError = true;
            hasErrorCheck = true;
            this.nodesError.push(node);
          }
          break;
        case 'loop_flow':
          if (node.outputs) {
            if (node.outputs.output_1) {
              if(node.outputs.output_1.connections.length == 0){
                isAlone = true;
                this.nodesError.push(node);
                //console.error('validate:node:loop_flow:output_1', node);
              }
            }
            if (node.outputs.output_2) {
              if(node.outputs.output_2.connections.length == 0){
                isAlone = true;
                this.nodesError.push(node);
                //console.error('validate:node:loop_flow:output_2', node);
              }
            }
          }
          break;
      }

      //check if this is alone
      let isAlone = false;
      let isSame = false;
      if (node.inputs.input_1) {
        if(node.inputs.input_1.connections.length == 0)
          isAlone = true;
          this.nodesError.push(node);
          //console.error('validate:node:input_1:noconn', node);
      }
      if (node.outputs) {
        if (node.outputs.output_1) {
          if(node.outputs.output_1.connections.length == 0){
            isAlone = true;
            this.nodesError.push(node);
            //console.error('validate:node:output_1:noconn', node);
          } else {
            if(node.inputs.input_1){
              if(node.inputs.input_1.connections.length > 0) {
                if(parseInt(node.outputs.output_1.connections[0].node) == parseInt(node.inputs.input_1.connections[0].node)) {
                  isSame = true
                }
              }
            }
          }
        }
      }

      if(isAlone) {
        hasError = true;
        hasErrorCheck = true;
        nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
        nodeElement.appendChild(this.makeErrorNode('Esse node está sem conexão'));
      }

      if(isSame) {
        hasError = true;
        hasErrorCheck = true;
        nodeElement.style.setProperty( 'border', '3px solid #721c24', 'important' );
        nodeElement.appendChild(this.makeErrorNode('Esse node está repetido, desconecte e reconecte o node'));
      }

      if(hasErrorCheck) {
        connectOkColor = '#721c24';
      }

      if (nodesConnection) {
        nodesConnection.forEach((nodeConnection) => {
          let line = nodeConnection.children[0];
          let lineStyle = false;
          setInterval(() => {
            if (lineStyle) {
              line.style = 'stroke: '+connectOkColor+' !important;'
            } else {
              line.style = 'stroke: #ccc !important;'
            }
            lineStyle = !lineStyle;
          }, 500);
        });
      }

    }

    if(this.nodesError.length > 0) {
      this.backToNode(this.nodesError[this.nodesError.length - 1]);
    }

    return hasError;
  }

  addNodeImport(dataNode, precanvas, isCopy = false) {
    const parent = document.createElement('div');
    parent.classList.add('parent-node');

    const node = document.createElement('div');
    node.innerHTML = '';
    node.setAttribute('id', `node-${dataNode.id}`);
    node.classList.add('drawflow-node');
    node.classList.add('selectable');
    if (dataNode.class != '') {
      node.classList.add(...dataNode.class.split(' '));
    }

    const inputs = document.createElement('div');
    inputs.classList.add('inputs');

    const outputs = document.createElement('div');
    outputs.classList.add('outputs');

    Object.keys(dataNode.inputs).map((input_item, index) => {
      const input = document.createElement('div');
      input.classList.add('input');
      input.classList.add(input_item);
      inputs.appendChild(input);
      Object.keys(dataNode.inputs[input_item].connections).map((output_item, index) => {
        const connection = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.classList.add('main-path');
        path.setAttributeNS(null, 'd', '');
        // path.innerHTML = 'a';
        connection.classList.add('connection');
        connection.classList.add(`node_in_node-${dataNode.id}`);
        connection.classList.add(`node_out_node-${dataNode.inputs[input_item].connections[output_item].node}`);
        connection.classList.add(dataNode.inputs[input_item].connections[output_item].input);
        connection.classList.add(input_item);

        connection.appendChild(path);
        precanvas.appendChild(connection);
      });
    });

    for (let x = 0; x < Object.keys(dataNode.outputs).length; x++) {
      const output = document.createElement('div');
      output.classList.add('output');
      output.classList.add(`output_${x + 1}`);
      outputs.appendChild(output);
    }

    const content = document.createElement('div');
    content.classList.add('drawflow_content_node');

    if (dataNode.typenode === false) {
      content.innerHTML = dataNode.html;
    } else if (dataNode.typenode === true) {
      content.appendChild(this.noderegister[dataNode.html].html.cloneNode(true));
    } else if (parseInt(this.render.version) === 3) {
      // Vue 3
      const wrapper = this.render.h(this.noderegister[dataNode.html].html, this.noderegister[dataNode.html].props, this.noderegister[dataNode.html].options);
      wrapper.appContext = this.parent;
      this.render.render(wrapper, content);
    } else {
      // Vue 2
      const wrapper = new this.render({
        parent: this.parent,
        render: (h) => h(this.noderegister[dataNode.html].html, { props: this.noderegister[dataNode.html].props }),
        ...this.noderegister[dataNode.html].options,
      }).$mount();
      content.appendChild(wrapper.$el);
    }

    Object.entries(dataNode.data).forEach((key, value) => {
      if (typeof key[1] === 'object') {
        insertObjectkeys(null, key[0], key[0]);
      } else {
        const elems = content.querySelectorAll(`[df-${key[0]}]`);
        for (let i = 0; i < elems.length; i++) {
          elems[i].value = key[1];
          if (elems[i].isContentEditable) {
            elems[i].innerText = key[1];
          }
        }
      }
    });

    function insertObjectkeys(object, name, completname) {
      if (object === null) {
        var object = dataNode.data[name];
      } else {
        var object = object[name];
      }
      if (object !== null) {
        Object.entries(object).forEach((key, value) => {
          if (typeof key[1] === 'object') {
            insertObjectkeys(object, key[0], `${completname}-${key[0]}`);
          } else {
            const elems = content.querySelectorAll(`[df-${completname}-${key[0]}]`);
            for (let i = 0; i < elems.length; i++) {
              elems[i].value = key[1];
              if (elems[i].isContentEditable) {
                elems[i].innerText = key[1];
              }
            }
          }
        });
      }
    }
    node.appendChild(inputs);
    node.appendChild(content);
    node.appendChild(outputs);
    let newPos = 0;
    if(isCopy)
      newPos = 100;

    node.style.top = `${dataNode.pos_y - newPos}px`;
    node.style.left = `${dataNode.pos_x + newPos}px`;
    parent.appendChild(node);
    this.precanvas.appendChild(parent);
    this.loadSelectables();
    return node;
  }

  addRerouteImport(dataNode) {
    const reroute_width = this.reroute_width;
    const reroute_fix_curvature = this.reroute_fix_curvature;
    const container = this.container;
    Object.keys(dataNode.outputs).map((output_item, index) => {
      Object.keys(dataNode.outputs[output_item].connections).map((input_item, index) => {
        const points = dataNode.outputs[output_item].connections[input_item].points;
        if (points !== undefined) {
          points.forEach((item, i) => {
            const input_id = dataNode.outputs[output_item].connections[input_item].node;
            const input_class = dataNode.outputs[output_item].connections[input_item].output;
            const ele = container.querySelector(`.connection.node_in_node-${input_id}.node_out_node-${dataNode.id}.${output_item}.${input_class}`);

            if (reroute_fix_curvature) {
              if (i === 0) {
                for (let z = 0; z < points.length; z++) {
                  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                  path.classList.add('main-path');
                  path.setAttributeNS(null, 'd', '');
                  ele.appendChild(path);
                }
              }
            }

            const point = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
            point.classList.add('point');
            const pos_x = item.pos_x;
            const pos_y = item.pos_y;

            point.setAttributeNS(null, 'cx', pos_x);
            point.setAttributeNS(null, 'cy', pos_y);
            point.setAttributeNS(null, 'r', reroute_width);

            ele.appendChild(point);
          });
        }
      });
    });
  }

  updateNodeValue(event) {
    const attr = event.target.attributes;
    for (let i = 0; i < attr.length; i++) {
      if (attr[i].nodeName.startsWith('df-')) {
        const keys = attr[i].nodeName.slice(3).split('-');
        let target = this.drawflow.drawflow[this.module].data[event.target.closest('.drawflow_content_node').parentElement.id.slice(5)].data;
        for (let index = 0; index < keys.length - 1; index += 1) {
          if (target[keys[index]] == null) {
            target[keys[index]] = {};
          }
          target = target[keys[index]];
        }
        target[keys[keys.length - 1]] = event.target.value;
        if (event.target.isContentEditable) {
          target[keys[keys.length - 1]] = event.target.innerText;
        }
        this.dispatch('nodeDataChanged', event.target.closest('.drawflow_content_node').parentElement.id.slice(5));
      }
    }
  }

  updateNodeDataFromId(id, data) {
    const moduleName = this.getModuleFromNodeId(id);
    this.drawflow.drawflow[moduleName].data[id].data = data;
    if (this.module === moduleName) {
      const content = this.container.querySelector(`#node-${id}`);

      Object.entries(data).forEach((key, value) => {
        if (typeof key[1] === 'object') {
          insertObjectkeys(null, key[0], key[0]);
        } else {
          const elems = content.querySelectorAll(`[df-${key[0]}]`);
          for (let i = 0; i < elems.length; i++) {
            elems[i].value = key[1];
            if (elems[i].isContentEditable) {
              elems[i].innerText = key[1];
            }
          }
        }
      });

      function insertObjectkeys(object, name, completname) {
        if (object === null) {
          var object = data[name];
        } else {
          var object = object[name];
        }
        if (object !== null) {
          Object.entries(object).forEach((key, value) => {
            if (typeof key[1] === 'object') {
              insertObjectkeys(object, key[0], `${completname}-${key[0]}`);
            } else {
              const elems = content.querySelectorAll(`[df-${completname}-${key[0]}]`);
              for (let i = 0; i < elems.length; i++) {
                elems[i].value = key[1];
                if (elems[i].isContentEditable) {
                  elems[i].innerText = key[1];
                }
              }
            }
          });
        }
      }
    }
  }

  addNodeInput(id) {
    const moduleName = this.getModuleFromNodeId(id);
    const infoNode = this.getNodeFromId(id);
    const numInputs = Object.keys(infoNode.inputs).length;
    if (this.module === moduleName) {
      // Draw input
      const input = document.createElement('div');
      input.classList.add('input');
      input.classList.add(`input_${numInputs + 1}`);
      const parent = this.container.querySelector(`#node-${id} .inputs`);
      parent.appendChild(input);
      this.updateConnectionNodes(`node-${id}`);
    }
    this.drawflow.drawflow[moduleName].data[id].inputs[`input_${numInputs + 1}`] = { connections: [] };

    this.loadSelectables();
  }

  addNodeOutput(id) {
    const moduleName = this.getModuleFromNodeId(id);
    const infoNode = this.getNodeFromId(id);
    const numOutputs = Object.keys(infoNode.outputs).length;
    if (this.module === moduleName) {
      // Draw output
      const output = document.createElement('div');
      output.classList.add('output');
      output.classList.add(`output_${numOutputs + 1}`);
      const parent = this.container.querySelector(`#node-${id} .outputs`);
      parent.appendChild(output);
      this.updateConnectionNodes(`node-${id}`);
    }
    this.drawflow.drawflow[moduleName].data[id].outputs[`output_${numOutputs + 1}`] = { connections: [] };
    this.loadSelectables();
  }

  removeNodeInput(id, input_class) {
    const moduleName = this.getModuleFromNodeId(id);
    const infoNode = this.getNodeFromId(id);
    if (this.module === moduleName) {
      this.container.querySelector(`#node-${id} .inputs .input.${input_class}`).remove();
    }
    const removeInputs = [];
    Object.keys(infoNode.inputs[input_class].connections).map((key, index) => {
      const id_output = infoNode.inputs[input_class].connections[index].node;
      const output_class = infoNode.inputs[input_class].connections[index].input;
      removeInputs.push({
        id_output, id, output_class, input_class,
      });
    });
    // Remove connections
    removeInputs.forEach((item, i) => {
      this.removeSingleConnection(item.id_output, item.id, item.output_class, item.input_class);
    });

    delete this.drawflow.drawflow[moduleName].data[id].inputs[input_class];

    // Update connection
    const connections = [];
    const connectionsInputs = this.drawflow.drawflow[moduleName].data[id].inputs;
    Object.keys(connectionsInputs).map((key, index) => {
      connections.push(connectionsInputs[key]);
    });
    this.drawflow.drawflow[moduleName].data[id].inputs = {};
    const input_class_id = input_class.slice(6);
    let nodeUpdates = [];
    connections.forEach((item, i) => {
      item.connections.forEach((itemx, f) => {
        nodeUpdates.push(itemx);
      });
      this.drawflow.drawflow[moduleName].data[id].inputs[`input_${i + 1}`] = item;
    });
    nodeUpdates = new Set(nodeUpdates.map((e) => JSON.stringify(e)));
    nodeUpdates = Array.from(nodeUpdates).map((e) => JSON.parse(e));

    if (this.module === moduleName) {
      const eles = this.container.querySelectorAll(`#node-${id} .inputs .input`);
      eles.forEach((item, i) => {
        const id_class = item.classList[1].slice(6);
        if (parseInt(input_class_id) < parseInt(id_class)) {
          item.classList.remove(`input_${id_class}`);
          item.classList.add(`input_${id_class - 1}`);
        }
      });
    }

    nodeUpdates.forEach((itemx, i) => {
      this.drawflow.drawflow[moduleName].data[itemx.node].outputs[itemx.input].connections.forEach((itemz, g) => {
        if (itemz.node == id) {
          const output_id = itemz.output.slice(6);
          if (parseInt(input_class_id) < parseInt(output_id)) {
            if (this.module === moduleName) {
              const ele = this.container.querySelector(`.connection.node_in_node-${id}.node_out_node-${itemx.node}.${itemx.input}.input_${output_id}`);
              ele.classList.remove(`input_${output_id}`);
              ele.classList.add(`input_${output_id - 1}`);
            }
            if (itemz.points) {
              this.drawflow.drawflow[moduleName].data[itemx.node].outputs[itemx.input].connections[g] = { node: itemz.node, output: `input_${output_id - 1}`, points: itemz.points };
            } else {
              this.drawflow.drawflow[moduleName].data[itemx.node].outputs[itemx.input].connections[g] = { node: itemz.node, output: `input_${output_id - 1}` };
            }
          }
        }
      });
    });
    this.updateConnectionNodes(`node-${id}`);
  }

  removeNodeOutput(id, output_class) {
    const moduleName = this.getModuleFromNodeId(id);
    const infoNode = this.getNodeFromId(id);
    if (this.module === moduleName) {
      this.container.querySelector(`#node-${id} .outputs .output.${output_class}`).remove();
    }
    const removeOutputs = [];
    Object.keys(infoNode.outputs[output_class].connections).map((key, index) => {
      const id_input = infoNode.outputs[output_class].connections[index].node;
      const input_class = infoNode.outputs[output_class].connections[index].output;
      removeOutputs.push({
        id, id_input, output_class, input_class,
      });
    });
    // Remove connections
    removeOutputs.forEach((item, i) => {
      this.removeSingleConnection(item.id, item.id_input, item.output_class, item.input_class);
    });

    delete this.drawflow.drawflow[moduleName].data[id].outputs[output_class];

    // Update connection
    const connections = [];
    const connectionsOuputs = this.drawflow.drawflow[moduleName].data[id].outputs;
    Object.keys(connectionsOuputs).map((key, index) => {
      connections.push(connectionsOuputs[key]);
    });
    this.drawflow.drawflow[moduleName].data[id].outputs = {};
    const output_class_id = output_class.slice(7);
    let nodeUpdates = [];
    connections.forEach((item, i) => {
      item.connections.forEach((itemx, f) => {
        nodeUpdates.push({ node: itemx.node, output: itemx.output });
      });
      this.drawflow.drawflow[moduleName].data[id].outputs[`output_${i + 1}`] = item;
    });
    nodeUpdates = new Set(nodeUpdates.map((e) => JSON.stringify(e)));
    nodeUpdates = Array.from(nodeUpdates).map((e) => JSON.parse(e));

    if (this.module === moduleName) {
      const eles = this.container.querySelectorAll(`#node-${id} .outputs .output`);
      eles.forEach((item, i) => {
        const id_class = item.classList[1].slice(7);
        if (parseInt(output_class_id) < parseInt(id_class)) {
          item.classList.remove(`output_${id_class}`);
          item.classList.add(`output_${id_class - 1}`);
        }
      });
    }

    nodeUpdates.forEach((itemx, i) => {
      this.drawflow.drawflow[moduleName].data[itemx.node].inputs[itemx.output].connections.forEach((itemz, g) => {
        if (itemz.node == id) {
          const input_id = itemz.input.slice(7);
          if (parseInt(output_class_id) < parseInt(input_id)) {
            if (this.module === moduleName) {
              const ele = this.container.querySelector(`.connection.node_in_node-${itemx.node}.node_out_node-${id}.output_${input_id}.${itemx.output}`);
              ele.classList.remove(`output_${input_id}`);
              ele.classList.remove(itemx.output);
              ele.classList.add(`output_${input_id - 1}`);
              ele.classList.add(itemx.output);
            }
            if (itemz.points) {
              this.drawflow.drawflow[moduleName].data[itemx.node].inputs[itemx.output].connections[g] = { node: itemz.node, input: `output_${input_id - 1}`, points: itemz.points };
            } else {
              this.drawflow.drawflow[moduleName].data[itemx.node].inputs[itemx.output].connections[g] = { node: itemz.node, input: `output_${input_id - 1}` };
            }
          }
        }
      });
    });

    this.updateConnectionNodes(`node-${id}`);
  }

  removeNodeId(id) {
    this.removeConnectionNodeId(id);
    const moduleName = this.getModuleFromNodeId(id.slice(5));
    if (this.module === moduleName) {
      this.container.querySelector(`#${id}`).remove();
    }
    delete this.drawflow.drawflow[moduleName].data[id.slice(5)];
    this.dispatch('nodeRemoved', id.slice(5));
  }

  removeConnection() {
    if (this.lastConnection_selectedOver != null) {
      const listclass = this.lastConnection_selectedOver.parentElement.classList;
      this.lastConnection_selectedOver.parentElement.remove();
      // console.log(listclass);
      const index_out = this.drawflow.drawflow[this.module].data[listclass[2].slice(14)].outputs[listclass[3]].connections.findIndex((item, i) => item.node === listclass[1].slice(13) && item.output === listclass[4]);
      this.drawflow.drawflow[this.module].data[listclass[2].slice(14)].outputs[listclass[3]].connections.splice(index_out, 1);

      const index_in = this.drawflow.drawflow[this.module].data[listclass[1].slice(13)].inputs[listclass[4]].connections.findIndex((item, i) => item.node === listclass[2].slice(14) && item.input === listclass[3]);
      this.drawflow.drawflow[this.module].data[listclass[1].slice(13)].inputs[listclass[4]].connections.splice(index_in, 1);
      this.dispatch('connectionRemoved', {
        output_id: listclass[2].slice(14), input_id: listclass[1].slice(13), output_class: listclass[3], input_class: listclass[4],
      });
      this.lastConnection_selectedOver = null;
    }
  }

  removeSingleConnection(id_output, id_input, output_class, input_class) {
    const nodeOneModule = this.getModuleFromNodeId(id_output);
    const nodeTwoModule = this.getModuleFromNodeId(id_input);
    if (nodeOneModule === nodeTwoModule) {
      // Check nodes in same module.

      // Check connection exist
      const exists = this.drawflow.drawflow[nodeOneModule].data[id_output].outputs[output_class].connections.findIndex((item, i) => item.node == id_input && item.output === input_class);
      if (exists > -1) {
        if (this.module === nodeOneModule) {
          // In same module with view.
          this.container.querySelector(`.connection.node_in_node-${id_input}.node_out_node-${id_output}.${output_class}.${input_class}`).remove();
        }

        const index_out = this.drawflow.drawflow[nodeOneModule].data[id_output].outputs[output_class].connections.findIndex((item, i) => item.node == id_input && item.output === input_class);
        this.drawflow.drawflow[nodeOneModule].data[id_output].outputs[output_class].connections.splice(index_out, 1);

        const index_in = this.drawflow.drawflow[nodeOneModule].data[id_input].inputs[input_class].connections.findIndex((item, i) => item.node == id_output && item.input === output_class);
        this.drawflow.drawflow[nodeOneModule].data[id_input].inputs[input_class].connections.splice(index_in, 1);

        this.dispatch('connectionRemoved', {
          output_id: id_output, input_id: id_input, output_class, input_class,
        });
        return true;
      }
      return false;
    }
    return false;
  }

  removeConnectionNodeId(id) {
    const idSearchIn = `node_in_${id}`;
    const idSearchOut = `node_out_${id}`;

    const elemsOut = this.container.querySelectorAll(`.${idSearchOut}`);
    for (var i = elemsOut.length - 1; i >= 0; i--) {
      var listclass = elemsOut[i].classList;

      var index_in = this.drawflow.drawflow[this.module].data[listclass[1].slice(13)].inputs[listclass[4]].connections.findIndex((item, i) => item.node === listclass[2].slice(14) && item.input === listclass[3]);
      this.drawflow.drawflow[this.module].data[listclass[1].slice(13)].inputs[listclass[4]].connections.splice(index_in, 1);

      var index_out = this.drawflow.drawflow[this.module].data[listclass[2].slice(14)].outputs[listclass[3]].connections.findIndex((item, i) => item.node === listclass[1].slice(13) && item.output === listclass[4]);
      this.drawflow.drawflow[this.module].data[listclass[2].slice(14)].outputs[listclass[3]].connections.splice(index_out, 1);

      elemsOut[i].remove();

      this.dispatch('connectionRemoved', {
        output_id: listclass[2].slice(14), input_id: listclass[1].slice(13), output_class: listclass[3], input_class: listclass[4],
      });
    }

    const elemsIn = this.container.querySelectorAll(`.${idSearchIn}`);
    for (var i = elemsIn.length - 1; i >= 0; i--) {
      var listclass = elemsIn[i].classList;

      var index_out = this.drawflow.drawflow[this.module].data[listclass[2].slice(14)].outputs[listclass[3]].connections.findIndex((item, i) => item.node === listclass[1].slice(13) && item.output === listclass[4]);
      this.drawflow.drawflow[this.module].data[listclass[2].slice(14)].outputs[listclass[3]].connections.splice(index_out, 1);

      var index_in = this.drawflow.drawflow[this.module].data[listclass[1].slice(13)].inputs[listclass[4]].connections.findIndex((item, i) => item.node === listclass[2].slice(14) && item.input === listclass[3]);
      this.drawflow.drawflow[this.module].data[listclass[1].slice(13)].inputs[listclass[4]].connections.splice(index_in, 1);

      elemsIn[i].remove();

      this.dispatch('connectionRemoved', {
        output_id: listclass[2].slice(14), input_id: listclass[1].slice(13), output_class: listclass[3], input_class: listclass[4],
      });
    }
  }

  getModuleFromNodeId(id) {
    let nameModule;
    const editor = this.drawflow.drawflow;
    Object.keys(editor).map((moduleName, index) => {
      Object.keys(editor[moduleName].data).map((node, index2) => {
        if (node == id) {
          nameModule = moduleName;
        }
      });
    });
    return nameModule;
  }

  addModule(name) {
    this.drawflow.drawflow[name] = { data: {} };
    this.dispatch('moduleCreated', name);
  }

  changeModule(name) {
    this.dispatch('moduleChanged', name);
    this.module = name;
    this.precanvas.innerHTML = '';
    this.canvas_x = 0;
    this.canvas_y = 0;
    this.pos_x = 0;
    this.pos_y = 0;
    this.mouse_x = 0;
    this.mouse_y = 0;
    this.zoom = 1;
    this.zoom_last_value = 1;
    this.precanvas.style.transform = '';
    this.import(this.drawflow, false);
  }

  removeModule(name) {
    if (this.module === name) {
      this.changeModule('Home');
    }
    delete this.drawflow.drawflow[name];
    this.dispatch('moduleRemoved', name);
  }

  clearModuleSelected() {
    this.precanvas.innerHTML = '';
    this.drawflow.drawflow[this.module] = { data: {} };
  }

  clear() {
    this.precanvas.innerHTML = '';
    this.drawflow = { drawflow: { Home: { data: {} } } };
  }

  export() {
    const dataExport = JSON.parse(JSON.stringify(this.drawflow));
    this.dispatch('export', dataExport);
    return dataExport;
  }

  import(data, notifi = true) {
    this.clear();
    this.drawflow = JSON.parse(JSON.stringify(data));
    this.load();
    if (notifi) {
      this.dispatch('import', 'import');
    }
  }

  /* Events */
  on(event, callback) {
    // Check if the callback is not a function
    if (typeof callback !== 'function') {
      console.error(`The listener callback must be a function, the given type is ${typeof callback}`);
      return false;
    }
    // Check if the event is not a string
    if (typeof event !== 'string') {
      console.error(`The event name must be a string, the given type is ${typeof event}`);
      return false;
    }
    // Check if this event not exists
    if (this.events[event] === undefined) {
      this.events[event] = {
        listeners: [],
      };
    }
    this.events[event].listeners.push(callback);
  }

  removeListener(event, callback) {
    // Check if this event not exists

    if (!this.events[event]) return false;

    const listeners = this.events[event].listeners;
    const listenerIndex = listeners.indexOf(callback);
    const hasListener = listenerIndex > -1;
    if (hasListener) listeners.splice(listenerIndex, 1);
  }

  dispatch(event, details) {
    // Check if this event not exists
    if (this.events[event] === undefined) {
      // console.error(`This event: ${event} does not exist`);
      return false;
    }
    this.events[event].listeners.forEach((listener) => {
      listener(details);
    });
  }

  getUuid() {
    // http://www.ietf.org/rfc/rfc4122.txt
    const s = [];
    const hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-';

    const uuid = s.join('');
    return uuid;
  }
}
