<!-- eslint-disable -->
<template>
  <div class="content relative editor">
    <b-modal id="flow-create-template" hide-footer class="modal-lg" size="lg">
      <template #modal-title>
        Salvar template
      </template>
      <div style="padding: 20px;">
        <div class="row text-center" v-if="templateLoading">
          <img src="assets/img/loading.gif" style="margin: 0 auto;" />
        </div>
        <div class="row" style="margin-top: 20px;">
          <div class="col-sm-12">
            <h3 style="margin-bottom: 15px;">Canais</h3>
            <div class="row">
              <div class="col-sm-6">
                <div class="card p20">
                  <div class="row" style="padding: 10px;">
                    <div class="col-sm-2">
                      <img src="assets/img/icons/flow/instagram.svg" class="c-pointer" style="margin-top: 10px;" />
                    </div>
                    <div class="col-sm-6">
                      <h5 style="padding-top: 15px;">Instagram</h5>
                    </div>
                    <div class="col-sm-4" style="padding: 0px;">
                      <div class="control-group opt-2 toggle-check" style="margin-top: 10px;">
                        <label class="control control-checkbox">
                          <input type="checkbox" name="notifications-opt" v-model="isInstagramChannel"
                            @change="changeChannelTemplate('instagram')" />
                          <div class="control_indicator" style="margin-bottom: 0;"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card p20">
                  <div class="row" style="padding: 10px;">
                    <div class="col-sm-2">
                      <img src="assets/img/icons/flow/whats_meta.png" class="c-pointer" style="margin-top: 10px;" />
                    </div>
                    <div class="col-sm-6">
                      <h5 style="padding-top: 15px;">WhatsApp</h5>
                    </div>
                    <div class="col-sm-4" style="padding: 0px;">
                      <div class="control-group opt-2 toggle-check" style="margin-top: 10px;">
                        <label class="control control-checkbox">
                          <input type="checkbox" name="notifications-opt" v-model="isWhatsAppChannel"
                            @change="changeChannelTemplate('whatsapp')" />
                          <div class="control_indicator" style="margin-bottom: 0;"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card p20">
                  <div class="row" style="padding: 10px;">
                    <div class="col-sm-2">
                      <img src="assets/img/icons/flow/telegram.svg" class="c-pointer" style="margin-top: 10px;" />
                    </div>
                    <div class="col-sm-6">
                      <h5 style="padding-top: 15px;">Telegram</h5>
                    </div>
                    <div class="col-sm-4" style="padding: 0px;">
                      <div class="control-group opt-2 toggle-check" style="margin-top: 10px;">
                        <label class="control control-checkbox">
                          <input type="checkbox" name="notifications-opt" v-model="isTelegramChannel"
                            @change="changeChannelTemplate('telegram')" />
                          <div class="control_indicator" style="margin-bottom: 0;"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card p20">
                  <div class="row" style="padding: 10px;">
                    <div class="col-sm-2">
                      <img src="assets/img/icons/flow/messenger.svg" class="c-pointer" style="margin-top: 10px;" />
                    </div>
                    <div class="col-sm-6">
                      <h5 style="padding-top: 15px;">Messenger</h5>
                    </div>
                    <div class="col-sm-4" style="padding: 0px;">
                      <div class="control-group opt-2 toggle-check" style="margin-top: 10px;">
                        <label class="control control-checkbox">
                          <input type="checkbox" name="notifications-opt" v-model="isMessengerChannel"
                            @change="changeChannelTemplate('messenger')" />
                          <div class="control_indicator" style="margin-bottom: 0;"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card p20">
                  <div class="row" style="padding: 10px;">
                    <div class="col-sm-2">
                      <img src="assets/img/icons/flow/rcs.svg" class="c-pointer" style="margin-top: 10px;" />
                    </div>
                    <div class="col-sm-6">
                      <h5 style="padding-top: 15px;">RCS</h5>
                    </div>
                    <div class="col-sm-4" style="padding: 0px;">
                      <div class="control-group opt-2 toggle-check" style="margin-top: 10px;">
                        <label class="control control-checkbox">
                          <input type="checkbox" name="notifications-opt" v-model="isRcsChannel"
                            @change="changeChannelTemplate('rcs')" />
                          <div class="control_indicator" style="margin-bottom: 0;"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card p20">
                  <div class="row" style="padding: 10px;">
                    <div class="col-sm-2">
                      <img src="assets/img/icons/flow/chat.svg" class="c-pointer" style="margin-top: 10px;" />
                    </div>
                    <div class="col-sm-6">
                      <h5 style="padding-top: 15px;">Widget</h5>
                    </div>
                    <div class="col-sm-4" style="padding: 0px;">
                      <div class="control-group opt-2 toggle-check" style="margin-top: 10px;">
                        <label class="control control-checkbox">
                          <input type="checkbox" name="notifications-opt" v-model="isWidgetChannel"
                            @change="changeChannelTemplate('widget')" />
                          <div class="control_indicator" style="margin-bottom: 0;"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div>
                  <p>Tags</p>
                  <div class="tags-container">
                    <vue-tags-input v-model="flow.tag_template" :class="'vue-tag-borderless col'" :tags="tags"
                      :max-tags="6" @tags-changed="(newTags) => (tags = newTags)"
                      :placeholder="'Ex: WhatsApp, SMS, RCS'" :separators="[',']" :add-on-key="[13, ',']" />
                  </div>
                </div>
              </div>
              <div class="col-sm-12"><br></div>
              <div class="col-sm-12">

                <p>Link do vídeo</p>
                <div>
                  <input class="form-control" v-model="flow.video_link" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <hr>
            <button class="btn btn-primary" @click="saveTemplate()"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': !fetched }" :disabled="!fetched">Salvar
              Template
            </button>
          </div>
        </div>
        <button @click="create('blank')" class="hide">{{ $t('flows.blank') }}</button>
      </div>
    </b-modal>
    <div class="row bars-flow-float" style="z-index: 1;">
      <div class="col-sm-6">
        <div class="float-bar header-flow-bar">
          <div class="d-flex justify-content-between">
            <div class="text-right d-flex" style="padding-top: 3px; padding-right: 0px;">
              <button class="btn btn-default" @click="$router.go(-1)">
                <i class="fas fa-angle-left"></i> Voltar
              </button>
              <h4 style="padding: 7px 10px;" v-if="!showEditName" v-on:click="showEditName = true"
                class="c-pointer float-bar-title">
                {{ flow.name }}
              </h4>
              <div style="padding: 5px; padding-top: 2px;width: 250px;" v-if="showEditName">
                <input class="form-control" placeholder="Título" v-model="flow.name">
              </div>
              <div class="d-flex">
                <div class="float-badge float-badge-danger" v-if="!this.flow.active">
                  <div class="float-badge-danger-inner"></div>
                </div>
                <div class="float-badge float-badge-success" v-else>
                  <div class="float-badge-success-inner"></div>
                </div>
                <span class="badge top-11" v-if="this.flow.active" style="margin-top: 8px !important;">
                  Publicado
                </span>
                <span class="badge top-11" v-else style="margin-top: 8px !important;">
                  Inativo
                </span>
                <span class="badge top-11" v-if="this.flow.draft" style="margin-top: 8px !important;">
                  Rascunho
                </span>
              </div>
            </div>
            <div class="d-flex" style=" padding-top: 8px;">
              <button v-if="this.flow.template != true" v-tooltip.bottom="'Salvar'"
                class="btn btn-default btn-sm btn-save-flow" @click="saveFlow()" :disabled="!fetched">
                <span class="material-symbols-outlined" style="font-size: 20px;">save</span>
              </button>
              <input type="file" accept=".yflow" hidden ref="uploadedFile" @change="uploadFile" id="inport-flow-json"
                name="inport-flow-json" />
              <button class="btn btn-default btn-sm" @click="validate()" style="margin-left: 5px; border: none;"
                v-tooltip.bottom="'Validar'"><span class="material-symbols-outlined"
                  style="font-size: 20px;">check</span>
              </button>
              <div class="to-right flow-float-config">
                <dropdown>
                  <template v-slot:text>
                    <span class="material-symbols-outlined title">
                      settings
                    </span>
                  </template>
                  <template #items>
                    <a class="dropdown-item c-pointer" @click="saveFlow()">
                      <span class="material-symbols-outlined to-left">save</span>
                      <span class="to-left left-15"> Salvar Rascunho</span>
                      <div style="clear: both;"></div>
                    </a>
                    <a class="dropdown-item c-pointer" v-if="libCheck" v-b-modal.flow-create-template>
                      <span class="material-symbols-outlined to-left">draw</span>
                      <span class="to-left left-15"> Salvar Template</span>
                      <div style="clear: both;"></div>
                    </a>
                    <a for="inport-flow-json" @click="importFlow()" class="dropdown-item c-pointer">
                      <span class="material-symbols-outlined to-left">upload</span> <span class="to-left top2 left-15">
                        Importar</span>
                      <div style="clear: both;"></div>
                    </a>
                    <a @click="exportFlow()" class="dropdown-item c-pointer">
                      <span class="material-symbols-outlined to-left">download</span> <span
                        class="to-left top2 left-15">
                        Exportar</span>
                      <div style="clear: both;"></div>
                    </a>
                    <a @click="closeHistory = false" class="dropdown-item c-pointer">
                      <span class="material-symbols-outlined to-left">history</span> <span class="to-left top2 left-15">
                        Histórico</span>
                      <div style="clear: both;"></div>
                    </a>
                  </template>
                </dropdown>
              </div>
            </div>
          </div>
          <div class="row" v-if="false">
            <div class="col-sm-2 text-right" style="padding-top: 3px; padding-right: 0px;">
              <button class="btn btn-default" @click="$router.go(-1)">
                <i class="fas fa-angle-left"></i> Voltar
              </button>
            </div>
            <div class="col-sm-7" style="padding-left: 0px;">
              <h4 style="padding: 10px;" v-if="!showEditName" v-on:dblclick="showEditName = true"
                class="to-left c-pointer float-bar-title">
                {{ flow.name }}
              </h4>
              <div class="float-badge float-badge-danger to-left" v-if="!this.flow.active">
                <div class="float-badge-danger-inner"></div>
              </div>
              <div class="float-badge float-badge-success to-left" v-if="this.flow.active">
                <div class="float-badge-success-inner"></div>
              </div>
              <span class="badge top-11" v-if="this.flow.active">
                Publicado
              </span>
              <span class="badge top-11" v-if="!this.flow.active">
                Inativo
              </span>
              <span class="badge top-11" v-if="this.flow.draft">
                Rascunho
              </span>
              <div style="padding: 5px; width: 30%;" v-if="showEditName">
                <input class="form-control" placeholder="Título" v-model="flow.name">
              </div>
            </div>
            <div class="col-sm-3 text-right" style=" padding-top: 8px;">
              <button v-tooltip.bottom="'Salvar'" class="btn btn-default btn-sm btn-save-flow" @click="saveFlow()"
                :disabled="!fetched">
                <span class="material-symbols-outlined" style="font-size: 20px;">save</span>
              </button>
              <input type="file" accept=".yflow" hidden ref="uploadedFile" @change="uploadFile" id="inport-flow-json"
                name="inport-flow-json" />
              <button class="btn btn-default btn-sm" @click="validate()" style="margin-left: 5px; border: none;"
                v-tooltip.bottom="'Validar'"><span class="material-symbols-outlined"
                  style="font-size: 20px;">check</span>
              </button>
              <div class="to-right flow-float-config">
                <dropdown>
                  <template v-slot:text>
                    <span class="material-symbols-outlined title">
                      settings
                    </span>
                  </template>
                  <template #items>
                    <a class="dropdown-item c-pointer" @click="saveFlow()">
                      <span class="material-symbols-outlined to-left">save</span>
                      <span class="to-left left-15"> Salvar Rascunho</span>
                      <div style="clear: both;"></div>
                    </a>
                    <a class="dropdown-item c-pointer" v-if="libCheck" v-b-modal.flow-create-template>
                      <span class="material-symbols-outlined to-left">draw</span>
                      <span class="to-left left-15"> Salvar Template</span>
                      <div style="clear: both;"></div>
                    </a>
                    <a for="inport-flow-json" @click="importFlow()" class="dropdown-item c-pointer">
                      <span class="material-symbols-outlined to-left">upload</span> <span class="to-left top2 left-15">
                        Importar</span>
                      <div style="clear: both;"></div>
                    </a>
                    <a @click="exportFlow()" class="dropdown-item c-pointer">
                      <span class="material-symbols-outlined to-left">download</span> <span
                        class="to-left top2 left-15">
                        Exportar</span>
                      <div style="clear: both;"></div>
                    </a>
                  </template>
                </dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-bar header-flow-bar header-flow-bar-right tool-bar-right" draggable="true">
          <div class="row m0">
            <div class="col-sm-1 col-tool-bar text-center drag-drawflow" style="padding-top: 10px; padding-right: 0px;">
              <span class="material-symbols-outlined" style="color: #697077;" draggable="true" @dragstart="drag"
                data-node="simple_message" v-tooltip.top="`Enviar mensagem`">
                send
              </span>
            </div>
            <div class="col-sm-1 col-tool-bar text-center drag-drawflow" style="padding-top: 10px; padding-right: 0px;">
              <span class="material-symbols-outlined" style="color: #697077;" draggable="true" @dragstart="drag"
                data-node="add_variable" v-tooltip.top="`Variável`">
                database
              </span>
            </div>
            <div class="col-sm-1 col-tool-bar text-center drag-drawflow" style="padding-top: 10px; padding-right: 0px;">
              <span class="material-symbols-outlined" v-tooltip.top="`Solicitar dados`" draggable="true"
                @dragstart="drag" data-node="response_expecter" style="color: #697077;">data_exploration</span>
            </div>
            <div class="col-sm-1 col-tool-bar text-center drag-drawflow" style="padding-top: 10px; padding-right: 0px;">
              <span class="material-symbols-outlined" v-tooltip.top="`Atualizar contato`" draggable="true"
                @dragstart="drag" data-node="contact_update" style="color: #697077;">person</span>
            </div>
            <div class="col-sm-1 col-tool-bar text-center" style="padding-top: 5px; padding-right: 0px;">
              <dropdown>
                <template v-slot:text>
                  <span class="material-symbols-outlined title">
                    <img src="/assets/img/icons/flow/arrow_node.svg">
                  </span>
                </template>
                <template #items>
                  <a class="drag-drawflow dropdown-item" style="cursor: move;" draggable="true" @dragstart="drag"
                    :data-node="`${action.name}`" v-for="(action, i) in flow.actions" :key="i">
                    <span class="material-symbols-outlined to-left">{{ action.icon }}</span>
                    <span class="to-left left-15"> {{ action.label }}</span>
                    <div style="clear: both;"></div>
                  </a>
                  <a class="drag-drawflow dropdown-item" style="cursor: move;" draggable="true" @dragstart="drag"
                    data-node="send_file">
                    <span class="material-symbols-outlined to-left">upload_file</span>
                    <span class="to-left left-15"> Enviar arquivo</span>
                    <div style="clear: both;"></div>
                  </a>
                  <a class="drag-drawflow dropdown-item" style="cursor: move;" draggable="true" @dragstart="drag"
                    data-node="branch">
                    <span class="material-symbols-outlined to-left">flaky</span> <span class="to-left left-15">
                      Condição</span>
                    <div style="clear: both;"></div>
                  </a>
                  <a for="inport-flow-json" class="drag-drawflow  dropdown-item" style="cursor: move;" draggable="true"
                    @dragstart="drag" data-node="loop_flow">
                    <span class="material-symbols-outlined to-left">all_inclusive</span> <span
                      class="to-left top2 left-15"> Loop</span>
                    <div style="clear: both;"></div>
                  </a>
                  <a draggable="true" @dragstart="drag" data-node="consumir_api" class="drag-drawflow dropdown-item"
                    style="cursor: move;">
                    <span class="material-symbols-outlined to-left">cloud_upload</span> <span
                      class="to-left top2 left-15"> API</span>
                    <div style="clear: both;"></div>
                  </a>
                  <a draggable="true" @dragstart="drag" data-node="next_additional" class="drag-drawflow dropdown-item"
                    style="cursor: move;">
                    <span class="material-symbols-outlined to-left">redo</span> <span class="to-left top2 left-15">
                      Retornar Flow</span>
                    <div style="clear: both;"></div>
                  </a>
                  <a draggable="true" @dragstart="drag" data-node="transfer_omni" class="drag-drawflow dropdown-item"
                    style="cursor: move;">
                    <span class="material-symbols-outlined to-left">sync_alt</span> <span class="to-left top2 left-15">
                      Transferir Omni</span>
                    <div style="clear: both;"></div>
                  </a>
                  <a draggable="true" @dragstart="drag" data-node="flow_end" class="drag-drawflow dropdown-item"
                    style="cursor: move;">
                    <span class="material-symbols-outlined to-left">power_settings_new</span> <span
                      class="to-left top2 left-15"> Finalizar fluxo</span>
                    <div style="clear: both;"></div>
                  </a>
                </template>
              </dropdown>
            </div>
            <div class="col-sm-4 text-right" style="padding-top: 5px;">
              <button class="btn btn-primary" @click="publishFlow()" v-if="this.flow.template != true">Publicar
              </button>
              <button class="btn btn-primary" v-if="this.flow.template == true" v-b-modal.flow-create-template>Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="header-flow-bar" class="hide">
      <div class="row">
        <div class="col-sm-6">
          <h3 style="padding: 10px;" v-if="!showEditName" v-on:dblclick="showEditName = true" class="c-pointer">
            {{ flow.name }}

            <span class="badge badge-success" v-if="this.flow.active">
              Publicado
            </span>
            <span class="badge badge-danger" v-if="!this.flow.active">
              Inativo
            </span>
            <span class="badge badge-gray m-left-5" v-if="this.flow.draft">
              Rascunho
            </span>
          </h3>
          <div style="padding: 5px; width: 30%;" v-if="showEditName">
            <input class="form-control" placeholder="Título" v-model="flow.name">
          </div>
        </div>
        <div class="col-sm-6 text-right" style="padding-right: 85px; padding-top: 5px;">
          <button class="btn btn-default" @click="$router.go(-1)">
            <i class="fas fa-angle-left"></i> Voltar
          </button>
          <button v-tooltip.bottom="'Validar'" class="btn btn-warning btn-sm" @click="validate()"
            style="margin-right: 5px;" :class="{ 'qt-loader qt-loader-mini qt-loader-right': !fetched }"
            :disabled="!fetched">
            <span class="material-symbols-outlined" style="color: white;font-size: 20px;">check</span>
          </button>
          <button v-tooltip.bottom="'Salvar'" class="btn btn-success btn-sm" @click="saveFlow()"
            :class="{ 'qt-loader qt-loader-mini qt-loader-right': !fetched }" :disabled="!fetched">
            <span class="material-symbols-outlined" style="color: white;font-size: 20px;">save</span>
          </button>
          <button v-tooltip.bottom="'Publicar'" class="btn btn-info btn-sm" @click="publishFlow()"
            style="margin-left: 5px;" :class="{ 'qt-loader qt-loader-mini qt-loader-right': !fetchedPublish }"
            :disabled="!fetchedPublish">
            <span class="material-symbols-outlined" style="color: white;font-size: 20px;">publish</span>
          </button>
          <button class="btn btn-default btn-sm" for="inport-flow-json" @click="importFlow()"
            style="margin-left: 5px; border: 1px solid #ccc;" v-tooltip.bottom="'Importar'"><span
              class="material-symbols-outlined" style="font-size: 20px;">upload</span></button>
          <input type="file" accept=".yflow" hidden ref="uploadedFile" @change="uploadFile" id="inport-flow-json"
            name="inport-flow-json" />
          <button class="btn btn-default btn-sm" @click="exportFlow()" style="margin-left: 5px; border: 1px solid #ccc;"
            v-tooltip.bottom="'Exportar'"><span class="material-symbols-outlined"
              style="font-size: 20px;">download</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row text-center" v-if="!fetched" style="position: fixed; top: 120px; left: 50%;">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
    <!-- area do drawflow -->
    <div class="flowItemProperties" :class="{ 'property-closed': closeProperty }">
      <div style="overflow-y: scroll; height: 100%;">
        <div class="relative">
          <div class="flowItemProperties-header" style="padding-top: 40px;">
            <h3 class="text-center" v-if="itemSelected.name == 'simple_message'">Enviar
              mensagem</h3>
            <h3 class="text-center" v-if="itemSelected.name == 'consumir_error'">Falha na API</h3>
            <h3 class="text-center" v-if="itemSelected.name == 'contact_update'">Atualizar
              contato</h3>
            <h3 class="text-center" v-if="itemSelected.name == 'add_variable'">Variável</h3>
            <h3 class="text-center" v-if="itemSelected.name == 'response_expecter'">Solicitar
              dados</h3>
            <h3 class="text-center" v-if="itemSelected.name == 'branch'">Condição</h3>
            <h3 class="text-center" v-if="itemSelected.name == 'welcome'">Passo inicial</h3>
            <h3 class="text-center" v-if="itemSelected.name == 'interactive_whatsapp'">Interação
              WhatsApp</h3>
            <h3 class="text-center" v-if="itemSelected.name == 'rcs_card'">Cartão</h3>
            <h3 class="text-center" v-if="itemSelected.name == 'catalog_whatsapp'">Catálogo</h3>
            <h3 class="text-center" v-if="itemSelected.name == 'transfer_omni'">Transferir para o
              Omni</h3>
            <h3 class="text-center" v-if="itemSelected.name == 'consumir_api'">API</h3>
            <h3 class="text-center" v-if="itemSelected.name == 'send_file'">Enviar arquivo</h3>
            <h3 class="text-center" v-if="itemSelected.name == 'loop_flow'">Loop</h3>
            <div v-if="itemSelected.data && itemSelected.data.action_id">
              <h4>ID: {{ itemSelected.data.action_id }}</h4>
            </div>
          </div>
          <div class="flowItemProperties-body">
            <div class="row" v-if="itemSelected.name == 'consumir_api'">
              <div class="col-sm-12">
                <p>Envia uma requisição pela a API.</p>
                <hr>
              </div>
              <div class="col-sm-12">
                <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                  <h6>EndPoint</h6>
                  <input type="text" class="form-control" v-model="itemSelected.data.end_point" />
                  <h6 style="margin-top: 5px">Método</h6>
                  <select class="form-control" v-model="itemSelected.data.method">
                    <option value="POST" selected>POST</option>
                    <option value="GET">GET</option>
                  </select>
                  <h6 style="margin-top: 5px">Autenticação</h6>
                  <input type="text" class="form-control" v-model="itemSelected.data.auth" />
                  <h6 style="margin-top: 5px">Tipo</h6>
                  <select class="form-control" v-model="itemSelected.data.type_auth">
                    <option value="Basic">Basic</option>
                    <option value="Bearer">Bearer</option>
                  </select>
                  <div class="new-template-whats m-top-10" style="margin-top: 30px;">
                    <section class="m-top-10">
                      <h6 class="new-template-title">
                        <span>Header</span>
                      </h6>
                      <p style="display: block; margin-top: 8px;">
                      <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                        <div v-for="(header, i) in itemSelected.data.parameters_header" :key="i">
                          <div class="row">
                            <div class="col-sm-12" v-if="i >= 1">
                              <hr>
                            </div>
                            <div class="col-sm-12">
                              <div class="relative" style="position: relative;">
                                <input class="form-control" v-model="header.name" placeholder="Nome" />
                              </div>
                            </div>
                            <div class="col-sm-12">
                              <div class="relative" style="position: relative; margin-top: 5px;">
                                <input class="form-control" v-model="header.value" placeholder="Valor" />
                                <span style="float: right; top: 6px; position: absolute; right: 10px;" class="c-pointer"
                                  @click="removeHeader(i)" v-if="i >= 1"><i class="fas fa-times"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <hr>
                          <button class="btn btn-info btn-sm" @click="addHeader"><i class="fas fa-plus"></i></button>
                        </div>
                      </div>
                      </p>
                    </section>
                  </div>
                  <div class="new-template-whats m-top-10" style="margin-top: 15px;">
                    <hr>
                    <section class="m-top-10">
                      <h6 class="new-template-title">
                        <span>Body</span>
                      </h6>
                      <p class="text-right c919191">
                        <input type="checkbox" v-model="itemSelected.data.json_free" /> Json Livre
                      </p>
                      <p style="display: block; margin-top: 8px;" v-if="itemSelected.data.json_free">
                        <!--<v-jsoneditor v-model="itemSelected.data.json_body" :options="options" :mode="'text'" :plus="false" :height="'400px'"/>-->
                        <textarea class="form-control" v-model="itemSelected.data.json_body" rows="6" />
                      </p>
                      <p style="display: block; margin-top: 8px;" v-if="!itemSelected.data.json_free">
                      <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                        <div v-for="(body, i) in itemSelected.data.parameters_body" :key="i">
                          <div class="row">
                            <div class="col-sm-12" v-if="i >= 1">
                              <hr>
                            </div>
                            <div class="col-sm-8">
                              <div class="relative" style="position: relative;">
                                <input class="form-control" v-model="body.name" placeholder="Nome" />
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <select class="form-control" v-model="body.type">
                                <option value="TEXT">TEXT</option>
                                <option value="INTEGER">INTEGER</option>
                                <option value="DOUBLE">DOUBLE</option>
                                <option value="BOOLEAN">BOOLEAN</option>
                              </select>
                            </div>
                            <div class="col-sm-12">
                              <div class="relative" style="position: relative; margin-top: 5px;">
                                <input class="form-control" v-model="body.value" placeholder="Valor" />
                                <span style="float: right; top: 6px; position: absolute; right: 10px;" class="c-pointer"
                                  @click="removeBody(i)" v-if="i >= 1"><i class="fas fa-times"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <hr>
                          <button class="btn btn-info btn-sm" @click="addBody"><i class="fas fa-plus"></i></button>
                        </div>
                      </div>
                      </p>
                    </section>
                  </div>
                  <div class="new-template-whats m-top-10" style="margin-top: 15px;">
                    <section class="m-top-10">
                      <h6 class="new-template-title">
                        <span>Path</span>
                      </h6>
                      <p style="display: block; margin-top: 8px;">
                      <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                        <div v-for="(path, i) in itemSelected.data.parameters_path" :key="i">
                          <div class="row">
                            <div class="col-sm-12" v-if="i >= 1">
                              <hr>
                            </div>
                            <div class="col-sm-12">
                              <div class="relative" style="position: relative;">
                                <input class="form-control" v-model="path.name" placeholder="Nome" />
                              </div>
                            </div>
                            <div class="col-sm-12">
                              <div class="relative" style="position: relative; margin-top: 5px;">
                                <input class="form-control" v-model="path.value" placeholder="Valor" />
                                <span style="float: right; top: 6px; position: absolute; right: 10px;" class="c-pointer"
                                  @click="removePath(i)" v-if="i >= 1"><i class="fas fa-times"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <hr>
                          <button class="btn btn-info btn-sm" @click="addPath"><i class="fas fa-plus"></i></button>
                        </div>
                      </div>
                      </p>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="itemSelected.name == 'welcome'">
              <div class="col-sm-12" v-if="itemSelected.data.type_trigger == 'WEBHOOK'">
                <p>O fluxo começa quando uma requisição é enviada na API.</p>
                <i class="fas fa-wrench"></i> Webhook
                <hr>
              </div>
              <div class="col-sm-12" v-if="itemSelected.data.type_trigger == 'CHANNEL'">
                <p>O fluxo começa quando o usuário manda uma mensagem no canal.</p>
                <hr>
              </div>
              <div class="col-sm-12">
                <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                  <h6>EndPoint (POST)</h6>
                  <p class="flow-webhook-panel c-pointer" v-tooltip.top="`${textCopy}`"
                    @click="copy('http://app.yup.chat:8097')">http://app.yup.chat:8097</p>
                  <h6>Path</h6>
                  <p class="flow-webhook-panel c-pointer" v-tooltip.top="`${textCopy}`" @click="copy(webHookInfo.uri)">
                    {{ webHookInfo.uri }}</p>
                  <h6>Token</h6>
                  <p class="flow-webhook-panel c-pointer" v-tooltip.top="`${textCopy}`"
                    @click="copy(webHookInfo.token)">
                    {{ webHookInfo.token }}</p>
                  <h6>Autenticação</h6>
                  <p class="flow-webhook-panel c-pointer" v-tooltip.top="`${textCopy}`"
                    @click="copy(webHookInfo.type_auth)">{{ webHookInfo.type_auth }}</p>
                  <hr>
                  <h6>Campos</h6>
                  <h6>Nome do cliente</h6>
                  <p class="flow-webhook-panel c-pointer" v-tooltip.top="`${textCopy}`"
                    @click="copy(flow.client_name_field)">{{ flow.client_name_field }}</p>
                  <h6>Email do cliente</h6>
                  <p class="flow-webhook-panel c-pointer" v-tooltip.top="`${textCopy}`"
                    @click="copy(flow.client_email_field)">{{ flow.client_email_field }}</p>
                  <h6>Telefone do cliente</h6>
                  <p class="flow-webhook-panel c-pointer" v-tooltip.top="`${textCopy}`"
                    @click="copy(flow.client_phone_field)">{{ flow.client_phone_field }}</p>
                </div>
              </div>
            </div>
            <div class="row" v-if="itemSelected.name == 'send_file'">
              <div class="col-sm-12">
                <p>Envie uma imagem, vídeo, audio ou documento.</p>
                <hr>
              </div>
              <div class="col-sm-12">
                <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                  <h4>Tipo de arquivo</h4>
                  <p style="color: #b5b5b5;">Coloquei o nome da variável que irá armazenar os dados
                    digitado pelo usuário.
                  </p>
                  <div class="form-group row">
                    <div class="col-md-4 text-center">
                      <div class="preview-media-select preview-media-select-image"
                        :class="{ 'preview-media-select-active': itemSelected.data.type == 'image' }"
                        @click="itemSelected.data.type = 'image'">
                        <label></label>
                        <span>Imagem</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="preview-media-select preview-media-select-video"
                        :class="{ 'preview-media-select-active': itemSelected.data.type == 'video' }"
                        @click="itemSelected.data.type = 'video'">
                        <label></label>
                        <span>Vídeo</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="preview-media-select preview-media-select-document"
                        :class="{ 'preview-media-select-active': itemSelected.data.type == 'file' }"
                        @click="itemSelected.data.type = 'file'">
                        <label></label>
                        <span>Arquivo</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12" style="margin-bottom: 10px;">
                <div
                  style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px; margin-top: 10px;">
                  <h4>URL</h4>
                  <div class="form-group row">
                    <div class="col-md-12 text-center">
                      <input class="form-control" v-model="itemSelected.data.url">
                    </div>
                  </div>
                </div>
                <div
                  style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px; margin-top: 10px;">
                  <h4>Arquivo</h4>
                  <div class="form-group row">
                    <div class="col-md-12 text-center">
                      <div class="input-group mb-3">
                        <div class="form-control preview-file-name text-left" style="overflow: hidden;">{{ fileName }}
                        </div>
                        <div class="input-group-append">
                          <button class="btn btn-info" type="button"
                            @click="openFileImageUpload('preview-file-image-card-node')">
                            Buscar
                          </button>
                        </div>
                      </div>
                      <input ref="fileUploadImage" type="file" hidden @change="onUploadFileImage" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12 text-center">
                      <video style="width: 100%;" v-if="itemSelected.data.type == 'video'" ref="previewMp4" controls
                        class="preview-file-card-node">
                        <source :src="itemSelected.data.url">
                      </video>
                      <iframe style="width: 100%;" :src="itemSelected.data.url" class="preview-file-card-node"
                        id="preview-image-header-pdf" ref="previewPdf"
                        v-if="itemSelected.data.type == 'file' && itemSelected.data.mime_type == 'application/pdf'"></iframe>
                      <div v-if="itemSelected.data.type == 'image'" class="preview-file-card-node text-right"
                        id="preview-file-image-card-node"
                        :style="`background-image: url(${itemSelected.data.url}); background-size: cover;`">
                        <button class="btn btn-danger btn-remove-file-node-card hide"><i
                            class="fa fa-times"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="itemSelected.name == 'response_expecter'">
              <div class="col-sm-12">
                <p>Essa ação espera que um texto ou informação seja digitada pelo usuário. Ex.: Nome
                  ou telefone</p>
                <hr>
              </div>
              <div class="col-sm-12">
                <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                  <h4>Nome da variável</h4>
                  <p style="color: #b5b5b5;">Coloquei o nome da variável que irá armazenar os dados
                    digitado pelo usuário.
                  </p>
                  <input class="form-control" placeholder="Variável" v-model="itemSelected.data.variable_name" />
                </div>
              </div>
              <div class="col-sm-12">
                <br>
                <div>
                  <h4 class="to-left">Salvar dados de contato</h4>
                  <div class="to-right">
                    <div class="control-group opt-2 toggle-check" style="margin-top: 0px;">
                      <label class="control control-checkbox">
                        <input type="checkbox" name="save_field" v-model="itemSelected.data.save_field"
                          @change="changeCustomType('select')" />
                        <div class="control_indicator" style="margin-bottom: 0;margin-right:0;"></div>
                      </label>
                    </div>
                  </div>
                  <div style="clear:both;"></div>
                </div>
                <label v-if="itemSelected.data.save_field">Campo</label>
                <select class="form-control" style="margin-bottom: 20px;" v-if="itemSelected.data.save_field"
                  v-model="itemSelected.data.contact_field">
                  <option value="first_name">Primeiro Nome</option>
                  <option value="last_name">Sobrenome</option>
                  <option value="birth_date">Data de nascimento</option>
                  <option value="mobile_number">Celular</option>
                  <option value="email">Email</option>
                  <option value="gender">Gênero</option>
                  <option value="city">Cidade</option>
                  <option value="country">País</option>
                  <option value="description">Descrição</option>
                  <option value="phone">Telefone</option>
                  <option value="company">Empresa</option>
                  <option value="address">Endereço</option>
                  <option value="complement">Complemento</option>
                  <option value="district">Bairro</option>
                  <option value="zip">CEP</option>
                  <option value="state">Estado</option>
                  <option v-for="(input, i) in customInputs" :key="i" :value="input.value">
                    {{ input.name }}
                  </option>
                </select>
                <hr>
                <div class="hide">
                  <h4 class="to-left">Campo personalizados</h4>
                  <div class="to-right">
                    <div class="control-group opt-2 toggle-check" style="margin-top: 0px;">
                      <label class="control control-checkbox">
                        <input type="checkbox" name="save_field" v-model="itemSelected.data.save_field_custom"
                          @change="changeCustomType('custom')" />
                        <div class="control_indicator" style="margin-bottom: 0;margin-right:0;"></div>
                      </label>
                    </div>
                  </div>
                  <div style="clear:both;"></div>
                </div>
                <label v-if="itemSelected.data.save_field_custom">&nbsp;</label>
                <input placeholder="Nome do campo" class="form-control" style="margin-bottom: 20px;"
                  v-model="itemSelected.data.custom_contact_field" v-if="itemSelected.data.save_field_custom" />
                <hr>
                <div>
                  <h4 class="to-left">Adicionar validação</h4>
                  <div class="to-right">
                    <div class="control-group opt-2 toggle-check" style="margin-top: 0px;">
                      <label class="control control-checkbox">
                        <input type="checkbox" name="save_field" v-model="itemSelected.data.has_regex" />
                        <div class="control_indicator" style="margin-bottom: 0;margin-right:0;"></div>
                      </label>
                    </div>
                  </div>
                  <div style="clear:both;"></div>
                  <div v-if="itemSelected.data.has_regex">
                    <label>Regex de validação:</label>
                    <input class="form-control" type="text" v-model="itemSelected.data.regex_validation"
                      maxlength="500" />
                    <label>Mensagem inválida:</label>
                    <textarea class="form-control" type="text" v-model="itemSelected.data.invalid_message"
                      maxlength="500"></textarea>
                  </div>
                </div>
                <hr>
              </div>
            </div>
            <div class="row" v-if="itemSelected.name == 'template_whatsapp'">
              <div class="col-sm-12">
                <p>Essa ação irá disparar uma mensagem WhatsApp</p>
                <hr>
              </div>
              <div class="col-sm-12">
                <select class="form-control" @change="changeTemplate" v-model="selectedTemplate"
                  v-if="flow.channel_id !== whatsIdLive">
                  <option value>{{ $t('generic-str.lbl-choose-template') }}</option>
                  <option v-for="template in templates" :key="template.id" :value="JSON.stringify(template)">{{
          template.name
        }}
                  </option>
                </select>
                <select class="form-control" @change="changeNewTemplate" v-model="selectedTemplate"
                  v-if="flow.channel_id === whatsIdLive">
                  <option value>{{ $t('generic-str.lbl-choose-template') }}</option>
                  <option v-for="template in newTemplates" :key="template.id" :value="JSON.stringify(template)">{{
          template.name
        }}
                  </option>
                </select>
                <div>
                  <div class="form-group">
                    <div class="row" style="margin-top:10px;">
                      <div class="col-lg-12" v-if="form.template.body">
                        <h4>Preview</h4>
                      </div>
                      <div class="col-lg-12" v-if="form.template.body">
                        <div class="whats-container" style="background: none;">
                          <div class="whats-preview">
                            <div class="relative">
                              <img id="img-preview" :src="`${form.template.header.example.header_handle[0]}`"
                                v-if="form.template.header && form.template.header.format === 'IMAGE'" />
                            </div>
                            <p class="whats-preview-header"
                              v-if="form.template.header && form.template.header.format === 'TEXT'">
                              {{ form.template.header.text }}</p>
                            <p class="whats-preview-body">{{ form.template.body }}</p>
                            <p class="whats-preview-footer" v-if="form.template.footer" style="color: #a49e9e;">
                              {{ form.template.footer.body }}</p>
                          </div>
                          <div class="row" v-if="form.template.buttons">
                            <div class="col-sm-12" v-for="(btn, i) in form.template.buttons" :key="i">
                              <button type="button" class="btn-link-preview" v-if="btn.type == 'PHONE_NUMBER'"><i
                                  class="fas fa-phone"></i> {{ btn.text }}
                              </button>
                              <button type="button" class="btn-link-preview" v-if="btn.type == 'URL'"><i
                                  class="fas fa-link"></i> {{ btn.text }}
                              </button>
                              <button type="button" class="btn-link-preview" v-if="btn.type == 'QUICK_REPLY'">{{
          btn.text }}
                              </button>
                            </div>
                          </div>
                          <div class="form-group" style="margin-top:10px;">
                            <div class="row" v-for="(p, i) in this.form.template.params" :key="p.index">
                              <div class="col">
                                <div class="relative">
                                  <div class="popup-box-container dropdown to-right var-insede-input"
                                    style="margin-left:5px;" v-if="form.type != 'simple' && form.file">
                                    <a href="javascript:void(0)" v-dropdown>
                                      {{ $t('sms.send-msg.sms-input-component.lbl-variables') }} <i class="fas fa-plus"
                                        v-tooltip.top="$t('sms.send-msg.sms-input-component.lbl-variables')"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
                                      <a class="dropdown-item" href="javascript:void(0)" v-for="variable in vars"
                                        :key="variable.value" @click="addVarParams(variable, i)">{{ variable.name }}</a>
                                    </div>
                                  </div>
                                  <label>{{ $t('generic-str.lbl-param') }} {{ p.index }}</label>
                                  <!-- Parâmetro -->
                                  <input class="form-control" type="text" v-model="p.text" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12" style="margin-bottom: 10px;padding-left: 25px;padding-right: 25px;"
                        v-if="this.itemSelected.data.template.type == 'image' || this.itemSelected.data.template.type == 'video'">
                        <div
                          style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px; margin-top: 10px;">
                          <div class="form-group row">
                            <div class="col-md-12 text-center">
                              <div class="input-group mb-3">
                                <div class="form-control preview-file-name text-left" style="overflow: hidden;">
                                  {{ fileName }}
                                </div>
                                <div class="input-group-append">
                                  <button class="btn btn-info" type="button"
                                    @click="openFileImageUpload('preview-file-image-card-node-temp')">
                                    Buscar
                                  </button>
                                </div>
                              </div>
                              <input ref="fileUploadImage" type="file" hidden @change="onUploadFileImage" />
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="col-md-12 text-center" v-if="this.itemSelected.data.template.type === 'image'">
                              <div class="preview-file-card-node text-right" id="preview-file-image-card-node-temp"
                                :style="`background-image: url(${itemSelected.data.template.header_link}); background-size: cover;`">
                                <button class="btn btn-danger btn-remove-file-node-card hide"><i
                                    class="fa fa-times"></i></button>
                              </div>
                            </div>
                            <div class="col-md-12 text-center" v-if="this.itemSelected.data.template.type === 'video'">
                              <video style="width: 100%;" ref="previewTempMp4" controls class="preview-file-card-node">
                                <source :src="itemSelected.data.template.header_link">
                              </video>
                            </div>
                            <div class="col-md-12 text-center"
                              v-if="this.itemSelected.data.template.type === 'document'">
                              <iframe style="width: 100%;" :src="itemSelected.data.template.header_link"
                                class="preview-file-card-node" id="preview-image-header-pdf"
                                ref="previewNewPdf"></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
              </div>
            </div>
            <div class="row" v-if="itemSelected.name == 'transfer_omni'">
              <div class="col-sm-12">
                <p>Essa ação irá transferir o usuário para o Omni</p>
                <hr>
              </div>
              <div class="col-sm-12">
                <h4>Departamento</h4>
              </div>
              <div class="col-sm-12">
                <select class="form-control" v-model="itemSelected.data.department">
                  <option v-for="department in departments" :key="department.id" :value="department.id">{{
          department.name
        }}
                  </option>
                </select>

              </div>
              <div class="col-sm-12">
                <br>
                <h4>Mensagem padrão</h4>
              </div>
              <div class="col-sm-12">
                <textarea placeholder="Mensagem" class="form-control" v-model="itemSelected.data.transfer_message"
                  rows="5"></textarea>
                <hr>
              </div>
            </div>
            <div class="row new-template-whats" v-if="itemSelected.name == 'simple_message'">
              <section class="col-sm-12">
                <ul class="list-group list-group-flush hide" style="display: block;">
                  <li class="list-group-item btn-change-typebutton" @click="itemSelected.data.type_send = 'TEXT'"
                    :class="{ 'preview-media-select-active': itemSelected.data.type_send == 'TEXT' }"
                    style="float: left;width: 33%;">
                    <div class="media">
                      <div class="media-body text-center">
                        <strong>Texto</strong>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item btn-change-typebutton" @click="itemSelected.data.type_send = 'FILE'"
                    :class="{ 'preview-media-select-active': itemSelected.data.type_send == 'FILE' }"
                    style="float: left;width: 33%;">
                    <div class="media">
                      <div class="media-body text-center">
                        <strong>Arquivo</strong>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item btn-change-typebutton" @click="itemSelected.data.type_send = 'MULT'"
                    :class="{ 'preview-media-select-active': itemSelected.data.type_send == 'MULT' }"
                    style="float: left;width: 33%;">
                    <div class="media">
                      <div class="media-body text-center">
                        <strong>Multiplo</strong>
                      </div>
                    </div>
                  </li>
                  <div style="clear:both;"></div>
                </ul>
              </section>
              <div class="col-sm-12">
                <p>Essa ação irá disparar uma mensagem simples</p>
                <hr>
              </div>
              <div class="col-sm-12">
                <textarea class="form-control" placeholder="Insira o seu texto" v-model="itemSelected.data.text"
                  rows="5"></textarea>
                <hr>
              </div>
              <div>
                <hr>
              </div>
            </div>
            <div class="row new-template-whats" v-if="itemSelected.name == 'consumir_error'">
              <section class="col-sm-12">
                <ul class="list-group list-group-flush hide" style="display: block;">
                  <li class="list-group-item btn-change-typebutton" @click="itemSelected.data.type_send = 'TEXT'"
                    :class="{ 'preview-media-select-active': itemSelected.data.type_send == 'TEXT' }"
                    style="float: left;width: 33%;">
                    <div class="media">
                      <div class="media-body text-center">
                        <strong>Texto</strong>
                      </div>
                    </div>
                  </li>
                  <div style="clear:both;"></div>
                </ul>
              </section>
              <div class="col-sm-12">
                <p>Essa ação irá disparar uma mensagem de erro na api</p>
                <hr>
              </div>
              <div class="col-sm-12">
                <textarea class="form-control" placeholder="Insira o seu texto" v-model="itemSelected.data.text"
                  rows="5"></textarea>
                <hr>
              </div>
              <div>
                <hr>
              </div>
            </div>
            <div class="row new-template-whats" v-if="itemSelected.name == 'contact_update'">
              <div class="col-sm-12">
                <p>Essa ação irá atualizar a informação do seu contato</p>
                <hr>
              </div>
              <label>Campo</label>
              <select class="form-control" style="margin-bottom: 20px;" v-model="itemSelected.data.field">
                <option value="first_name">Primeiro Nome</option>
                <option value="last_name">Sobrenome</option>
                <option value="birth_date">Data de nascimento</option>
                <option value="mobile_number">Celular</option>
                <option value="email">Email</option>
                <option value="gender">Gênero</option>
                <option value="city">Cidade</option>
                <option value="country">País</option>
                <option value="description">Descrição</option>
                <option value="phone">Telefone</option>
                <option value="company">Empresa</option>
                <option value="address">Endereço</option>
                <option value="complement">Complemento</option>
                <option value="district">Bairro</option>
                <option value="zip">CEP</option>
                <option value="state">Estado</option>
                <option v-for="(input, i) in customInputs" :key="i" :value="input.value">
                  {{ input.name }}
                </option>
              </select>
              <div class="col-sm-12">
                <label>Atributo ou variável</label>
                <input class="form-control" placeholder="Ex.: [primeiro_nome]" v-model="itemSelected.data.value" />
                <hr>
              </div>
              <div>
                <hr>
              </div>
            </div>
            <div class="row new-template-whats" v-if="itemSelected.name == 'add_variable'">
              <div class="col-sm-12">
                <p>Esse card adiciona uma variável no flow</p>
                <hr>
              </div>
              <div class="col-sm-12">
                <label>Nome da variável</label>
                <input class="form-control" placeholder="" v-model="itemSelected.data.name" />
              </div>
              <div class="col-sm-12" style="margin-top: 15px; margin-bottom: 15px;">
                <input type="checkbox" v-model="itemSelected.data.calc" /> Faz cálculo?
                <p style="margin-top:5px; display:block; font-size: 12px;color: #9b9b9b;">Essa
                  condição verifica se no
                  conteúdo tem algum cálculo ou não</p>
              </div>
              <div class="col-sm-12">
                <label>Valor da variável</label>
                <input class="form-control" placeholder="Ex.: [primeiro_nome]" v-model="itemSelected.data.value" />
                <hr>
              </div>
              <div>
                <hr>
              </div>
            </div>
            <div class="row new-template-whats" v-if="itemSelected.name == 'loop_flow'">
              <div class="col-sm-12">
                <p>Essa ação irá disparar um loop para extrair os dados de um array</p>
                <hr>
              </div>
              <div class="col-sm-12">
                <input class="form-control" placeholder="Atributo" v-model="itemSelected.data.attribute" />
                <hr>
              </div>
              <div>
                <hr>
              </div>
            </div>
            <div class="row" v-if="itemSelected.name === 'loop_flow' && itemSelected.send_element">
              <div class="col-sm-12">
                <p>Próxima ação do loop</p>
                <hr>
              </div>
              <div class="col-sm-12">
                <div style="border: 2px dashed #dfdfdf; border-radius: 10px !important; padding: 20px;">
                  <div class="drawflow-node send_file" v-if="itemSelected.send_element.name === 'send_file'">
                    <div class="title-box padding-10"><i class="fas fa-file"></i> Enviar arquivo
                    </div>
                  </div>
                  <div class="drawflow-node simple_message" v-if="itemSelected.send_element.name === 'simple_message'">
                    <div class="title-box padding-10"><i class="fas fa-comment"></i> Enviar mensagem
                    </div>
                  </div>
                  <div class="drawflow-node consumir_error" v-if="itemSelected.send_element.name === 'consumir_error'">
                    <div class="title-box padding-10"><i class="fas fa-comment"></i> Falha na API
                    </div>
                  </div>
                  <div class="drawflow-node contact_update" v-if="itemSelected.send_element.name === 'contact_update'">
                    <div class="title-box padding-10"><i class="fas fa-user"></i> Atualizar contato
                    </div>
                  </div>
                  <div class="drawflow-node add_variable" v-if="itemSelected.send_element.name === 'add_variable'">
                    <div class="title-box padding-10"><i class="fas fa-database"></i> Variável</div>
                  </div>
                  <div class="drawflow-node response_expecter"
                    v-if="itemSelected.send_element.name === 'response_expecter'">
                    <div class="title-box padding-10" style="border-radius: 10px !important;"><i
                        class="fas fa-solid fa-edit"></i> Solicitar dados
                    </div>
                  </div>
                  <div class="drawflow-node branch" v-if="itemSelected.send_element.name === 'branch'">
                    <div class="title-box padding-10" style="border-radius: 10px !important;"><i
                        class="fa fa-sitemap"></i>
                      Condição
                    </div>
                  </div>
                  <div class="drawflow-node interactive_whatsapp"
                    v-if="itemSelected.send_element.name === 'interactive_whatsapp'">
                    <div class="title-box padding-10" style="border-radius: 10px !important;"><i
                        class="fab fa-whatsapp"></i> Interação
                    </div>
                  </div>
                  <div class="drawflow-node rcs_card" v-if="itemSelected.send_element.name === 'rcs_card'">
                    <div class="title-box padding-10" style="border-radius: 10px !important;"><i
                        class="fab fa-google"></i>
                      Cartão
                    </div>
                  </div>
                  <div class="drawflow-node rcs_carousel" v-if="itemSelected.send_element.name === 'rcs_carousel'">
                    <div class="title-box padding-10" style="border-radius: 10px !important;"><i
                        class="fab fa-google"></i>
                      Carrossél
                    </div>
                  </div>
                  <div class="drawflow-node catalog_whatsapp"
                    v-if="itemSelected.send_element.name === 'catalog_whatsapp'">
                    <div class="title-box padding-10" style="border-radius: 10px !important;"><i
                        class="fa fa-store"></i>
                      Catálog
                    </div>
                  </div>
                  <div class="drawflow-node template_whatsapp"
                    v-if="itemSelected.send_element.name === 'template_whatsapp'">
                    <div class="title-box padding-10" style="border-radius: 10px !important;"><i
                        class="fab fa-whatsapp"></i> Template WhatsApp
                    </div>
                  </div>
                  <div class="drawflow-node transfer_omni" v-if="itemSelected.send_element.name === 'transfer_omni'">
                    <div class="title-box padding-10" style="border-radius: 10px !important;"><i
                        class="fas fa-share"></i>
                      Transferir Omni
                    </div>
                  </div>
                  <div class="drawflow-node consumir_api" v-if="itemSelected.send_element.name === 'consumir_api'">
                    <div class="title-box padding-10" style="border-radius: 10px !important;"><i
                        class="fas fa-wrench"></i>
                      API
                    </div>
                  </div>
                  <div class="drawflow-node flow_end" v-if="itemSelected.send_element.name === 'flow_end'">
                    <div class="title-box padding-10" style="border-radius: 10px !important;"><i
                        class="fas fa-solid fa-check"></i> Finalizar Fluxo
                    </div>
                  </div>
                  <div class="drawflow-node next_additional"
                    v-if="itemSelected.send_element.name === 'next_additional'">
                    <div class="title-box padding-10" style="border-radius: 10px !important;"><i
                        class="fas fa-solid fa-undo"></i> Retornar flow
                    </div>
                  </div>
                </div>
                <br>
              </div>
            </div>
            <div class="row" v-if="itemSelected.name == 'branch'">
              <div class="col-sm-12">
                <p>Essa ação analisa a informação digita pelo usuário e direciona o fluxo</p>
                <hr>
              </div>
              <div class="col-sm-12">
                <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                  <h4>Nome da variável</h4>
                  <p style="color: #b5b5b5;">Coloquei o nome da variável definida na ação que recebe
                    os dados.</p>
                  <input class="form-control" placeholder="" v-model="itemSelected.data.attribute" />
                  <br>
                  <p style="color: #b5b5b5;">Mensagem de quando a opção for inválida.</p>
                  <textarea class="form-control" placeholder="" v-model="itemSelected.data.invalid_message"></textarea>
                </div>
              </div>
            </div>
            <div class="row" v-if="itemSelected.name == 'interactive_whatsapp'">
              <div class="col-sm-12">
                <p>Essa ação irá disparar uma mensagem de interação no WhatsApp</p>
                <hr>
                <div class="new-template-whats">
                  <section>
                    <h4 class="new-template-title">
                      Tipo de interação
                    </h4>
                    <p>
                      <span>
                        Escolha um modelo de interação.
                      </span>
                    </p>
                  </section>
                  <section>
                    <ul class="list-group list-group-flush" style="display: block;">
                      <li class="list-group-item btn-change-typebutton"
                        :class="{ 'active': itemSelected.data.template.type == 'buttons' }"
                        @click="itemSelected.data.template.type = 'buttons'" style="float: left;width: 50%;">
                        <div class="media">
                          <div class="media-body text-center">
                            <strong>Botão</strong>
                          </div>
                        </div>
                      </li>
                      <li class="list-group-item btn-change-typebutton"
                        :class="{ 'active': itemSelected.data.template.type == 'menu' }"
                        @click="itemSelected.data.template.type = 'menu'" style="float: left;width: 50%;">
                        <div class="media">
                          <div class="media-body text-center">
                            <strong>Menu</strong>
                          </div>
                        </div>
                      </li>
                      <div style="clear:both;"></div>
                    </ul>
                  </section>
                </div>
                <div class="new-template-whats m-top-10">
                  <div class="col-sm-12">
                    <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                      <h4>Header</h4>
                      <div class="form-group row">
                        <div class="col-md-3 text-center" v-if="itemSelected.data.template.type == 'buttons'">
                          <div class="preview-media-select preview-media-select-text"
                            :class="{ 'preview-media-select-active': itemSelected.data.template.header.type == 'TEXT' || itemSelected.data.template.header.type == null || itemSelected.data.template.header.type == '' }"
                            @click="itemSelected.data.template.header.type = 'TEXT'">
                            <label></label>
                            <span>Texto</span>
                          </div>
                        </div>
                        <div class="col-md-3 text-center" v-if="itemSelected.data.template.type == 'buttons'">
                          <div class="preview-media-select preview-media-select-image"
                            :class="{ 'preview-media-select-active': itemSelected.data.template.header.type == 'IMAGE' }"
                            @click="itemSelected.data.template.header.type = 'IMAGE'">
                            <label></label>
                            <span>Imagem</span>
                          </div>
                        </div>
                        <div class="col-md-3" v-if="itemSelected.data.template.type == 'buttons'">
                          <div class="preview-media-select preview-media-select-video"
                            :class="{ 'preview-media-select-active': itemSelected.data.template.header.type == 'VIDEO' }"
                            @click="itemSelected.data.template.header.type = 'VIDEO'">
                            <label></label>
                            <span>Vídeo</span>
                          </div>
                        </div>
                        <div class="col-md-3" v-if="itemSelected.data.template.type == 'buttons'">
                          <div class="preview-media-select preview-media-select-document"
                            :class="{ 'preview-media-select-active': itemSelected.data.template.header.type == 'DOCUMENT' }"
                            @click="itemSelected.data.template.header.type = 'DOCUMENT'">
                            <label></label>
                            <span>DOC</span>
                          </div>
                        </div>
                      </div>
                      <section class="relative"
                        v-if="(itemSelected.data.template.header.type == 'TEXT' || itemSelected.data.template.header.type == null || itemSelected.data.template.header.type == '') || itemSelected.data.template.type == 'menu'">
                        <input class="form-control" maxlength="60" v-model="itemSelected.data.template.header.text" />
                      </section>

                      <div class="col-sm-12" style="margin-bottom: 0px;"
                        v-if="(itemSelected.data.template.header.type == 'IMAGE' || itemSelected.data.template.header.type == 'VIDEO' || itemSelected.data.template.header.type == 'DOCUMENT') && itemSelected.data.template.type == 'buttons'">
                        <div style="border-radius: 0px !important; padding: 0px; margin-top: 0px;">
                          <h4>Arquivo</h4>
                          <div class="form-group row">
                            <div class="col-md-12 text-center">
                              <div class="input-group mb-3">
                                <div class="form-control preview-file-name text-left" style="overflow: hidden;">
                                  {{ fileName }}
                                </div>
                                <div class="input-group-append">
                                  <button class="btn btn-info" type="button"
                                    @click="openFileImageUpload('preview-file-image-card-node-interact')">
                                    Buscar
                                  </button>
                                </div>
                              </div>
                              <input ref="fileUploadImage" type="file" hidden @change="onUploadFileImage" />
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="col-md-12 text-center">
                              <video style="width: 100%;" v-if="itemSelected.data.template.header.type == 'VIDEO'"
                                ref="previewMp4" controls class="preview-file-card-node">
                                <source :src="itemSelected.data.template.header.link">
                              </video>
                              <div v-if="itemSelected.data.template.header.type == 'IMAGE'"
                                class="preview-file-card-node text-right" id="preview-file-image-card-node-interact"
                                :style="`background-image: url(${itemSelected.data.template.header.link}); background-size: cover;`">
                                <button class="btn btn-danger btn-remove-file-node-card hide"><i
                                    class="fa fa-times"></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="new-template-whats m-top-10" style="margin-top: 15px;">
                  <section class="m-top-10">
                    <h6 class="new-template-title">
                      <span>Body</span>
                    </h6>
                  </section>
                  <section class="relative">
                    <textarea class="form-control" maxlength="1024" v-model="itemSelected.data.template.body"
                      rows="5"></textarea>
                  </section>
                </div>
                <div class="new-template-whats m-top-10" style="margin-top: 15px;">
                  <section class="m-top-10">
                    <h6 class="new-template-title">
                      <span>Footer</span>
                    </h6>
                    <p style="display: block; margin-top: 8px;">
                      <span class="span-info">
                        Adicione uma pequena linha de texto na parte inferior da mensagem.
                      </span>
                    </p>
                  </section>
                  <section class="relative">
                    <input class="form-control" maxlength="60" v-model="itemSelected.data.template.footer" />
                  </section>
                </div>
                <div class="new-template-whats m-top-10" style="margin-top: 15px;"
                  v-if="itemSelected.data.template.type !== 'buttons'">
                  <section class="m-top-10">
                    <h6 class="new-template-title">
                      <span>Texto do botão</span>
                    </h6>
                  </section>
                  <section class="relative">
                    <input class="form-control" maxlength="20" v-model="itemSelected.data.template.button_text" />
                  </section>
                </div>
                <div class="new-template-whats m-top-10" style="margin-top: 15px;"
                  v-if="itemSelected.data.template.type == 'buttons'">
                  <section class="m-top-10">
                    <h6 class="new-template-title">
                      <span>Botões</span>
                    </h6>
                    <p style="display: block; margin-top: 8px;">
                    <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                      <div v-for="(button, i) in itemSelected.data.template.buttons" :key="i">
                        <div class="relative" style="width: 100%; margin-top: 5px; position: relative;">
                          <input class="form-control" v-model="button.title" maxlength="20" />
                          <span style="float: right; top: 6px; position: absolute; right: 10px;" class="c-pointer"
                            @click="removeButtons(i)" v-if="i >= 1"><i class="fas fa-times"></i></span>
                        </div>
                      </div>
                      <div>
                        <hr>
                        <button class="btn btn-info btn-sm" @click="addButtons"><i class="fas fa-plus"></i></button>
                      </div>
                    </div>
                    </p>
                  </section>
                </div>
                <div class="new-template-whats m-top-10" style="margin-top: 15px;"
                  v-if="itemSelected.data.template.type == 'menu'">
                  <section class="m-top-10">
                    <h6 class="new-template-title">
                      <span>Menu</span>
                    </h6>
                    <p style="display: block; margin-top: 8px;">
                    <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                      <h6>Sessões</h6>
                      <div v-for="(session, i) in itemSelected.data.template.sections" :key="i"
                        class="flow-menu-session-list">
                        <div class="relative" style="width: 100%; margin-top: 5px; position: relative;">
                          <input class="form-control" v-model="session.title" maxlength="24" />
                          <span style="float: right; top: 6px; position: absolute; right: 10px;" class="c-pointer"
                            @click="removeSession(i)" v-if="i >= 1"><i class="fas fa-times"></i></span>
                        </div>
                        <hr>
                        <div class="flow-menu-session-list-sublist">
                          <h6>Opções</h6>
                          <div v-for="(list, j) in session.sectionList" :key="j" class="flow-menu-sub-session-list">
                            <div class="relative" style="width: 100%; margin-top: 5px; position: relative;">
                              <input class="form-control" v-model="list.title" maxlength="24" />
                              <span style="font-size: 13px;color: #939393;">Descrição</span>
                              <textarea class="form-control" v-model="list.description" maxlength="72"></textarea>
                              <span style="float: right; top: 6px; position: absolute; right: 10px;" class="c-pointer"
                                @click="removeList(session, j)"><i class="fas fa-times"></i></span>
                            </div>
                          </div>
                          <button class="btn btn-success btn-sm" style="margin-top:5px;" @click="addList(session)"><i
                              class="fas fa-plus"></i></button>
                        </div>
                      </div>
                      <div>
                        <hr>
                        <button class="btn btn-info btn-sm" @click="addSessions"><i class="fas fa-plus"></i></button>
                      </div>
                    </div>
                    </p>
                  </section>
                </div>
              </div>
            </div>
            <div class="row" v-if="itemSelected.name == 'rcs_card'">
              <div class="col-sm-12">
                <p>Essa ação irá disparar um cartão RCS</p>
                <hr>
                <div class="new-template-whats">
                  <div>
                    <div class="row">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-content">
                            <div class="card-img h-200 d-flex">
                              <img class="card-img-top img-fluid" :src="itemSelected.data.body.card.media.url"
                                alt="Card image cap" />
                              <ImageUploader @upload="(file) =>
          (itemSelected.data.body.card.media.url = file.url)
          ">
                                <i class="fas fa-edit card-edit-btn absolute"></i>
                              </ImageUploader>
                            </div>
                            <div class="card-body">
                              <h4 class="card-title">
                                <input class="form-control" placeholder="Titulo do Card"
                                  v-model="itemSelected.data.body.card.title" />
                              </h4>
                              <p class="card-text">
                                <textarea class="form-control" placeholder="Descrição"
                                  v-model="itemSelected.data.body.card.text" />
                              </p>
                            </div>
                            <RcsButtons v-model="itemSelected.data.body.card.buttons" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="itemSelected.name == 'rcs_carousel'">
              <div class="col-sm-12">
                <p>Essa ação irá disparar um Carrosséis RCS</p>
                <hr>
                <div class="new-template-whats">
                  <div class="row carousel">
                    <div v-for="(card, n) in itemSelected.data.body.carousel.cards" :key="n" class="col-14"
                      style="margin-right: 10px;">
                      <div class="card">
                        <div class="card-content">
                          <div class="card-img h-200 d-flex">
                            <img class="card-img-top img-fluid" :src="card.media.url" alt="Card image cap" />
                            <ImageUploader @upload="(file) => (card.media.url = file.url)
          ">
                              <i class="fas fa-edit card-edit-btn absolute"></i>
                            </ImageUploader>
                            <a v-if="itemSelected.data.body.carousel.cards.length > 2" role="button"
                              class="text-white absolute float-right close-cards" @click="removeCard(n)">
                              <span class="material-icons edit_square_icon">
                                close
                              </span>
                            </a>
                          </div>
                          <div class="card-body">
                            <h4 class="card-title">
                              <input class="form-control" placeholder="Titulo do Card" v-model="card.title" />
                            </h4>
                            <p class="card-text">
                              <textarea class="form-control" placeholder="Descrição" v-model="card.text" />
                            </p>
                          </div>
                          <RcsButtons v-model="card.buttons" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-actions text-right">
                    <div class="row">
                      <div class="col-md-12">
                        <button style="margin-top: 8px;" class="btn btn-primary btn-rounded" @click="addCard"
                          :disabled="itemSelected.data.body.carousel.cards.length > 9">
                          Adicionar Card<i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="itemSelected.name == 'catalog_whatsapp'">
              <div class="col-sm-12">
                <div class="new-template-whats m-top-10">
                  <div class="col-sm-12">
                    <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                      <h4>Header</h4>

                      <section class="relative">
                        <input class="form-control" maxlength="60" v-model="itemSelected.data.catalog.header_text" />
                      </section>
                    </div>
                  </div>
                </div>
                <div class="new-template-whats m-top-10" style="margin-top: 15px;">
                  <section class="m-top-10">
                    <h6 class="new-template-title">
                      <span>Body</span>
                    </h6>
                  </section>
                  <section class="relative">
                    <textarea class="form-control" maxlength="1024" v-model="itemSelected.data.catalog.body"
                      rows="5"></textarea>
                  </section>
                </div>
                <div class="new-template-whats m-top-10" style="margin-top: 15px;">
                  <section class="m-top-10">
                    <h6 class="new-template-title">
                      <span>Catálogo</span>
                    </h6>
                  </section>
                  <section class="relative">
                    <select class="form-control" @change="changeCatalog" v-model="itemSelected.data.catalog.catalog_id">
                      <option value>Escolha um catálogo</option>
                      <option v-for="catalog in newCatalogs" :key="catalog.id" :value="catalog.id">
                        {{ catalog.name }}
                      </option>
                    </select>
                  </section>
                </div>
                <div class="new-template-whats m-top-10" style="margin-top: 15px;">
                  <section class="m-top-10">
                    <h6 class="new-template-title">
                      <span>Footer</span>
                    </h6>
                    <p style="display: block; margin-top: 8px;">
                      <span class="span-info">
                        Adicione uma pequena linha de texto na parte inferior da mensagem.
                      </span>
                    </p>
                  </section>
                  <section class="relative">
                    <input class="form-control" maxlength="60" v-model="itemSelected.data.catalog.footer" />
                  </section>
                </div>
                <div class="new-template-whats m-top-10" style="margin-top: 15px;"
                  v-if="itemSelected.data.catalog.type == 'menu'">
                  <section class="m-top-10">
                    <h6 class="new-template-title">
                      <span>Menu</span>
                    </h6>
                    <p style="display: block; margin-top: 8px;">
                    <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                      <h6>Catálogo</h6>
                      <div v-for="(session, i) in itemSelected.data.catalog.sections" :key="i"
                        class="flow-menu-session-list">
                        <div class="relative" style="width: 100%; margin-top: 5px; position: relative;">
                          <input class="form-control" v-model="session.title" maxlength="24" />
                          <span style="float: right; top: 6px; position: absolute; right: 10px;" class="c-pointer"
                            @click="removeCatalog(i)" v-if="i >= 1"><i class="fas fa-times"></i></span>
                        </div>
                        <hr>
                        <div class="flow-menu-session-list-sublist">
                          <h6>Produtos</h6>
                          <div v-for="(list, j) in session.productItems" :key="j" class="flow-menu-sub-session-list">
                            <div class="relative" style="width: 100%; margin-top: 5px; position: relative;">
                              <select class="form-control" v-model="list.product_retailer_id">
                                <option value>Escolha o produto</option>
                                <option v-for="product in catalogProducts" :key="product.retailer_id"
                                  :value="product.retailer_id">{{ product.name }}
                                </option>
                              </select>
                              <span style="float: right; top: 6px; position: absolute; right: 20px;" class="c-pointer"
                                @click="removeProd(session, j)"><i class="fas fa-times"></i></span>
                            </div>
                          </div>
                          <button class="btn btn-success btn-sm" style="margin-top:5px;" @click="addProduct(session)"><i
                              class="fas fa-plus"></i></button>
                        </div>
                      </div>
                      <div>
                        <hr>
                        <button class="btn btn-info btn-sm" @click="addSessionsCatalog"><i
                            class="fas fa-plus"></i></button>
                      </div>
                    </div>
                    </p>
                  </section>
                </div>
              </div>
            </div>
            <div class="row" v-if="itemSelected.data">
              <div class="col-sm-12" v-if="itemSelected.data.back == 'branch'">
                <div style="background: rgb(253, 240, 208) !important; padding: 20px; border-radius: 10px;">
                  <h6 style="color: rgb(168, 130, 37);"><i style="color: rgb(168, 130, 37);" class="fas fa-sitemap"></i>
                    Condição</h6>
                  <div style="border: 2px dashed #dfdfdf;border-radius: 10px !important; padding: 20px;">
                    <h4>Palavra chave</h4>
                    <p style="color: #b5b5b5;">Digite uma palavra chave esperada para direcionar
                      para esta ação.</p>
                    <input class="form-control" placeholder="" v-model="itemSelected.data.key" />
                    <hr>
                    <h4>Comparação</h4>
                    <p style="color: #b5b5b5;">Comparar o valor com as seguintes condições >, <,>=,
                        <= ou=</p>
                          <div>
                            <input class="form-control" placeholder="exemplo: >5"
                              v-model="itemSelected.data.comparator" />
                          </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="itemSelected.data && itemSelected.data.controller_id">
              <div class="col-sm-12 m-top-10">
                <hr>
                <p>Trabalhar com objeto corrente</p>
                <toggle-button v-model="itemSelected.data.current_controller" :sync="true"
                  :value="itemSelected.data.current_controller" />
                <hr>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 text-right">
                <br>
                <button class="btn btn-success" @click="updateAction()"
                  :class="{ 'qt-loader qt-loader-mini qt-loader-right': !fetchedAction }"
                  :disabled="!fetchedAction">Salvar
                </button>
              </div>
            </div>
            <br>
            <br>
            <br>
            <br>
            <br>
          </div>
        </div>
      </div>
      <div class="closePanel" @click="closeProperty = true">
        <div class="closePanel-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet">
            <path
              d="M9.01335 12L8.48302 11.4697C8.34237 11.6103 8.26335 11.8011 8.26335 12C8.26335 12.1989 8.34237 12.3897 8.48302 12.5303L9.01335 12ZM14.5437 7.53033C14.8366 7.23744 14.8366 6.76256 14.5437 6.46967C14.2508 6.17678 13.7759 6.17678 13.483 6.46967L14.5437 7.53033ZM13.483 17.5303C13.7759 17.8232 14.2508 17.8232 14.5437 17.5303C14.8366 17.2374 14.8366 16.7626 14.5437 16.4697L13.483 17.5303ZM9.54368 12.5303L14.5437 7.53033L13.483 6.46967L8.48302 11.4697L9.54368 12.5303ZM8.48302 12.5303L13.483 17.5303L14.5437 16.4697L9.54368 11.4697L8.48302 12.5303Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>
    </div>
    <div class="flowItemProperties" :class="{ 'property-closed': closeHistory }">
      <div style="overflow-y: scroll; height: 100%;">
        <div class="relative">
          <div class="flowItemProperties-header" style="padding-top: 40px;">
            <h3 class="text-center">Histórico</h3>
          </div>
          <div class="flowItemHistory-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="row m20" v-if="histories.length == 0">
                  <div class="col-sm-12 text-center">
                    <span class="material-symbols-outlined no-history">history</span>
                  </div>
                </div>
                <div class="row m20" style="margin-bottom: 0px !important;" v-for="(history, i) in histories" :key="i">
                  <div class="col-sm-12">
                    <div class="alert alert-info" role="alert" v-if="history.type == 'save'"
                      style="margin-bottom: 0px;">
                      <h5 class="alert-heading">{{ history.name }} salvou o flow</h5>
                      <p class="mb-0" style="font-size: 10px;">
                        {{ history.created_at | formatDateHour }}
                      </p>
                    </div>
                    <div class="alert alert-success" role="alert" v-if="history.type == 'publish'"
                      style="margin-bottom: 0px;">
                      <h5 class="alert-heading">{{ history.name }} publicou o flow</h5>
                      <p class="mb-0" style="font-size: 10px;">
                        {{ history.created_at | formatDateHour }}
                      </p>
                    </div>
                  </div>
                </div>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="closePanel" @click="closeHistory = true">
        <div class="closePanel-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet">
            <path
              d="M9.01335 12L8.48302 11.4697C8.34237 11.6103 8.26335 11.8011 8.26335 12C8.26335 12.1989 8.34237 12.3897 8.48302 12.5303L9.01335 12ZM14.5437 7.53033C14.8366 7.23744 14.8366 6.76256 14.5437 6.46967C14.2508 6.17678 13.7759 6.17678 13.483 6.46967L14.5437 7.53033ZM13.483 17.5303C13.7759 17.8232 14.2508 17.8232 14.5437 17.5303C14.8366 17.2374 14.8366 16.7626 14.5437 16.4697L13.483 17.5303ZM9.54368 12.5303L14.5437 7.53033L13.483 6.46967L8.48302 11.4697L9.54368 12.5303ZM8.48302 12.5303L13.483 17.5303L14.5437 16.4697L9.54368 11.4697L8.48302 12.5303Z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>
    </div>
    <div ref="drawFlowArea" id="drawFlowArea" @dragover.prevent @drop.prevent="drop"
      @connectionCreated="connectionCreated">
      <div class="version-box">Versão 1.0</div>
    </div>
    <div class="box-tools-flow">
      <div class="btn-flow-tools btn-flow-zoons" v-tooltip.left="'Zoom out'">
        <i class="fas fa-search-minus" @click="editor.zoom_out()"></i>
      </div>
      <div class="btn-flow-tools btn-flow-zoons" v-tooltip.left="'Zoom in'">
        <i class="fas fa-search-plus" @click="editor.zoom_in()"></i>
      </div>
      <div class="btn-flow-tools btn-flow-zoons" v-tooltip.left="'Resertar'">
        <i class="fas fa-search" @click="editor.zoom_reset()"></i>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
// @ is an alias to /src
import DrawflowStyle from '@/plugins/drawflow/drawflow.css';
import axios from '@/plugins/axios';
import { lte } from '../../../public/assets/vendor/lodash/lang';
import Drawflow from '@/plugins/drawflow/old';
import { Array } from 'engine.io-client/lib/globalThis';
import Swal from 'sweetalert2';
import WhatsAppService from '@/services/whatsapp.service';
import TemplateInput from '@/components/whatsapp/TemplateInput.vue';

const webApi = axios();
import { ToggleButton } from 'vue-js-toggle-button';
import ax from 'axios';
import Loader from '@/plugins/loader';
import Dropdown from '@/components/Dropdown.vue';
import VJsoneditor from 'v-jsoneditor/src/index';
import VueTagsInput from '@johmun/vue-tags-input';
import RcsButtons from '@/components/rcs/RcsButtons.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import store from '@/store';

export default {
  name: 'FlowCreateEdit',
  components: {
    TemplateInput,
    ToggleButton,
    Dropdown,
    VJsoneditor,
    VueTagsInput,
    RcsButtons,
    ImageUploader,
  },
  data() {
    return {
      tags: [],
      isInstagramChannel: false,
      isWhatsAppChannel: false,
      isTelegramChannel: false,
      isMessengerChannel: false,
      isWidgetChannel: false,
      isRcsChannel: false,
      options: {
        mode: 'text',
        language: 'pt-BR',
        mainMenuBar: false,
      },
      templateLoading: false,
      libCheck: false,
      whatsIdLive: process.env.VUE_APP_WHATS_META,
      newTemplates: [],
      fileName: null,
      catalogProducts: [],
      templates: [],
      newCatalogs: [],
      fetched: false,
      previewId: '',
      selectedTemplate: '',
      editor: null,
      isSaving: false,
      drawFlowMobileItemSelec: null,
      drawFlowMobileLastMove: null,
      closeProperty: true,
      closeHistory: true,
      fetchedPublish: true,
      customInputs: [],
      catalogSelected: {},
      histories: [],
      flow: {
        delete_nodes: [],
        channels_template: '',
        tag_template: '',
      },
      isNodeModed: false,
      showEditName: false,
      counterInterval: {},
      itemSelected: {
        send_element: {},
      },
      itemSelectedIndex: 0,
      showAction: false,
      fetchedAction: true,
      textCopy: 'Copiar',
      file_upload: null,
      webHookInfo: {},
      template: {
        name: '',
        category: '',
        buttons: [
          { title: 'Botão 1' },
        ],
      },
      departments: [],
      form: { template: {} },
      formTemplates: {
        page: 1,
        limit: 400,
        sort: 'id',
      },
      defaultBg: '/assets/img/image_preview_white.png',
      defaultBgPdf: '/assets/img/image_preview_white.png',
      Toast: {},
      ToastError: {},
      nodesSave: null,
    };
  },
  created() {
    this.$root.$once('flow.update', this.updateCallBack);
    webApi.get(`/contacts/custom-field`).then((response) => {
      console.log('Contatos Campos');
      console.log(response);
      if (response.data.length > 0) {
        response.data.forEach((input) => {
          this.customInputs.push({ name: input.name, value: `yup_${input.name}_chat` })
        });
      }
    }, (error) => {
      this.content = error;
      this.fetched = true;
      this.$toast.show({
        title: 'Erro',
        content: 'Erro interno, por favor tente novamente mais tarde ou contact o suporte para ajuda.',
        type: 'error',
      });
    });
    this.counterInterval = setInterval(() => {
      if (this.flow) {
        this.autoSave();
      }
    }, 180000);
  },
  beforeDestroy() {
    this.$root.$off('flow.update', () => {
    });
    clearInterval(this.counterInterval);
  },
  mounted() {
    //Loader.showModal('Teste...');
    this.editor = new Drawflow(this.$refs.drawFlowArea);
    this.editor.start();
    this.editor.on('connectionCreated', (ev) => {
      this.connectionCreated(ev);
    });
    this.editor.on('nodeDataChanged', (ev) => {
      this.nodeDataChanged(ev);
    });
    this.editor.on('nodeMoved', (ev) => {
      this.nodeMoved(ev);
    });
    this.editor.on('nodeSelected', (ev) => {
      this.nodeSelected(ev);
    });
    this.editor.on('connectionRemoved', (ev) => {
      this.connectionRemoved(ev);
    });
    this.editor.on('nodeRemoved', (ev) => {
      this.nodeRemoved(ev);
    });
    this.editor.on('clickEnd', (ev) => {
      this.clickEnd(ev);
    });
    this.editor.on('nodeSimpleClick', (ev) => {
      this.nodeSimpleClick(ev);
    });
    this.init();
    this.fetchTemplates();
    this.getDepartment();
    this.Toast = Swal.mixin({
      toast: false,
      position: 'center',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
    this.ToastError = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
    if (
      this.$store.state.account.id === '52867698-ac7b-4186-a7e9-8525161c8b7f' ||
      this.$store.state.account.id === '29af08dd-1c1e-46e8-b19d-29ba400fed40'
    ) {
      this.libCheck = true;
    }
  },
  methods: {
    changeChannelTemplate(channel) {
      if (this.flow.channels_template == null) {
        this.flow.channels_template = '';
      }
      let contain = this.flow.channels_template.includes(`${channel},`);
      if (contain) {
        this.flow.channels_template.replace(`${channel},`, '');
      } else {
        this.flow.channels_template += `${channel},`;
      }
      switch (channel) {
        case 'instagram':
          this.isInstagramChannel = !this.isInstagramChannel;
          break;
        case 'whatsapp':
          this.isWhatsAppChannel = !this.isWhatsAppChannel;
          break;
        case 'telegram':
          this.isTelegramChannel = !this.isTelegramChannel;
          break;
        case 'messenger':
          this.isMessengerChannel = !this.isMessengerChannel;
          break;
        case 'widget':
          this.isWidgetChannel = !this.isWidgetChannel;
          break;
        case 'rcs':
          this.isRcsChannel = !this.isRcsChannel;
          break;
      }
    },
    updateCallBack(data) {
      this.fetchedPublish = true;
      switch (data.type) {
        case 'success':
          this.editor.import(JSON.parse(data.response.nodes));
          this.$toast.show({
            title: 'Flow',
            content: 'O flow foi publicado com sucesso!',
            type: 'success',
          });
          break;
        case 'error':
          this.$toast.show({
            title: 'Erro',
            content: 'Erro interno, por favor tente novamente mais tarde.',
            type: 'error',
          });
          break;
      }
    },
    fetchNewCatalog(page) {
      this.newCatalogs = [];
      WhatsAppService.getNewCatalog(this.flow.identification_name, this.flow.account_channel_external_id, {
        params: { page },
      }).then((response) => {
        console.log('New Catalog');
        console.log(response);
        if (response && response.data) {
          this.newCatalogs = response.data.data;
        }
      }, (error) => {
        this.content = error;
      }).finally(() => {
        this.fetchedItens = true;
      });
    },
    fetchNewTemplates(page) {
      this.newTemplates = [];
      WhatsAppService.getNewTemplates(this.flow.account_channel_external_id, {
        params: { page },
      }).then((response) => {
        console.log('New Templates');
        console.log(response.data);
        if (response && response.data && response.data.content) {
          // response.data.content.forEach((template) => {
          //   if (template.status !== 'DELETED') {
          //     this.templates.push(template);
          //   }
          // });
          this.newTemplates = response.data.content;
        }
      }, (error) => {
        this.content = error;
      }).finally(() => {
        this.fetchedItens = true;
      });
    },
    validate() {
      if (!this.editor.validate()) {
        // this.Toast.fire({
        //   icon: 'success',
        //   title: 'Flow validado',
        // });

        this.$toast.show({
          title: 'Flow',
          content: 'Flow validado',
          type: 'success',
        });
      } else {
        // this.ToastError.fire({
        //   type: 'danger',
        //   title: 'O flow está com erro',
        // });

        this.$toast.show({
          title: 'Flow',
          content: 'O flow está com erro',
          type: 'danger',
        });
      }
    },
    removeCard(i) {
      this.itemSelected.data.body.carousel.cards.splice(i, 1);
    },
    uploadFile(event) {
      const self = this;
      const reader = new FileReader();
      const file = event.target.files[0];

      reader.onload = () => {
        const jsonFile = reader.result;
        console.log(jsonFile);
        this.flow.importing = true;
        this.editor.import(JSON.parse(jsonFile));
      };
      reader.readAsText(file, 'UTF-8');
    },
    importFlow() {
      this.$refs.uploadedFile.click();
    },
    addCard() {
      this.itemSelected.data.body.carousel.cards.push({
        title: '',
        text: '',
        media: {
          url: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png'
        },
      });
      this.itemSelected.body.carousel.width = this.itemSelected.data.body.carousel.cards.length;
    },
    exportFlow() {
      const exportdata = this.editor.export();
      for (var node in exportdata.drawflow.Home.data) {
        exportdata.drawflow.Home.data[node].data.action_id = '';
        exportdata.drawflow.Home.data[node].data.back_id = '';
      }
      ;
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportdata));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', 'flow_yup.yflow');
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    fetchTemplates() {
      WhatsAppService.getTemplates(this.formTemplates).then(
        (response) => {
          this.loaded = true;
          if (response && response.data && response.data.data && response.data.data.waba_templates) {
            this.templates = response.data.data.waba_templates;
          }
        },
        (error) => {
          this.content = error;
        },
      );
    },
    checkTemplate(name) {
      if (this.flow.channel_id !== this.whatsIdLive) {
        this.templates.forEach((element) => {
          if (element.name === name) {
            this.insertTemplate(JSON.stringify(element));
          }
        });
      } else {
        this.newTemplates.forEach((element) => {
          if (element.name === name) {
            this.insertNewTemplate(JSON.stringify(element));
          }
        });
      }
    },
    getDepartment() {
      webApi.get(`flows/departments`).then((response) => {
        console.log('Departamentos');
        console.log(response);
        this.departments = [];
        response.data.forEach((department) => {
          if (department.deleted === 'false') {
            if (!department.generic) {
              this.departments.push({
                id: department.name,
                name: department.name,
              });
            } else {
              this.departments.push({
                id: department.id,
                name: 'Geral'
              });
            }
          }
        });
      }, (error) => {
        this.content = error;
        this.fetched = true;
        this.$toast.show({
          title: 'Erro',
          content: 'Erro interno, por favor tente novamente mais tarde ou contact o suporte para ajuda.',
          type: 'error',
        });
      });
    },
    changeTemplate(e) {
      this.insertTemplate(e.target.value);
    },
    changeNewTemplate(e) {
      this.insertNewTemplate(e.target.value);
    },
    changeCatalog(e) {
      this.itemSelected.data.catalog.catalog_id = e.target.value;
      WhatsAppService.getNewCatalogProduct(this.flow.identification_name, e.target.value, {
        params: { page: 1 },
      }).then((response) => {
        console.log('New Products');
        console.log(response);
        if (response.data) {
          this.catalogProducts = response.data.data;
        }
      }, (error) => {
        this.content = error;
      }).finally(() => {
        this.fetchedItens = true;
      });
    },
    insertNewTemplate(param) {
      this.selectedTemplate = param;
      const template = JSON.parse(param);
      console.log('Template');
      console.log(template);
      this.form.template = {};
      this.form.template.name = template.name;
      this.itemSelected.data.template.name = template.name;
      this.itemSelected.data.template.language_code = template.language;
      this.form.template.type = 'text';
      if (template.body) {
        this.form.template.body = template.body.text;
        this.form.body = template.body.text;
      }
      if (template.header) {
        this.form.template.header = template.header;
        this.form.template.type = 'rich';
        if (template.header.type === 'IMAGE') {
          this.form.template.type = 'image';
          this.itemSelected.data.template.type = 'image';
        }
        if (template.header.type === 'VIDEO') {
          this.form.template.type = 'video';
          this.itemSelected.data.template.type = 'video';
        }
        if (template.header.type === 'DOCUMENT') {
          this.form.template.type = 'document';
          this.itemSelected.data.template.type = 'document';
        }
      }
      if (template.footer) {
        this.form.template.footer = template.footer;
      }
      /* if (element.type === 'BUTTONS') {
        this.form.template.buttons = element.buttons;
        this.form.template.type = 'rich';
      }*/
      console.log(this.itemSelected.data.template);
      const countVar = (this.form.template.body.match(/{{(.*?)}}/g) || []).length;
      this.form.template.params = [];

      for (let i = 0; i < countVar; i += 1) {
        this.form.template.params.push({
          index: i + 1,
          text: '',
        });
      }
      if (this.form.template.params.length == 0) {
        if (this.itemSelected.data.template) {
          if (this.itemSelected.data.template.params && this.itemSelected.data.template.params.length > 0) {
            this.itemSelected.data.template.params = []
          }
        }
      }
      if (this.itemSelected.data.template) {
        if (this.itemSelected.data.template.params && this.itemSelected.data.template.params.length > 0) {
          this.form.template.params = this.itemSelected.data.template.params;
        }
      }
      this.$forceUpdate();
    },
    insertTemplate(param) {
      this.selectedTemplate = param;
      console.log('Template');
      console.log(param);
      const template = JSON.parse(param);
      this.form.template = {};
      this.form.template.name = template.name;
      this.form.template.language = template.language;
      this.itemSelected.data.template.name = template.name;
      this.itemSelected.data.template.language_code = template.language;

      template.components.forEach((element) => {
        console.log(element);
        this.form.template.type = 'text';
        if (element.type === 'BODY') {
          this.form.template.body = element.text;
          this.form.body = element.text;
        }
        if (element.type === 'HEADER') {
          this.form.template.header = element;
          this.form.template.type = 'rich';
          if (element.format === 'IMAGE') {
            this.form.template.type = 'image';
          }
          if (element.format === 'VIDEO') {
            this.form.template.type = 'video';
            this.itemSelected.data.template.type = 'video';
          }
          if (element.format === 'DOCUMENT') {
            this.form.template.type = 'document';
            this.itemSelected.data.template.type = 'document';
          }
        }
        if (element.type === 'FOOTER') {
          this.form.template.footer = element;
        }
        if (element.type === 'BUTTONS') {
          this.form.template.buttons = element.buttons;
        }
        // const count = (this.value.match(/{{(.*?)}}/g) || []).length;
      });

      const countVar = (this.form.template.body.match(/{{(.*?)}}/g) || []).length;
      this.form.template.params = [];

      for (let i = 0; i < countVar; i += 1) {
        this.form.template.params.push({
          index: i + 1,
          text: '',
        });
      }
      if (this.form.template.params.length == 0) {
        if (this.itemSelected.data.template) {
          if (this.itemSelected.data.template.params && this.itemSelected.data.template.params.length > 0) {
            this.itemSelected.data.template.params = []
          }
        }
      }
      if (this.itemSelected.data.template) {
        if (this.itemSelected.data.template.params && this.itemSelected.data.template.params.length > 0) {
          this.form.template.params = this.itemSelected.data.template.params;
        }
      }
    },
    removeButtons(i) {
      this.itemSelected.data.template.buttons.splice(i, 1);
    },
    removeSession(i) {
      this.itemSelected.data.template.sections.splice(i, 1);
    },
    removeCatalog(i) {
      this.itemSelected.data.catalog.sections.splice(i, 1);
    },
    addButtons() {
      if (this.itemSelected.data.template.buttons.length < 3) {
        this.itemSelected.data.template.buttons.push(
          { title: '', button_id: this.itemSelected.data.template.buttons.length + 1 },
        );
      }
    },
    addSessions() {
      if (this.itemSelected.data.template.sections.length < 10) {
        this.itemSelected.data.template.sections.push(
          {
            title: 'Título da sessão',
            list_config_id: this.itemSelected.data.template.sections.length + 1,
            sectionList: []
          },
        );
      }
    },
    addSessionsCatalog() {
      if (this.itemSelected.data.catalog.sections.length < 10) {
        this.itemSelected.data.catalog.sections.push(
          { title: 'Título da sessão', productItems: [] },
        );
      }
    },
    removeBody(i) {
      this.itemSelected.data.parameters_body.splice(i, 1);
    },
    addBody() {
      this.itemSelected.data.parameters_body.push(
        { name: '', type: 'TEXT', value: '' },
      );
    },
    removePath(i) {
      this.itemSelected.data.parameters_path.splice(i, 1);
    },
    removeHeader(i) {
      this.itemSelected.data.parameters_header.splice(i, 1);
    },
    addPath() {
      this.itemSelected.data.parameters_path.push(
        { name: '', value: '' },
      );
    },
    addHeader() {
      this.itemSelected.data.parameters_header.push(
        { name: '', value: '' },
      );
    },
    addList(section) {
      let id = 1;
      section.sectionList.forEach((item) => {
        id = item.list_id + 1;
      });
      if (section.sectionList.length < 10) {
        section.sectionList.push(
          {
            description: 'Descrição',
            title: 'Nome da opção',
            list_id: id + 1,
            section_list_id: ''
          },
        );
      }
    },
    addProduct(section) {
      if (section.productItems.length < 30) {
        section.productItems.push(
          { product_retailer_id: '' },
        );
      }
    },
    removeList(section, i) {
      section.sectionList.splice(i, 1);
    },
    removeProd(section, i) {
      section.productItems.splice(i, 1);
    },
    changeCategory(category) {
      this.template.category = category;
    },
    copy(data) {
      this.$toast.show({
        title: 'Sucesso',
        content: 'Copiado',
        type: 'success',
      });
      this.$clipboard(data);
    },
    nodeSimpleClick(id) {
      console.log('Click simples', id);
      this.itemSelectedIndex = id;
      const nodes = this.editor.export();
      if (nodes.drawflow.Home.data[id].name !== 'next_additional') {
        this.closeProperty = false;
      }
      if (nodes.drawflow.Home.data[id].name !== 'flow_end') {
        this.itemSelected = nodes.drawflow.Home.data[id];
      }
      if (nodes.drawflow.Home.data[id].name === 'loop_flow') {
        this.itemSelected.send_element = nodes.drawflow.Home.data[this.itemSelected.data.send_action_id];
      }
    },
    nodeMoved(id) {
      console.log(id);
      this.isNodeModed = true;
    },
    clickEnd(id) {
      console.log(id);
      // this.closeProperty = false;
    },
    nodeSelected(id) {
      console.log('Node id', id);
      this.itemSelectedIndex = id;
      this.fileName = null;
      this.file_upload = null;
      if (id > 1) {
        const nodes = this.editor.export();
        if (nodes.drawflow.Home.data[id].name !== 'flow_end') {
          this.itemSelected = nodes.drawflow.Home.data[id];
          console.log(this.itemSelected.data);
          if (this.itemSelected !== null && this.itemSelected.data !== null) {
            if (this.itemSelected.data.template !== undefined) {
              if (this.itemSelected.data.template.name !== null) {
                this.checkTemplate(this.itemSelected.data.template.name);
              }
            }
          }
        }
        if (nodes.drawflow.Home.data[id].name === 'loop_flow') {
          this.itemSelected.send_element = nodes.drawflow.Home.data[this.itemSelected.data.send_action_id];
        }
        if (nodes.drawflow.Home.data[id].name === 'catalog_whatsapp') {
          WhatsAppService.getNewCatalogProduct(this.flow.identification_name, nodes.drawflow.Home.data[id].data.catalog.catalog_id, {
            params: { page: 1 },
          }).then((response) => {
            if (response.data) {
              this.catalogProducts = response.data.data;
            }
          }, (error) => {
            this.content = error;
          }).finally(() => {
            this.fetchedItens = true;
          });
        }
      }
    },
    nodeDataChanged(ev) {
      console.log(ev);
    },
    nodeRemoved(id) {
      console.log(id);
      const nodes = this.editor.export();
      //this.flow.delete_nodes.push(nodes.drawflow.Home.data[id]);
    },
    connectionRemoved(ev) {
      console.log(ev);
    },
    connectionCreated(ev) {
      console.log('Evento: ', ev);
      const exportdata = this.editor.export();
      //const nodes = Object.keys(exportdata.drawflow.Home.data).map((key) => exportdata.drawflow.Home.data[key]);
      let backNode = {};
      let currentNode = {};
      //console.log(nodes);
      for (var node in exportdata.drawflow.Home.data) {
        console.log(exportdata.drawflow.Home.data[node]);
        if (exportdata.drawflow.Home.data[node].id == ev.output_id) {
          backNode = exportdata.drawflow.Home.data[node];
        }
        if (exportdata.drawflow.Home.data[node].id == ev.input_id) {
          currentNode = exportdata.drawflow.Home.data[node];
        }
      }
      ;

      currentNode.data.back_id = backNode.data.action_id;
      currentNode.data.back_name = backNode.data.action_name;
      currentNode.data.back_node_id = backNode.id;

      exportdata.drawflow.Home.data[currentNode.id] = currentNode;

      if (currentNode.data.back_node_id == 1) {
        for (var node in exportdata.drawflow.Home.data) {
          exportdata.drawflow.Home.data[node].data.first = false;
        }
        ;
        currentNode.data.first = true;
        //exportdata.drawflow.Home.data = nodes;
      }
      if (backNode.name == 'branch') {
        currentNode.data.back = 'branch';
        exportdata.drawflow.Home.data[ev.input_id] = currentNode;
      }
      if (backNode.name == 'consumir_api') {
        if (ev.output_class == 'output_2') {
          console.log('currentNode', currentNode);
          exportdata.drawflow.Home.data[ev.output_id].data.failed_action_node_id = currentNode.id;
        }
      }

      if (backNode.data.controller_id) {
        currentNode.data.controller_id = backNode.data.controller_id;
        exportdata.drawflow.Home.data[currentNode.id] = currentNode;
      }

      switch (backNode.name) {
        case 'loop_flow':
          if (ev.output_class == 'output_1') {
            backNode.data.send_action_id = currentNode.id;
            currentNode.data.controller_id = backNode.id;

            exportdata.drawflow.Home.data[currentNode.id] = currentNode;
            exportdata.drawflow.Home.data[backNode.id] = backNode;
          }
          break;
        case 'branch':
          if (ev.output_class == 'output_2') {
            backNode.data.otherwise_id = currentNode.id;
            backNode.data.otherwise = currentNode.data.action_id;
            backNode.data.otherwise_action_name = currentNode.data.action_name;

            exportdata.drawflow.Home.data[backNode.id] = backNode;
          }
          break;
        default:
          break;
      }
      switch (currentNode.name) {
        case 'branch':
          if (backNode.name === 'response_expecter') {
            currentNode.data.attribute = backNode.data.variable_name;
            exportdata.drawflow.Home.data[ev.input_id] = currentNode;
          }
          break;
        default:
          break;
      }
      this.editor.import(exportdata);
    },
    updateAction() {
      this.fetchedAction = false;
      if (this.itemSelected.name === 'template_whatsapp') {
        this.itemSelected.data.template.params = this.form.template.params;
        if (this.file_upload) {
          const formData = new FormData();
          formData.append('flow_id', this.flow.id);
          formData.append('file', this.file_upload);

          webApi.post('flow/upload', formData).then((response) => {
            console.log('Upload');
            console.log(response);
            this.fetchedAction = true;
            this.itemSelected.data.template.header_link = response.data.url;

            const nodes = this.editor.export();
            nodes.drawflow.Home.data[this.itemSelectedIndex] = this.itemSelected;
            console.log(this.itemSelected);
            this.editor.import(nodes);
            this.fetchedAction = true;
            this.$toast.show({
              title: 'Sucesso',
              content: 'Node atualizado',
              type: 'success',
            });
          }, (error) => {
            console.log('Erro upload')
            this.content = error;
            this.fetchedAction = true;
            this.$toast.show({
              title: 'Sucesso',
              content: 'Erro ao fazer upload. Por favor, verifique o tamanho do arquivo',
              type: 'error',
            });
          }, 1000);
        } else {
          const nodes = this.editor.export();
          nodes.drawflow.Home.data[this.itemSelectedIndex] = this.itemSelected;
          console.log(this.itemSelected);
          this.editor.import(nodes);
          this.fetchedAction = true;
          this.$toast.show({
            title: 'Sucesso',
            content: 'Node atualizado',
            type: 'success',
          });
        }
      }
      if (this.itemSelected.name === 'interactive_whatsapp') {
        if (this.file_upload) {
          const formData = new FormData();
          formData.append('flow_id', this.flow.id);
          formData.append('file', this.file_upload);

          webApi.post('flow/upload', formData).then((response) => {
            console.log('Upload');
            console.log(response);
            this.fetchedAction = true;
            this.itemSelected.data.template.header.filename = this.fileName;
            this.itemSelected.data.template.header.link = response.data.url;

            const nodes = this.editor.export();
            nodes.drawflow.Home.data[this.itemSelectedIndex] = this.itemSelected;
            console.log(this.itemSelected);
            this.editor.import(nodes);
          }, (error) => {
            this.content = error;
            this.fetchedAction = true;
          });
        } else {
          const nodes = this.editor.export();
          nodes.drawflow.Home.data[this.itemSelectedIndex] = this.itemSelected;
          console.log(this.itemSelected);
          this.editor.import(nodes);
          this.fetchedAction = true;
          this.$toast.show({
            title: 'Sucesso',
            content: 'Node atualizado',
            type: 'success',
          });
        }
      }
      if (this.itemSelected.name === 'send_file') {
        if (this.file_upload) {
          const formData = new FormData();
          formData.append('flow_id', this.flow.id);
          formData.append('file', this.file_upload);

          webApi.post('flow/upload', formData).then((response) => {
            console.log('Upload');
            console.log(response);
            this.fetchedAction = true;
            this.itemSelected.data.type = response.data.type;
            this.itemSelected.data.mime_type = response.data.mime_type;
            this.itemSelected.data.file_size = response.data.size;
            this.itemSelected.data.url = response.data.url;

            const nodes = this.editor.export();
            nodes.drawflow.Home.data[this.itemSelectedIndex] = this.itemSelected;
            console.log(this.itemSelected);
            this.editor.import(nodes);
            this.$toast.show({
              title: 'Sucesso',
              content: 'Node atualizado',
              type: 'success',
            });
          }, (error) => {
            this.$toast.show({
              title: 'Erro',
              content: 'Node com erro',
              type: 'error',
            });
            this.content = error;
            this.fetchedAction = true;
          });
        } else {
          const nodes = this.editor.export();
          nodes.drawflow.Home.data[this.itemSelectedIndex] = this.itemSelected;
          console.log(this.itemSelected);
          this.editor.import(nodes);
          this.fetchedAction = true;
          this.$toast.show({
            title: 'Sucesso',
            content: 'Node atualizado',
            type: 'success',
          });
        }
      } else {
        const nodes = this.editor.export();
        nodes.drawflow.Home.data[this.itemSelectedIndex] = this.itemSelected;
        this.editor.import(nodes);
        this.fetchedAction = true;
        this.$toast.show({
          title: 'Sucesso',
          content: 'Node atualizado',
          type: 'success',
        });
      }
    },
    startFlow(node) {
      const dataToImport = this.newFake();
      this.editor.import(node);
      setTimeout(() => {
        document.querySelectorAll('.drawflow-node').forEach((drawflow) => {
          const item = document.createElement('div');
          item.className = 'drawflow-delete';
          item.innerHTML = 'x';
          // drawflow.appendChild(item);
        });
      }, 1000);
      setInterval(() => {
        // this.saveFlow();
      }, 60000);
      this.$forceUpdate();
    },
    init() {
      webApi.get(`flow/${this.$route.params.id}`, {}).then((response) => {
        console.log('Flow');
        this.flow = response.data.flow;
        console.log(this.flow);
        this.webHookInfo = response.data.webhook;
        this.fetched = true;
        this.startFlow(JSON.parse(response.data.flow.nodes_draft));
        this.fetchNewTemplates(1);
        this.fetchNewCatalog(1);
      }, (error) => {
        this.content = error;
      });
    },
    changeStatus(status) {
      console.log(status);
    },
    save() {
      console.log('Salvar!');
    },
    // remover
    newFake() {
      return {
        drawflow: {
          Home: {
            data: {
              1: {
                id: 1,
                name: 'welcome',
                data: {},
                class: 'welcome',
                html: '\n<div>\n<div class="title-box"><i class="fas fa-wrench"></i> Webhook</div>\n<div class="box">\n<p><h3>Passo inicial</h3> <b></b>\n<br> O fluxo começa por aqui. Escolha ao lado os próximos passos.</p>\n</div>\n</div>\n',
                typenode: false,
                inputs: {},
                pos_x: 50,
                pos_y: 50,
                outputs: {
                  output_1: {
                    connections: [
                      {
                        node: 2,
                        output: 'input_1',
                      },
                    ],
                  },
                },
              },
              2: {
                id: 2,
                name: 'simple_message',
                data: {
                  text: 'Seja bem vindo, como podemos te ajudar?',
                },
                class: 'simple_message',
                html: '\n<div>\n<div class="title-box"><i class="fas fa-comment"></i> Enviar mensagem</div>\n<div class="box">\n<textarea df-text class="form-control"></textarea>\n</div>\n</div>\n',
                typenode: false,
                inputs: {
                  input_1: {
                    connections: [
                      {
                        node: 1,
                        input: 'output_1',
                      },
                    ],
                  },
                },
                outputs: {
                  output_1: {
                    connections: [],
                  },
                },
                pos_x: 424,
                pos_y: 120,
              },
            },
          },
          Other: {
            data: {},
          },
        },
      };
    },
    fakeData() {
      return {
        drawflow: {
          Home: {
            data: {
              1: {
                id: 1,
                name: 'welcome',
                data: {},
                class: 'welcome',
                html: '\n    <div>\n      <div class="title-box"><i class="fas fa-wrench"></i> Webhook</div>\n      <div class="box">\n        <p><h3>Passo inicial</h3> <b></b>\n<br> O fluxo começa por aqui. Escolha ao lado os próximos passos.</p>\n      </div>\n    </div>\n    ',
                typenode: false,
                inputs: {},
                pos_x: 50,
                pos_y: 50,
                outputs: {
                  output_1: {
                    connections: [],
                  },
                },
              },
            },
          },
          Other: {
            data: {},
          },
        },
      };
    },
    // remover
    drag(ev) {
      console.log(ev.type);
      if (ev.type === 'touchstart') {
        this.drawFlowMobileItemSelec = ev.target.closest('.drag-drawflow').getAttribute('data-node');
      } else {
        ev.dataTransfer.setData('node', ev.target.getAttribute('data-node'));
      }
    },
    drop(ev) {
      if (ev.type === 'touchend') {
        const isDrawFlowArea = document.elementFromPoint(this.drawFlowMobileLastMove.touches[0].clientX, this.drawFlowMobileLastMove.touches[0].clientY).closest('#drawFlowArea');

        if (isDrawFlowArea !== null) {
          this.addNodeToDrawFlow(this.drawFlowMobileItemSelec, this.drawFlowMobileLastMove.touches[0].clientX, this.drawFlowMobileLastMove.touches[0].clientY);
        }

        this.drawFlowMobileItemSelec = '';
      } else {
        this.addNodeToDrawFlow(ev.dataTransfer.getData('node'), ev.clientX, ev.clientY);
      }
    },
    openFileImageUpload(previewId) {
      this.previewId = previewId;
      this.$refs.fileUploadImage.click();
    },
    onUploadFileImage(event) {
      const preview = document.getElementById(this.previewId);
      const file = event.target.files[0];
      this.file_upload = file;
      const reader = new FileReader();
      this.fileName = file.name;

      switch (this.itemSelected.data.type) {
        case 'image':
          reader.addEventListener('load', () => {
            preview.style = `background: url(${reader.result}); background-size: cover;`;
          }, false);
          break;
        case 'video':
          this.$refs.previewMp4.src = URL.createObjectURL(file);
          this.$refs.previewMp4.play();
          break;
        default:
          break;
      }
      if (this.itemSelected.name === 'interactive_whatsapp') {
        switch (this.itemSelected.data.template.header.type) {
          case 'IMAGE':
            reader.addEventListener('load', () => {
              preview.style = `background: url(${reader.result}); background-size: cover;`;
            }, false);
            break;
          case 'VIDEO':
            this.$refs.previewMp4.src = URL.createObjectURL(file);
            this.$refs.previewMp4.play();
            break;
          default:
            break;
        }
      }
      if (this.itemSelected.name === 'template_whatsapp') {
        switch (this.itemSelected.data.template.type) {
          case 'image':
            reader.addEventListener('load', () => {
              preview.style = `background: url(${reader.result}); background-size: cover;`;
            }, false);
            break;
          case 'video':
            this.$refs.previewTempMp4.src = URL.createObjectURL(file);
            this.$refs.previewTempMp4.play();
            break;
          default:
            break;
        }
      }
      reader.readAsDataURL(file);
    },
    publishFlow() {
      Swal.fire({
        title: 'Tem certeza?',
        text: 'Tem certeza que deseja publicar esse flow? As alterações irão ser afetadas.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          //Check nodes desconected
          let thatRoot = this.$root;
          let thatFetchedPublish = this.fetchedPublish;

          const exportdata = this.editor.export();
          for (var i in exportdata.drawflow.Home.data) {
            let node = exportdata.drawflow.Home.data[i];
            console.log(node.id, node);
            if (node.inputs.input_1) {
              if (node.inputs.input_1.connections[0]) {
                if (node.inputs.input_1.connections[0].node !== node.data.back_node_id) {
                  exportdata.drawflow.Home.data[node.id].data.back_node_id = node.inputs.input_1.connections[0].node;
                  console.log(node.inputs.input_1.connections[0].node);
                }
              }
            }
          }
          ;
          this.editor.import(exportdata);
          console.log(exportdata);
          if (!this.editor.validate()) {
            this.fetchedPublish = false;
            this.showEditName = false;
            this.flow.active = true;
            this.flow.draft = false;
            const CancelToken = ax.CancelToken;
            const source = CancelToken.source();
            setTimeout(() => {
              source.cancel();
            }, 1200000);
            webApi.defaults.timeout = 200000;
            let body = {
              nodes_draft: JSON.stringify(exportdata),
              busy_message: this.flow.busy_message,
              description: this.flow.description,
              type_trigger: this.flow.type_trigger,
              name: this.flow.name,
              nodes: this.flow.nodes,
              draft: false,
              active: true,
              id: `${this.flow.id}`,
              template: false,
              importing: this.flow.importing,
            };
            this.editor.publishFlow(body, (draftNodes, error) => {
              console.log(draftNodes);
              Loader.showLoadingToast('Publicando...');
              if (error == undefined) {
                this.flow.importing = false;
                webApi.put(`flow/publish/${this.flow.id}`, {
                  nodes: JSON.stringify(draftNodes),
                  busy_message: this.flow.busy_message,
                  description: this.flow.description,
                  type_trigger: this.flow.type_trigger,
                  name: this.flow.name,
                  draft: false,
                  template: false,
                  active: true,
                  importing: false,
                }, { cancelToken: source.token, timeout: 200000 }).then((response) => {
                  console.log('Publish Flow');
                  console.log(response);
                  this.fetchedPublish = true;
                  this.editor.import(JSON.parse(response.data.nodes));
                  Loader.hideDialog();
                  this.Toast.fire({
                    icon: 'success',
                    title: 'O flow foi publicado com sucesso!',
                  });
                }, (error) => {
                  this.content = error;
                  this.fetchedPublish = true;
                  this.$toast.show({
                    title: 'Erro',
                    content: 'Erro interno, por favor tente novamente mais tarde.',
                    type: 'danger',
                  });
                  Loader.hideDialog();
                });
              } else {
                this.$toast.show({
                  title: 'Erro',
                  content: 'Erro ao tentar publicar flow',
                  type: 'danger',
                });
                Loader.hideDialog();
              }
            });
          } else {
            this.$toast.show({
              title: 'Flow',
              content: 'O flow está com erro',
              type: 'danger',
            });
            Loader.hideDialog();
          }
        }
      });
    },
    changeCustomType(how) {
      switch (how) {
        case 'custom':
          if (this.itemSelected.data.save_field_custom) {
            this.itemSelected.data.save_field = false;
          }
          break;
        case 'select':
          if (this.itemSelected.data.save_field) {
            this.itemSelected.data.save_field_custom = false;
          }
          break;
        default:
          break;
      }
      this.$forceUpdate();
    },
    autoSave() {
      store.dispatch('auth/refresh').then((foo) => {
        store.dispatch('account/info').then((info) => {
        });
      }, () => {
        this.$router.push('/login');
      });
      const exportdata = this.editor.export();
      let nodeSave = JSON.stringify(exportdata);
      if (nodeSave != this.nodesSave) {
        this.saveFlow();
      }
    },
    saveFlow() {
      Loader.showLoadingToast('Salvando...');
      const exportdata = this.editor.export();
      console.log(exportdata);

      this.fetched = false;
      this.showEditName = false;
      this.flow.draft = true;
      /*console.log(exportdata.drawflow.Home.data[1]);
      let node = exportdata.drawflow.Home.data[1];
      this.editor.backToNode(node.pos_x, node.pos_y);*/

      webApi.put(`flow/${this.flow.id}`, {
        nodes_draft: JSON.stringify(exportdata),
        busy_message: this.flow.busy_message,
        description: this.flow.description,
        type_trigger: this.flow.type_trigger,
        name: this.flow.name,
        active: this.flow.active,
        draft: true,
        save_history: true,
        importing: this.flow.importing,
        expirytime: 1440,
      }).then((response) => {
        console.log('UpdatedFlow');
        console.log(response);
        this.fetched = true;
        this.nodesSave = JSON.stringify(exportdata);
        Loader.hideDialog();
        this.$toast.show({
          title: 'Flow',
          content: 'Salvo com sucesso!',
          type: 'success',
        });
      }, (error) => {
        this.content = error;
        this.fetched = true;
        Loader.hideDialog();
      });
    },
    saveTemplate() {
      Loader.showLoadingToast('Salvando...');
      const exportdata = this.editor.export();
      console.log(exportdata);

      this.fetched = false;
      this.showEditName = false;
      this.flow.draft = true;
      /*console.log(exportdata.drawflow.Home.data[1]);
      let node = exportdata.drawflow.Home.data[1];
      this.editor.backToNode(node.pos_x, node.pos_y);*/

      if (this.tags.length > 0) {
        const toArray = [];
        this.tags.forEach((element) => {
          toArray.push(element.text);
        });
        this.flow.tag_template = toArray.join(', ');
      }

      webApi.put(`flow/${this.flow.id}`, {
        nodes_draft: JSON.stringify(exportdata),
        busy_message: this.flow.busy_message,
        description: this.flow.description,
        type_trigger: this.flow.type_trigger,
        name: this.flow.name,
        channels_template: this.flow.channels_template,
        active: false,
        template: true,
        tag_template: this.flow.tag_template,
        video_link: this.flow.video_link,
        draft: true,
      }).then((response) => {
        console.log('UpdatedFlow Template');
        console.log(response);
        this.fetched = true;
        this.nodesSave = JSON.stringify(exportdata);
        this.$toast.show({
          title: 'Flow',
          content: 'O template foi salvo com sucesso!',
          type: 'success',
        });
        Loader.hideDialog();
      }, (error) => {
        this.content = error;
        this.fetched = true;
        Loader.hideDialog();
      });
    },
    addNodeToDrawFlow(name, posX, posY) {
      const editor = this.editor;

      if (editor.editor_mode === 'fixed') return;

      posX = posX * (editor.precanvas.clientWidth / (editor.precanvas.clientWidth * editor.zoom)) - (editor.precanvas.getBoundingClientRect().x * (editor.precanvas.clientWidth / (editor.precanvas.clientWidth * editor.zoom)));
      posY = posY * (editor.precanvas.clientHeight / (editor.precanvas.clientHeight * editor.zoom)) - (editor.precanvas.getBoundingClientRect().y * (editor.precanvas.clientHeight / (editor.precanvas.clientHeight * editor.zoom)));

      switch (name) {
        case 'simple_message':
          const simpleMessage = `
            <div>
              <div class="title-box"><i class="fas fa-comment"></i> Enviar mensagem</div>
            </div>
            `;
          editor.addNode('simple_message', 1, 1, posX, posY, 'simple_message', {
            comparator: '',
            text: 'Seja bem vindo, como podemos te ajudar?',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            action_name: 'SendSimpleMessage',
            type_send: 'TEXT',
            file_send: { type: '', mime_type: '', url: '', file_size: '' },
            has_file: false
          }, simpleMessage);
          break;
        case 'consumir_error':
          const consumir_error = `
            <div>
              <div class="title-box"><i class="fas fa-info"></i> Falha na API</div>
            </div>
            `;
          editor.addNode('consumir_error', 1, 1, posX, posY, 'consumir_error', {
            comparator: '',
            text: '',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            action_name: 'SendSimpleMessage',
            type_send: 'TEXT',
            file_send: { type: '', mime_type: '', url: '', file_size: '' },
            has_file: false
          }, consumir_error);
          break;
        case 'contact_update':
          const contact_update = `
            <div>
              <div class="title-box"><i class="fas fa-user"></i> Atualizar Contato</div>
            </div>
            `;
          editor.addNode('contact_update', 1, 1, posX, posY, 'contact_update', {
            value: '',
            field: '',
            comparator: '',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            action_name: 'ContactUpdateFlow'
          }, contact_update);
          break;
        case 'add_variable':
          const add_variable = `
            <div>
              <div class="title-box"><i class="fas fa-database"></i> Variável</div>
            </div>
            `;
          editor.addNode('add_variable', 1, 1, posX, posY, 'add_variable', {
            value: '',
            name: '',
            calc: false,
            comparator: '',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            action_name: 'AddVariableFlow'
          }, add_variable);
          break;
        case 'loop_flow':
          const loop_flow = `
            <div>
              <div class="title-box"><i class="fas fa-undo"></i> Loop</div>
            </div>
            `;
          editor.addNode('loop_flow', 1, 2, posX, posY, 'loop_flow', {
            comparator: '',
            text: '',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            action_name: 'LoopFlow',
            current_controller: true,
            send_action: '',
            send_action_id: '',
            class_name_controller: '',
            controller: ''
          }, loop_flow);
          break;
        case 'next_additional':
          const next_additional = `
            <div>
              <div class="title-box"><i class="fas fa-undo"></i> Retornar flow</div>
            </div>
            `;
          editor.addNode('next_additional', 1, 1, posX, posY, 'next_additional', {
            comparator: '',
            text: '',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            action_name: 'NextAdditional'
          }, next_additional);
          break;
        case 'transfer_omni':
          const transfer_omni = `
            <div>
              <div class="title-box"><i class="fas fa-share"></i> Transferir Omni</div>
            </div>
            `;
          editor.addNode('transfer_omni', 1, 1, posX, posY, 'transfer_omni', {
            comparator: '',
            text: '',
            key: '',
            action_id: '',
            wait_finish: true,
            department: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            action_name: 'TransferOmni'
          }, transfer_omni);
          break;
        case 'consumir_api':
          const consumir_api = `
            <div>
              <div class="title-box"><i class="fas fa-wrench"></i> API</div>
            </div>
            `;
          editor.addNode('consumir_api', 1, 2, posX, posY, 'consumir_api', {
            comparator: '',
            text: '',
            key: '',
            action_id: '',
            wait_finish: true,
            auth: '',
            end_point: '',
            method: 'POST',
            parameters_body: [],
            parameters_path: [],
            parameters_header: [],
            back_node_id: '',
            failed_class_name: '',
            failed_action_node_id: '',
            failed_action_id: '',
            back_id: '',
            back_name: '',
            first: false,
            type_auth: 'Basic',
            action_name: 'ConsumerApi'
          }, consumir_api);
          break;
        case 'send_file':
          const send_file = `
            <div>
              <div class="title-box"><i class="fas fa-file"></i> Enviar arquivo</div>
            </div>
            `;
          editor.addNode('send_file', 1, 1, posX, posY, 'send_file', {
            comparator: '',
            text: '',
            key: '',
            action_id: '',
            wait_finish: true,
            back_node_id: '',
            back_id: '',
            back_name: '',
            mime_type: '',
            type: 'image',
            url: '',
            first: false,
            action_name: 'SendFileFlow'
          }, send_file);
          break;
        case 'interactive_whatsapp':
          const interactiveWhatsapp = `
            <div>
              <div class="title-box"><i class="fab fa-whatsapp"></i> Interação</div>
            </div>
            `;
          editor.addNode('interactive_whatsapp', 1, 1, posX, posY, 'interactive_whatsapp', {
            comparator: '',
            text: '',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            template: {
              type: 'bottons',
              body: '',
              button_text: 'Clique aqui',
              footer: '',
              header: {
                filename: null,
                header_id: null,
                link: null,
                provider_name: null,
                text: null,
                type: 'TEXT',
              },
              buttons: [
                {
                  button_config_id: '',
                  button_id: 1,
                  title: 'Texto Botão',
                },
              ],
              sections: [
                {
                  list_config_id: 1,
                  sectionList: [
                    {
                      description: 'Descrição',
                      list_id: 1,
                      section_list_id: '',
                      title: 'Título da lista',
                    },
                  ],
                  title: 'Título da sessão',
                },
              ],
            },
            action_name: 'SendInteractiveWhatsApp'
          }, interactiveWhatsapp);
          break;
        case 'rcs_card':
          const rcs_card = `
            <div>
              <div class="title-box" style="display: flex;flex-direction: row;"><span class="material-symbols-outlined" style="margin-top: 12px;margin-right: 6px;">pages</span> <span>Cartão</span></div>
            </div>
            `;
          editor.addNode('rcs_card', 1, 1, posX, posY, 'rcs_card', {
            comparator: '',
            text: '',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            type: 'card',
            body: {
              card: {
                title: 'Título',
                text: 'Texto',
                media: {
                  url: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
                },
                buttons: [],
              }
            },
            action_name: 'SendRCSMessage'
          }, rcs_card);
          break;
        case 'rcs_carousel':
          const rcs_carousel = `<div>
                <div class="title-box" style="display: flex;flex-direction: row;"><span class="material-symbols-outlined" style="margin-top: 12px;margin-right: 6px;">view_carousel</span> <span>Carrossel</span></div>
              </div>`;
          editor.addNode('rcs_carousel', 1, 1, posX, posY, 'rcs_carousel', {
            comparator: '',
            text: '',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            type: 'carousel',
            body: {
              carousel: {
                width: 'medium',
                cards: [
                  {
                    title: 'Título',
                    text: 'Texto',
                    media: {
                      url: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
                    },
                    buttons: [],
                  },
                  {
                    title: 'Título',
                    text: 'Texto',
                    media: {
                      url: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
                    },
                    buttons: [],
                  },
                ],
              },
            },
            action_name: 'SendRCSMessage'
          }, rcs_carousel);
          break;
        case 'template_whatsapp':
          const template_whatsapp = `
            <div>
              <div class="title-box"><i class="fab fa-whatsapp"></i> Template</div>
            </div>
            `;
          editor.addNode('template_whatsapp', 1, 1, posX, posY, 'template_whatsapp', {
            comparator: '',
            text: '',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            template: {
              type: 'bottons',
              name: '',
            },
            action_name: 'SendTemplateWhatsApp'
          }, template_whatsapp);
          break;
        case 'catalog_whatsapp':
          const catalogWhatsApp = `
            <div>
              <div class="title-box"><i class="fa fa-store"></i> Catálogo</div>
            </div>
            `;
          editor.addNode('catalog_whatsapp', 1, 1, posX, posY, 'catalog_whatsapp', {
            comparator: '',
            text: '',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            catalog: {
              type: 'menu',
              body: '',
              catalog_id: '',
              footer: '',
              text_header: '',
              sections: [
                {
                  productItems: [
                    {
                      product_retailer_id: '1',
                    },
                  ],
                  title: 'Título da sessão',
                },
              ],
            },
            action_name: 'SendCatalogWhatsApp'
          }, catalogWhatsApp);
          break;

        case 'response_expecter':
          const responseExpecter = `
            <div class="response_expecter-action">
              <div class="title-box"><i class="fas fa-edit"></i> Solicitar dado</div>
              <div class="box">
                <p>Solicite uma informação do usuário</p>
              </div>
            </div>
            `;
          editor.addNode('response_expecter', 1, 1, posX, posY, 'response_expecter', {
            comparator: '',
            variable_name: 'primeiro_nome',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            action_name: 'ResponseExpecter'
          }, responseExpecter);
          break;
        case 'flow_end':
          const flowEnd = `
            <div class="flow_end-action">
              <div class="title-box"><i class="fas fa-solid fa-check"></i> Finalizar fluxo</div>
            </div>
            `;
          editor.addNode('flow_end', 1, 0, posX, posY, 'flow_end', {
            comparator: '',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            action_name: 'FlowEnd'
          }, flowEnd);
          break;
        case 'branch':
          const branch = `
            <div class="branch-action">
              <div class="title-box"><i class="fas fa-sitemap"></i> Condição</div>
              <div class="box">
                <p>Se a informação digitada corresponde a...</p>
              </div>
              <div class="box hide">
                Tipo de palavra chave
                <select class="form-control" df-type-value type="select">
                  <option value="text">Texto</option>
                  <option value="number">Número</option>
                </select>
                Palavra chave
                <input type="text" df-key class="form-control" placeholder=""/>
              </div>
            </div>
            `;
          editor.addNode('branch', 1, 2, posX, posY, 'branch', {
            comparator: '',
            key: '',
            attribute: 'primeiro_nome',
            key: '',
            action_id: '',
            back_node_id: '',
            back_id: '',
            back_name: '',
            first: false,
            action_name: 'BranchFlow',
            otherwise: '',
            otherwise_action_name: ''
          }, branch);
          break;
        case 'facebook':
          const facebook = `
            <div>
              <div class="title-box"><i class="fab fa-facebook"></i> Facebook Message</div>
            </div>
            `;
          editor.addNode('facebook', 0, 1, posX, posY, 'facebook', {}, facebook);
          break;

        case 'slack':
          const slackchat = `
            <div>
              <div class="title-box"><i class="fab fa-slack"></i> Slack chat message</div>
            </div>`;
          editor.addNode('slack', 1, 0, posX, posY, 'slack', {}, slackchat);
          break;

        case 'github':
          const githubtemplate = `
            <div>
              <div class="title-box"><i class="fab fa-github "></i> Github Stars</div>
              <div class="box">
                <p>Enter repository url</p>
              <input type="text" df-name>
              </div>
            </div>`;
          editor.addNode('github', 0, 1, posX, posY, 'github', { name: '' }, githubtemplate);
          break;

        case 'telegram':
          const telegrambot = `
            <div>
              <div class="title-box"><i class="fab fa-telegram-plane"></i> Telegram bot</div>
              <div class="box">
                <p>Send to telegram</p>
                <p>select channel</p>
                <select df-channel>
                  <option value="channel_1">Channel 1</option>
                  <option value="channel_2">Channel 2</option>
                  <option value="channel_3">Channel 3</option>
                  <option value="channel_4">Channel 4</option>
                </select>
              </div>
            </div>
            `;
          editor.addNode('telegram', 1, 0, posX, posY, 'telegram', { channel: 'channel_3' }, telegrambot);
          break;

        case 'aws':
          const aws = `
            <div>
              <div class="title-box"><i class="fab fa-aws"></i> Aws Save </div>
              <div class="box">
                <p>Save in aws</p>
                <input type="text" df-db-dbname placeholder="DB name"><br><br>
                <input type="text" df-db-key placeholder="DB key">
                <p>Output Log</p>
              </div>
            </div>
            `;
          editor.addNode('aws', 1, 1, posX, posY, 'aws', { db: { dbname: '', key: '' } }, aws);
          break;

        case 'log':
          const log = `
            <div>
              <div class="title-box"><i class="fas fa-file-signature"></i> Save log file </div>
            </div>
            `;
          editor.addNode('log', 1, 0, posX, posY, 'log', {}, log);
          break;

        case 'google':
          const google = `
            <div>
              <div class="title-box"><i class="fab fa-google-drive"></i> Google Drive save </div>
            </div>
            `;
          editor.addNode('google', 1, 0, posX, posY, 'google', {}, google);
          break;

        case 'email':
          const email = `
            <div>
              <div class="title-box"><i class="fas fa-at"></i> Send Email </div>
            </div>
            `;
          editor.addNode('email', 1, 0, posX, posY, 'email', {}, email);
          break;

        case 'template':
          const template = `
            <div>
              <div class="title-box"><i class="fas fa-code"></i> Template</div>
              <div class="box">
                Ger Vars
                <textarea df-template></textarea>
                Output template with vars
              </div>
            </div>
            `;
          editor.addNode('template', 1, 1, posX, posY, 'template', { template: 'Write your template' }, template);
          break;

        case 'multiple':
          const multiple = `
            <div>
              <div class="box">
                Multiple!
              </div>
            </div>
            `;
          editor.addNode('multiple', 3, 4, posX, posY, 'multiple', {}, multiple);
          break;
        case 'personalized':
          const personalized = `
            <div>
              Personalized
            </div>
            `;
          editor.addNode('personalized', 1, 1, posX, posY, 'personalized', {}, personalized);
          break;

        case 'dbclick':
          const dbclick = `
            <div>
            <div class="title-box"><i class="fas fa-mouse"></i> Db Click</div>
              <div class="box dbclickbox" ondblclick="showpopup(event)">
                Db Click here
                <div class="modal" style="display:none">
                  <div class="modal-content">
                    <span class="close" onclick="closemodal(event)">&times;</span>
                    Change your variable {name} !
                    <input type="text" df-name>
                  </div>

                </div>
              </div>
            </div>
            `;
          editor.addNode('dbclick', 1, 1, posX, posY, 'dbclick', { name: '' }, dbclick);
          break;

        default:
          console.error('NÃO EXISTE');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tool-bar-right {
  width: 80% !important;
  margin-right: 50px !important;
  float: right !important;
}

#flowItems {
  width: 300px;
  margin-right: 90px;
  max-height: 300px;
}

.bars-flow-float {
  position: fixed;
  left: 61px;
  top: 62px;
  width: 100%;
}

.wi50 {
  width: 50% !important;
}

.header-flow-bar {
  width: 100%;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid #ccc;
}

.top-11 {
  margin-top: 11px !important;
}

.float-bar-title {
  margin-top: 4px;
}

.float-bar-title:hover {
  background: #f6f6f6;
}

.float-badge {
  margin-top: 8px;
}

.float-badge-danger {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background: rgba(218, 44, 44, 0.1);
  padding: 6.6px;
}

.float-badge-danger-inner {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background: #DA2C2C80;
}

.float-badge-success {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background: rgba(110, 218, 44, 0.1);
  padding: 6.6px;
}

.float-badge-success-inner {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background: #6EDA2C;
}

.header-flow-bar.float-bar {
  margin: 10px;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
}

.float-bar-title {
  color: #423C4C;
  font-weight: 500;
}

.hide {
  display: none !important;
}

.content {
  position: relative;
}

.custom-control-label {

  &::after,
  &::before {
    top: -0.5rem;
  }
}

.btn i {
  line-height: 1;
  color: inherit;
}

#flowItems {
  position: fixed;
  top: 180px;
  right: 24px;
  z-index: 10;

  .card::v-deep {
    border: 1px solid #ccc;
  }

  .card-body::v-deep {
    padding: .4em;

    h4 {
      padding: 0 .4em;
    }
  }

  .drag-drawflow {
    cursor: move;
    padding: .4em;

    &:hover {
      background: #fcfcfc;
    }
  }
}

#drawFlowArea {
  background: url("data:image/svg+xml,%3Csvg height='44' width='44' xmlns='http://www.w3.org/2000/svg' fill='%23ddd'%3E%3Ccircle cx='1' cy='1' r='1'/%3E%3Ccircle cx='22' cy='1' r='1'/%3E%3Ccircle cx='1' cy='22' r='1'/%3E%3Ccircle cx='22' cy='22' r='1'/%3E%3C/svg%3E");
  min-height: 200px;
  height: calc(100vh - 62px);
  width: 100%;

  &:active {
    cursor: grab;
    cursor: grabbing;
  }
}

.items {
  background: #fff;
}

.editor::v-deep {
  #drawFlowArea {
    .drawflow .drawflow-node {
      background: #fff;
      border: 1px solid #ccc;
      -webkit-box-shadow: 0 2px 15px 2px rgba(0, 0, 0, .2);
      box-shadow: 0 2px 15px 2px rgba(0, 0, 0, .2);
      padding: 0px;
      width: 200px;
    }

    .drawflow .drawflow-node.selected {
      background: white;
      border: 1px solid #4ea9ff;
      -webkit-box-shadow: 0 2px 20px 2px #4ea9ff;
      box-shadow: 0 2px 20px 2px #4ea9ff;
    }

    .drawflow .drawflow-node.selected .title-box {
      color: #22598c;
      /*border-bottom: 1px solid #4ea9ff;*/
    }

    .drawflow .connection .main-path {
      stroke: #4ea9ff;
      stroke-width: 3px;
    }

    .drawflow .drawflow-node .input,
    .drawflow .drawflow-node .output {
      height: 15px;
      width: 15px;
      border: 2px solid var(--border-color);
    }

    .drawflow .drawflow-node .input:hover,
    .drawflow .drawflow-node .output:hover {
      background: #4ea9ff;
    }

    .drawflow .drawflow-node .output {
      right: 10px;
    }

    .drawflow .drawflow-node .input {
      left: -10px;
      background: white;
    }

    .drawflow>.drawflow-delete {
      border: 2px solid #43b993;
      background: white;
      color: #43b993;
      -webkit-box-shadow: 0 2px 20px 2px #43b993;
      box-shadow: 0 2px 20px 2px #43b993;
    }

    .drawflow-delete {
      border: 2px solid #4ea9ff;
      background: white;
      color: #4ea9ff;
      -webkit-box-shadow: 0 2px 20px 2px #4ea9ff;
      box-shadow: 0 2px 20px 2px #4ea9ff;
    }

    .drawflow-node .title-box {
      height: 50px;
      line-height: 50px;
      background: var(--background-box-title);
      border-bottom: 1px solid #e9e9e9;
      border-radius: 4px 4px 0px 0px;
      padding-left: 10px;
    }

    .drawflow .title-box svg {
      position: initial;
    }

    .drawflow-node .box {
      padding: 10px 20px 20px 20px;
      font-size: 14px;
      color: #555555;

    }

    .drawflow-node .box p {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .drawflow-node.welcome {
      width: 250px;
    }

    .drawflow-node.slack .title-box {
      border-radius: 4px;
    }

    .drawflow-node input,
    .drawflow-node select,
    .drawflow-node textarea {
      border-radius: 4px;
      border: 1px solid var(--border-color);
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      width: 158px;
      color: #555555;
    }

    .drawflow-node textarea {
      height: 100px;
    }

    .drawflow-node.personalized {
      background: red;
      height: 200px;
      text-align: center;
      color: white;
    }

    .drawflow-node.personalized .input {
      background: yellow;
    }

    .drawflow-node.personalized .output {
      background: green;
    }

    .drawflow-node.personalized.selected {
      background: blue;
    }

    .drawflow .connection .point {
      stroke: var(--border-color);
      stroke-width: 2;
      fill: white;
    }

    .drawflow .connection .point.selected,
    .drawflow .connection .point:hover {
      fill: #4ea9ff;
    }

    /* Modal */
    .modal {
      display: none;
      position: fixed;
      z-index: 7;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      overflow: auto;
      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.7);
    }

    .modal-content {
      position: relative;
      background-color: #fefefe;
      margin: 15% auto;
      /* 15% from the top and centered */
      padding: 20px;
      border: 1px solid #888;
      width: 400px;
      /* Could be more or less, depending on screen size */
    }

    /* The Close Button */
    .modal .close {
      color: #aaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
      .modal-content {
        width: 80%;
      }
    }

    .drawflow-node textarea {
      border: 1px solid #ced4da !important;
    }

    .drawflow-node input {
      border: 1px solid #ced4da !important;
    }

    .drawflow-node .form-control {
      border: 1px solid #ced4da !important;
    }

    .drawflow .drawflow-node.intersected {
      border: 2px solid rgb(86, 47, 225) !important;
      box-sizing: border-box;
    }
  }

  #flowItems {
    top: 170px;
  }

  #flowItems .card {
    border-radius: 10px;
    padding: 20px;
  }

  .header-flow-bar {
    z-index: 300;
  }

  .drag-drawflow {
    cursor: move;
  }

  #flowItems .drag-drawflow {
    cursor: move !important;
  }

  .bar-zoom {
    float: right;
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    font-size: 24px;
    top: 10;
    color: white;
    padding: 5px 10px;
    background: #555555;
    border-radius: 4px;
    border-right: 1px solid var(--border-color);
    z-index: 5;
  }

  svg:not(:root).svg-inline--fa {
    overflow: visible;
  }

  .svg-inline--fa.fa-w-16 {
    width: 1em;
  }

  .bar-zoom svg {
    cursor: pointer;
    padding-left: 10px;
  }

  .svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
  }

  .bar-zoom {
    font-size: 24px;
    color: white;
  }

  .box-tools-flow {
    position: absolute;
    top: 20%;
    right: 14px;
  }

  .btn-flow-tools {
    z-index: 9;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #752de6;
    text-align: center;
    font-size: 40px;
    cursor: pointer;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.152);
  }

  .btn-flow-tools:hover {
    background: #824cd7;
  }

  .btn-flow-tools svg {
    margin-top: -10px;
  }

  .btn-flow-tools.btn-flow-zoons {
    background: #fff;
    width: 40px;
    height: 40px;
    border: solid 1px #788db4;
    margin: 0 auto;
    margin-top: 20px;
  }

  .btn-flow-tools.btn-flow-zoons:hover {
    background: #dbe6fb;
  }

  .btn-flow-tools.btn-flow-zoons i {
    font-size: 15px;
    margin-top: 12px;
    display: block;
  }

  .flow-webhook-panel {
    background: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    color: #313131;
  }

  .new-template-whats .col-sm-12 {
    padding: 0;
  }

  .new-template-whats {
    margin: 0 auto;
    background: #fff;
    margin: 0px;
  }

  .new-template-title {
    color: rgb(28, 30, 33);
  }

  .new-template-whats p span {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: normal;
    overflow-wrap: normal;
    text-align: left;
    color: rgb(96, 103, 112);
  }

  .new-template-whats .list-group-item {
    border: 1px solid rgba(140, 140, 140, 0.3);
    font-size: .875rem;
  }

  .new-template-whats .list-group-flush>.list-group-item:last-child {
    border-bottom-width: 1px;
  }

  .btn-change-typebutton:hover {
    background: rgba(140, 140, 140, 0.3) !important;
    color: #000;
    cursor: pointer;
  }

  .btn-change-typebutton.active {
    background: rgba(140, 140, 140, 0.3) !important;
    color: #000;
  }

  .span-info {
    font-size: 12px !important;
    color: #9d9d9d !important;
  }

  .whats-preview {
    background: rgb(255, 255, 255);
    border-radius: 6px;
    position: relative;
    padding: 28px;
    width: 100%;
    min-height: 175px;
    box-shadow: 0px 2px 8px rgba(12, 12, 13, 0.1);
  }

  .whats-preview img {
    width: 100%;
    object-fit: cover;
    height: 200px;
    border-radius: 6px;
  }

  .whats-preview-header {
    color: #000;
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .whats-preview-footer {
    color: #000;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .btn-link-preview {
    background: rgb(255, 255, 255);
    border-radius: 6px;
    box-shadow: 0px 2px 8px rgba(12, 12, 13, 0.1);
    border: none;
    width: 100% !important;
    padding: 10px;
    margin-top: 10px;
  }

  .content-wrapper {
    padding-left: 60px;
    padding-top: 62px;
  }
}

.btn-default:hover {
  background: #ccc;
}

.preview-media-select {
  flex: 0 1 auto;
  order: 0;
  margin-right: 20px;
  align-self: auto;
  border-radius: 10px;
  border-width: 2px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 10px;
}

.preview-media-select:hover {
  background-color: #ecf3ff;
  border: 1px solid #ccc;
  border-width: 1px;
}

.preview-media-select-active {
  background-color: #ecf3ff;
  border: 1px solid #ccc;
  border-width: 1px;
}

.preview-media-select label {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  width: 60%;
  display: block;
  margin: 0 auto;
}

.preview-media-select-image label {
  background-image: url('/assets/img/image_preview.png');
}

.preview-media-select-text label {
  background-image: url('/assets/img/text_preview.png');
}

.preview-media-select-video label {
  background-image: url('/assets/img/video_preview.png');
}

.preview-media-select-document label {
  background-image: url('/assets/img/document_preview.png');
}

.preview-media-select span {
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: normal;
  overflow-wrap: normal;
  text-align: center;
  display: block;
  margin-top: 8px;
}


.template-preview-new {
  background-color: #e5ddd5;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  z-index: 0;
  padding: 15px;
}

.template-preview-new::before {
  background: url('/assets/img/chat-bg.png');
  background-size: auto;
  background-size: 366.5px 666px;
  content: '';
  height: 100%;
  left: 0;
  opacity: .06;
  position: absolute;
  top: 0;
  width: 100%;
}

.preview-messages-container {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 12px;
  margin-top: 12px;
  background-color: #fff;
  border-radius: 7.5px;
  border-top-left-radius: 7.5px;
  border-top-left-radius: 0;
  box-shadow: 0 1px .5px rgba(0, 0, 0, .15);
  min-height: 20px;
  position: relative;
  word-wrap: break-word;
  width: 90%;
  padding: 3px;
  margin: 1px;
}

.preview-messages-container::after {
  background: url('/assets/img/chat_buble.png') 50% 50% no-repeat;
  background-size: auto;
  background-size: contain;
  content: '';
  height: 19px;
  left: -12px;
  position: absolute;
  top: 0;
  width: 12px;
}

.time-messages {
  bottom: 10px;
  color: rgba(0, 0, 0, .4);
  font-size: 11px;
  height: 15px;
  line-height: 15px;
  position: absolute;
  right: 7px;
}

.preview-image-header {
  background-image: url('/assets/img/image_preview_white.png');
  background-size: 80px 80px;
}

.preview-image-header {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 4px;
  box-sizing: border-box;
  height: 130px;
}

.preview-message-body {
  color: #262626;
  font-size: 13.6px;
  line-height: 19px;
  padding: 7px 7px 6px 9px;

  &>span {
    white-space: pre-wrap;
  }
}

.preview-message-header {
  color: rgba(0, 0, 0, .76);
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  padding: 6px 7px 0 9px;
}

.preview-message-footer {
  color: rgba(0, 0, 0, .45);
  font-size: 13px;
  line-height: 17px;
  padding: 0 7px 8px 9px;
}

.btn-select-file-card {
  background: #399af2;
  color: #fff;
  border: #399af2;
}

.btn-select-file-card:hover {
  background: #3c8dd8;
  border: #399af2;
}

.preview-file-card-node {
  height: 200px;
  border: solid #ccc 1px;
  border-radius: 10px;
  border-bottom: 2px #ccc solid;
  overflow: hidden;
}

.btn-remove-file-node-card {
  border-radius: 50px;
}

.preview-file-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
  /* some width */
}

.to-left {
  float: left !important;
}

.to-right {
  float: right !important;
}

.main-path-success {
  stroke: #44df72 !important;
}

.btn-flow-tools-add {
  padding-top: 1px;
}

.btn-flow-tools-check {
  padding-top: 1px;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  background: #2de664 !important;
  margin-top: 5px;
}

.btn-flow-tools-check span {
  color: #fff;
}

.flow-float-config {
  margin-left: 10px;
  margin-right: 15px;
  margin-top: 4px;
}

.left-15 {
  margin-left: 15px !important;
}

.c-pointer {
  cursor: pointer !important;
}

.btn-save-flow ::before {
  color: #000 !important;
}

.new-flow {
  top: 110px !important;
  right: -20px !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.col-tool-bar {
  flex: 0 0 12% !important;
  max-width: 12% !important;
}

.c919191 {
  color: #919191 !important;
}

::v-deep .tags-container {
  .vue-tags-input {
    max-width: 100%;
    padding: 0px;
  }
}

.card-edit-btn {
  margin: 10px;
  color: #fff;
  cursor: pointer;
  left: 0;
}

.card-edit-btn:hover {
  color: rgb(230, 230, 230);
}

.carousel {
  overflow-x: scroll;
  flex-direction: column;
  display: flex;
  height: 500px;
}

.close-cards {
  right: 5px;
  top: 5px;
}

.m0 {
  margin: 0px !important;
}

.version-box {
  position: absolute;
  bottom: 10px;
  left: 20px;
  font-size: 13px;
}

.no-history {
  font-size: 60px;
  margin-top: 30px;
  color: #ccc;
}
</style>
